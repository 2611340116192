import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, DestroyRef, Inject, inject, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, Validators } from '@angular/forms';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { Router } from '@angular/router';
import { Config, ConfiguratorData } from '@app/models/configurator';
import { ConfirmChangeNode, ExampleFlatNode } from '@app/models/configurator-models/confirm-change';
import { DeviceUIModel, PostBodyModel } from '@app/models/device';
import { ConfiguratorService } from '@app/services/configurator.service';
import { ConversionService } from '@app/services/conversion.service';
import { EventService, ResourceObject } from '@app/services/event.service';
import { DeviceMiddlewareService } from '@app/services/jsonapi-services/device-middleware.service';
import { UtilsService } from '@app/services/utils.service';
import { comfirmChangesColumns } from '@app/utils/constants/configurator-constants';
import { eventDeviceRelationships } from '@app/utils/constants/device-constants';
import { ActualConfigUpdateStatus, DeviceType } from '@app/utils/enums/device-enums';
import { EventOperation, Events, EventTypes } from '@app/utils/enums/event-enum';
import { ToastrType } from '@app/utils/enums/snackbar-enum';
import { Command, CommandService } from '@services/jsonapi-services/command.service';
import { operation, resources } from '@utils/enums/permissions-enums';
import { Subscription, take } from 'rxjs';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-config-confirmation',
  templateUrl: './config-confirmation.component.html',
  styleUrl: './config-confirmation.component.css',
})
export class ConfigConfirmationComponent {
  device!: DeviceUIModel;
  subscription!: Subscription;
  listOfTableColumns = comfirmChangesColumns;
  pageTitle!: string;
  comments = new FormControl('', [Validators.maxLength(240)]);
  headerColumns: string[] = ['groupHeader'];
  displayedColumns: string[] = ['name', 'previousValue', 'newValue'];
  confirmChangeData!: ConfirmChangeNode[];
  destroyRef = inject(DestroyRef);
  configuratorData!: ConfiguratorData;
  data!: Config;
  isDisabled = false;
  initialized!: Promise<void>;
  changesInProgressWarning!: string | null;
  private deviceDesiredConfigCreateEventId = 0;
  private deviceDesiredConfigUpdateEventId = 0;

  private transformer = (node: ConfirmChangeNode, level: number): ExampleFlatNode => {
    return {
      expandable: !!node.childrens && node.childrens.length > 0,
      name: node.name,
      values: node.changedValues,
      level: level,
      itemCount: node.childrens?.length || 0,
    };
  };

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level,
    node => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this.transformer,
    node => node.level,
    node => node.expandable,
    node => node.childrens,
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
  constructor(
    @Inject(Router) private router: Router,
    private configuratorService: ConfiguratorService,
    private deviceMiddlewareService: DeviceMiddlewareService,
    private utilsService: UtilsService,
    private conversionService: ConversionService,
    private eventService: EventService,
    private commandService: CommandService,
  ) {}

  ngOnInit() {
    const previousUrl: any = this.router.lastSuccessfulNavigation?.previousNavigation;
    if (!previousUrl?.targetRouterState?.snapshot?.url.includes('configure')) {
      const nextRoute = this.router.url.replace('/confirmChange', '');
      this.router.navigate([nextRoute]);
    } else {
      this.confirmChangeData = this.configuratorService.getConfigChangeData();
      this.confirmChangeData ? (this.dataSource.data = this.confirmChangeData) : this.router.navigate(['']);
      if (this.treeControl.dataNodes) {
        const firstMenuNode = this.treeControl.dataNodes[0];
        this.treeControl.expand(firstMenuNode);
      }
      [this.configuratorData, this.data, this.device] = this.configuratorService.getIntialConfigData();
      this.pageTitle = `Confirm ${this.device.deviceTag?.toUpperCase()}  Configuration`;
      this.bindEvents();
    }
  }

  onSave(): void {
    this.configuratorService
      .getConfigurationData()
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: data => {
          const deadline = this.conversionService.addSecondsToDate(new Date(), 300);
          const readConfigPayload: Command = {
            type: 'commands',
            attributes: {
              args: {},
              deadline: this.conversionService.convertDateToTimestampWithTimezone(deadline),
              operation: `${operation.read}_${resources.config}`,
              done: false,
            },
            relationships: {
              device: {
                data: {
                  type: EventTypes.Devices,
                  id: this.device.id!,
                },
              },
            },
          };
          this.commandService.addCommand(readConfigPayload).subscribe(() => {
            const postDataObject = this.createDesiredConfigPostObject(
              data as unknown as Record<string, string | boolean | number | null>,
            );
            this.deviceMiddlewareService
              .addDesiredConfigData(this.configuratorData.deviceType!, postDataObject)
              .subscribe({
                error: error => {
                  console.error(error);
                  this.utilsService.showSnackBar('Failed to update configuration data', ToastrType.error);
                },
              });
          });
        },
        error: error => {
          console.error(error);
          this.utilsService.showSnackBar('Failed to update configuration data', ToastrType.error);
        },
      });
  }

  createDesiredConfigPostObject(data: Record<string, string | boolean | number | null>): PostBodyModel {
    const updatedAttributesData: any = { ...this.data.attributes, ...data };
    delete updatedAttributesData.created_at;
    const currentDate = new Date();
    const deadlineDate = this.conversionService.addSecondsToDate(currentDate, 30);
    updatedAttributesData.deadline = this.conversionService.convertDateToTimestampWithTimezone(deadlineDate);
    const deviceType =
      this.configuratorData.deviceType! == DeviceType.Elexant40X0i
        ? DeviceType.Elexant40X0
        : this.configuratorData.deviceType!;
    const deviceRelationshipName = this.utilsService.getDeviceTypeName(deviceType);
    const postObj: PostBodyModel = {
      type: `${deviceType!}_desired_configs`,
      attributes: updatedAttributesData,
      relationships: {
        base: {
          data: {
            type: this.data.type,
            id: this.data.id,
          },
        },
        [`${deviceRelationshipName}`]: {
          data: {
            type: `${deviceType!}s`,
            id: this.device.id!,
          },
        },
      },
    };
    return postObj;
  }

  clearConfigData() {
    this.configuratorService.clearConfigurationData();
    this.configuratorService.clearAllConfigRelatedData();
    this.comments.setValue('');
  }

  onDesiredConfigCreateorUpdate(desiredConfigEvent: any) {
    this.initialized
      .then(() => {
        const relationshipType = eventDeviceRelationships[desiredConfigEvent.data.type.replace('_desired_config', '')];
        if (
          this.device?.id ===
          desiredConfigEvent.data.relationships[relationshipType as keyof ResourceObject['relationships']]!.data.id
        ) {
          if (desiredConfigEvent.op === EventOperation.Update) {
            const result = String(desiredConfigEvent.data.attributes!.result);
            switch (result) {
              case ActualConfigUpdateStatus.Ok:
                this.router.navigate(['/devices', this.device.deviceTag]);
                this.configuratorService.clearConfigurationData();
                this.configuratorService.clearAllConfigRelatedData();
                break;
              case ActualConfigUpdateStatus.Incomplete:
              case ActualConfigUpdateStatus.Merged:
              case ActualConfigUpdateStatus.IoFailed:
              case ActualConfigUpdateStatus.ReadOnly:
              case ActualConfigUpdateStatus.Invalid:
              case ActualConfigUpdateStatus.Obsolete:
              case ActualConfigUpdateStatus.Nop:
              case ActualConfigUpdateStatus.Expired:
                this.utilsService.showSnackBar(
                  'Device Configuration update failure. Please try again.',
                  ToastrType.error,
                );
                this.device!.desiredConfigId = 'NA';
                this.device!.desiredConfigDeadline = null;
                break;
            }
            this.isDisabled = false;
            this.changesInProgressWarning = '';
            if (result !== ActualConfigUpdateStatus.Ok) {
              this.configuratorService.clearConfigurationData();
            }
          } else if (desiredConfigEvent.op === EventOperation.Create) {
            this.isDisabled = true;
            this.changesInProgressWarning = 'Desired config changes are being applied. Please wait!';
          }
        }
      })
      .catch(err => {
        console.error(err);
      });
  }

  private bindEvents(): void {
    this.initialized = new Promise<void>((resolve, _reject) => {
      this.deviceDesiredConfigCreateEventId = this.eventService.register(
        Events.DesiredConfigCreate,
        this.onDesiredConfigCreateorUpdate.bind(this),
      );
      this.deviceDesiredConfigUpdateEventId = this.eventService.register(
        Events.DesiredConfigUpdate,
        this.onDesiredConfigCreateorUpdate.bind(this),
      );
      resolve();
    });
  }

  ngOnDestroy(): void {
    if (this.deviceDesiredConfigUpdateEventId !== 0 && this.deviceDesiredConfigCreateEventId !== 0) {
      this.eventService.deregister(Events.DesiredConfigCreate, this.deviceDesiredConfigCreateEventId);
      this.eventService.deregister(Events.DesiredConfigUpdate, this.deviceDesiredConfigUpdateEventId);
    }
    this.clearConfigData();
  }
}
