import {
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { format } from 'date-fns';
import cloneDeep from 'lodash-es/cloneDeep';
import { AlarmShelveReasonFields, AlarmUIModel } from '@app/models/alarm';
import { alarmShelveReasons } from '@app/utils/constants/alarm-constants';
import { Alarm, AlarmService } from '@app/services/jsonapi-services/alarm.service';
import { EventTypes } from '@app/utils/enums/event-enum';
import { AlarmStateEnum } from '@app/utils/enums/alarm-enums';

@Component({
  selector: 'app-alarm-shelve-form',
  templateUrl: './alarm-shelve-form.component.html',
  styleUrls: ['./alarm-shelve-form.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AlarmShelveFormComponent implements OnChanges {
  @Input() alarmData!: AlarmUIModel;
  @Output() saveSuccesEvent = new EventEmitter<void>();
  @Output() cancelOnClickEvent = new EventEmitter<void>();
  @Output() errorEvent = new EventEmitter<void>();
  title = 'Shelve Alarm';
  blockUIMessage = 'Loading Alarms...';
  alarmInfo!: { field: string; value: string }[];
  alarmShelveForm!: FormGroup;
  shelveDurationFields!: AlarmShelveReasonFields[];
  shelvingReasons = cloneDeep(alarmShelveReasons);
  outsideClick = false;
  unsavedChangesWarning: string | null = null;
  isLoading = false;
  shelveDurationError = false;
  destroyRef = inject(DestroyRef);

  constructor(
    private alarmService: AlarmService,
    private formBuilder: FormBuilder,
  ) {}

  ngOnChanges(): void {
    this.alarmInfo = [
      {
        field: 'Tag',
        value: this.alarmData?.deviceTag!,
      },
      {
        field: 'Alarm Type',
        value: this.alarmData?.alarmType!,
      },
      { field: 'Alarm Status', value: this.alarmData?.alarmState! },
    ];

    this.shelveDurationFields = [
      { fieldName: 'Days', controlName: 'shelveDurationInDays', iconName: 'calendar_month' },
      { fieldName: 'Hours', controlName: 'shelveDurationInHours', iconName: 'hourglass_top' },
      { fieldName: 'Minutes', controlName: 'shelveDurationInMins', iconName: 'timelapse' },
    ];

    this.alarmShelveForm = this.formBuilder.group({
      shelvingReason: new FormControl<string>('', Validators.required),
      shelveDurationInDays: new FormControl<number>(0, [
        Validators.required,
        Validators.min(0),
        this.validateDuration(),
      ]),
      shelveDurationInHours: new FormControl<number>(0, [
        Validators.required,
        Validators.min(0),
        Validators.max(23),
        this.validateDuration(),
      ]),
      shelveDurationInMins: new FormControl<number>(0, [
        Validators.required,
        Validators.min(0),
        Validators.max(59),
        this.validateDuration(),
      ]),
      otherReason: new FormControl<string>(''),
      comments: new FormControl<string | null>(null),
    });
    this.checkForChanges();
  }

  validateDuration(): ValidatorFn {
    return (): ValidationErrors | null => {
      if (this.shelveDurationError) {
        return { shelveDurationError: true };
      }
      return null;
    };
  }

  onSave(): void {
    this.isLoading = true;
    const shelveReason =
      this.alarmShelveForm.controls.shelvingReason.value === 'Other'
        ? this.alarmShelveForm.controls.otherReason.value
        : this.alarmShelveForm.controls.shelvingReason.value;

    const alarmBody: Alarm = {
      type: EventTypes.Alarms,
      id: this.alarmData.id!,
      attributes: {
        state: AlarmStateEnum.Shelved,
        shelve_reason: shelveReason,
        shelve_comments: this.alarmShelveForm.controls.comments.value,
        shelved_until: this.convertToTimestamp(
          this.alarmShelveForm.controls.shelveDurationInDays.value,
          this.alarmShelveForm.controls.shelveDurationInHours.value,
          this.alarmShelveForm.controls.shelveDurationInMins.value,
        ),
      },
    };

    this.alarmService
      .updateAlarm(alarmBody)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.resetForm();
          this.saveSuccesEvent.emit();
        },
        error: (err: unknown) => {
          this.resetForm();
          this.errorEvent.emit();
          console.error(err);
        },
      });
  }

  onCancel(): void {
    this.resetForm();
    this.cancelOnClickEvent.emit();
  }

  onInputClick(formControlName: string): void {
    if (this.alarmShelveForm.get(formControlName)?.pristine) {
      this.alarmShelveForm.get(formControlName)?.setValue(null);
    }
  }

  resetForm(): void {
    this.isLoading = false;
    this.alarmShelveForm.reset();
  }

  checkForChanges(): void {
    this.alarmShelveForm.valueChanges.pipe(distinctUntilChanged(), takeUntilDestroyed(this.destroyRef)).subscribe({
      next: () => {
        this.alarmShelveForm.get('shelvingReason')?.value === 'Other'
          ? this.alarmShelveForm.get('otherReason')?.setValidators([Validators.required])
          : this.alarmShelveForm.get('otherReason')?.setValidators(null);
        this.alarmShelveForm.get('otherReason')?.updateValueAndValidity({ emitEvent: false });
        this.unsavedChangesWarning = null;
        this.outsideClick = false;
        this.checkShelveDuration();
      },
    });
  }

  checkShelveDuration(): void {
    if (
      this.alarmShelveForm.get('shelveDurationInDays')?.value === 0 &&
      this.alarmShelveForm.get('shelveDurationInHours')?.value === 0 &&
      this.alarmShelveForm.get('shelveDurationInMins')?.value === 0
    ) {
      this.shelveDurationError = true;
      if (
        this.alarmShelveForm.get('shelveDurationInDays')?.dirty ||
        this.alarmShelveForm.get('shelveDurationInHours')?.dirty ||
        this.alarmShelveForm.get('shelveDurationInMins')?.dirty
      ) {
        this.alarmShelveForm.get('shelveDurationInDays')?.markAsTouched();
        this.alarmShelveForm.get('shelveDurationInHours')?.markAsTouched();
        this.alarmShelveForm.get('shelveDurationInMins')?.markAsTouched();
      }
    } else this.shelveDurationError = false;

    this.alarmShelveForm.get('shelveDurationInDays')?.updateValueAndValidity({ emitEvent: false });
    this.alarmShelveForm.get('shelveDurationInHours')?.updateValueAndValidity({ emitEvent: false });
    this.alarmShelveForm.get('shelveDurationInMins')?.updateValueAndValidity({ emitEvent: false });
  }

  convertToTimestamp(days: number, hours: number, mins: number): string {
    const timestamp = new Date();
    timestamp.setDate(timestamp.getDate() + days);
    timestamp.setHours(timestamp.getHours() + hours);
    timestamp.setMinutes(timestamp.getMinutes() + mins);
    return format(timestamp, 'yyyy-MM-dd HH:mm:ss.SSSxxx');
  }
}
