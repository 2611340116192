import { FlatTreeControl } from '@angular/cdk/tree';
import {
  Component,
  DestroyRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { ActivatedRoute, Router } from '@angular/router';
import { Config, ConfigAttributes, ConfiguratorData } from '@app/models/configurator';
import { ConfigFormOutlet } from '@app/models/configurator-models/configurator-outline';
import {
  Menu,
  cm2000Menu,
  cm2000pMenu,
  elexant3500iMenu,
  elexant40x0Menu,
  elexant5010Menu,
  htc3Menu,
  htc910Menu,
  htc920Menu,
  htcMenu,
  ioMenu,
  slimMenu,
} from '@app/models/configurator-models/menu-items';
import { DataModel, DeviceUIModel } from '@app/models/device';
import { MenuNode } from '@app/models/table-items';
import { ConfiguratorService } from '@app/services/configurator.service';
import { ConversionService } from '@app/services/conversion.service';
import { CreateFormMetaService } from '@app/services/create-form-meta.service';
import { DataStoreService } from '@app/services/data-store.service';
import { Event, EventService, ResourceObject } from '@app/services/event.service';
import { DeviceMiddlewareService } from '@app/services/jsonapi-services/device-middleware.service';
import { TreeMenuService } from '@app/services/tree-menu.service';
import { UtilsService } from '@app/services/utils.service';
import { eventDeviceRelationships } from '@app/utils/constants/device-constants';
import { ActualConfigUpdateStatus, DeviceType } from '@app/utils/enums/device-enums';
import { EventOperation, Events } from '@app/utils/enums/event-enum';
import { ToastrType } from '@app/utils/enums/snackbar-enum';
import { trailingZeroRegExPattern } from '@app/utils/patterns';
import { FeatureUnavailable } from '@utils/constants/feature-unavailable';
import { cloneDeep } from 'lodash-es';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { takeWhile } from 'rxjs';

@Component({
  selector: 'app-configurator',
  templateUrl: './configurator.component.html',
  styleUrl: './configurator.component.css',
  encapsulation: ViewEncapsulation.None,
})
export class ConfiguratorComponent implements OnInit, OnChanges, OnDestroy {
  @Input() device!: DeviceUIModel;
  @Input() peerDevices!: DeviceUIModel[];
  isEditable: boolean = false;
  configuratorData!: ConfiguratorData;
  @BlockUI('configure') blockUI!: NgBlockUI;
  pageTitle = 'Edit Configuration of ';
  blockUIMessage = 'Updating configuration...';
  isDisabled = true;
  sideMenu!: Menu[];
  finalFormOutlet!: ConfigFormOutlet;
  allFormOutlets: ConfigFormOutlet[] = [];
  initialConfigData!: ConfigAttributes;
  isActualConfig!: boolean;
  data!: Config;
  outletObj: { [key: string]: ConfigFormOutlet } = {};
  destroyRef = inject(DestroyRef);
  lastEditDate!: string;
  selectedParentNodes: string[] = [];
  isConfigFeatureAvailable: boolean = true;
  noDataMessage = '';
  formHeaderName: string = '';
  configureDeviceTypes: string[] = [
    DeviceType.Ngc40htc3,
    DeviceType.Htc910,
    DeviceType.Htc920,
    DeviceType.Cm2000,
    DeviceType.Cm2000p,
    DeviceType.Elexant40X0i,
    DeviceType.Ngc40htc,
    DeviceType.Ngc40io,
    DeviceType.Ngc40slim,
    DeviceType.Elexant5010i,
    DeviceType.Elexant3500i,
  ];
  initialized!: Promise<void>;
  private deviceActualConfigUpdateEventId = 0;
  private deviceActualConfigCreateEventId = 0;
  private deviceDesiredConfigCreateEventId = 0;
  private deviceDesiredConfigUpdateEventId = 0;
  private _transformer = (node: Menu, level: number) => {
    return {
      expandable: !!node.child && !node.child.isPartOfForm && node.child.menu.length > 0,
      name: node.parent,
      level: level,
    };
  };
  treeControl = new FlatTreeControl<MenuNode>(
    node => node.level,
    node => node.expandable,
  );
  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.child?.menu,
  );
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  hasChild = (_: number, node: MenuNode) => node.expandable;
  featureUnavailableTooltip = FeatureUnavailable;
  disabledButtons = ['Version history', 'Apply template'];

  constructor(
    @Inject(ActivatedRoute) private activatedRoute: ActivatedRoute,
    @Inject(Router) private router: Router,
    private createFormMetaService: CreateFormMetaService,
    private configuratorService: ConfiguratorService,
    private utilsService: UtilsService,
    private conversionService: ConversionService,
    private deviceMiddlewareService: DeviceMiddlewareService,
    private dataStoreService: DataStoreService,
    private eventService: EventService,
    private treeMenuService: TreeMenuService,
  ) {}

  ngOnInit() {
    this.blockUI.start('Getting config data...');
    this.isEditable = this.router.url.includes('configure') ? true : false;
    if (!this.device)
      this.activatedRoute.params.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(params => {
        if (params.deviceTag) {
          this.getDevicesFromDataStore(params.deviceTag);
        }
      });
    else this.getDeviceConfiguration();
    this.bindEvents();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['device'] &&
      changes['device'].previousValue &&
      changes['device'].previousValue?.id !== changes['device'].currentValue?.id
    ) {
      this.blockUI.start('Getting config data...');
      this.getDeviceConfiguration();
    }
  }

  getDevicesFromDataStore(deviceTag: string) {
    this.dataStoreService
      .getDevicesData()
      .pipe(takeWhile(() => this.device === undefined && this.isConfigFeatureAvailable))
      .subscribe({
        next: (devicesData: DataModel<DeviceUIModel[]>) => {
          if (!devicesData.isLoading && devicesData.data.length) {
            this.device = devicesData.data.find(device => {
              return device.deviceTag === deviceTag;
            })!;
            if (!this.device || !this.configureDeviceTypes.includes(this.device.deviceType!)) {
              this.isConfigFeatureAvailable = false;
              this.noDataMessage =
                this.device === undefined ? 'Device not found' : 'Edit Configuration feature not available for device';
              this.blockUI.stop();
            } else {
              this.peerDevices = devicesData.data.filter(device => device.bridgeName === this.device?.bridgeName);
              this.getDeviceConfiguration();
            }
          }
        },
        error: (err: unknown) => {
          console.error('Error getting devices from Redis', err);
          this.blockUI.stop();
        },
      });
  }

  getDeviceConfiguration(): void {
    if (
      this.device?.desiredConfigDeadline &&
      new Date().getTime() > new Date(this.device?.desiredConfigDeadline).getTime()
    ) {
      this.device.desiredConfigId = 'NA';
      this.device.desiredConfigDeadline = null;
    }
    this.deviceMiddlewareService
      .getDeviceActualConfigDataFromJsonApi(this.device?.actualConfigId!, this.device?.deviceType!)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: res => {
          this.configuratorData = {
            data: res.data,
            deviceType: this.device.deviceType!,
            deviceSerial: this.device.serialNumber!,
            deviceFirmwareVersion: this.device.firmwareVersion!,
          };
          this.pageTitle = `Edit Configuration of ${this.device.deviceTag?.toUpperCase()}`;
          this.configuratorService.setPeerDevices(this.peerDevices);
          this.setConfigurationLayout();
          this.setFormOutlet();
          this.configuratorService.setConfigFormOutlet(cloneDeep(this.outletObj));
          if (this.isEditable) this.canDisableButton();
          this.blockUI.stop();
        },
        error: (error: unknown) => {
          console.error('Error while getting config data', error);
          this.blockUI.stop();
        },
      });
  }

  updateLastEditedDate() {
    const created_at = this.configuratorData.data.attributes?.created_at! || new Date().toString();
    let date = new Date(created_at);
    return this.conversionService.convertDateTimeStringToDate(date.toString(), true);
  }

  setConfigurationLayout() {
    this.sideMenu = this.setSideMenu(this.configuratorData.deviceType!);
    this.dataSource.data = this.sideMenu;
    this.data = this.configuratorData.data;
    this.initialConfigData = cloneDeep(this.data.attributes! as ConfigAttributes);
    this.isActualConfig = this.data.type.includes('actual_configs');
    this.mapFormOutlets(this.sideMenu);
    this.lastEditDate = this.updateLastEditedDate();
    this.configuratorService.setIntialConfigData(this.configuratorData, this.data, this.device);
  }

  setFormOutlet() {
    if (this.treeControl.dataNodes) {
      const firstMenuNode = this.treeControl.dataNodes[0];
      this.treeControl.expand(firstMenuNode);
      this.selectedParentNodes = [];
      this.selectedParentNodes.push(firstMenuNode.name);
    }
    const currentFormHeader = this.getCurrentForm(this.sideMenu);
    this.finalFormOutlet = this.outletObj[currentFormHeader];
    this.formHeaderName = this.finalFormOutlet.name;
  }

  mapFormOutlets(menu: Menu[]) {
    menu.forEach(menuItem => {
      const itemLabel = menuItem.parent;
      const children = menuItem.child;
      if (children?.menu && !children.isPartOfForm) {
        this.mapFormOutlets(children.menu);
      } else {
        this.outletObj[itemLabel] = this.createFormMetaService.createformOutlet(
          menuItem,
          itemLabel,
          this.configuratorData,
          this.isActualConfig,
        );
        this.allFormOutlets.push(this.outletObj[itemLabel]);
      }
    });
  }

  onMenuOptionChange(menuOption: MenuNode) {
    if (this.finalFormOutlet.name !== menuOption.name) {
      this.selectedParentNodes = [];
      this.selectedParentNodes = this.treeMenuService.getParent(this.treeControl, menuOption, []);
      this.finalFormOutlet = this.outletObj[menuOption.name];
      this.formHeaderName = this.finalFormOutlet.name;
    }
  }

  closeOtherMenuItems(node: MenuNode) {
    const nodeIndex = this.treeMenuService.closeOtherMenuItems(this.treeControl, node);
    this.onMenuOptionChange(
      this.treeControl.dataNodes[nodeIndex + 1].expandable
        ? this.treeControl.dataNodes[nodeIndex + 2]
        : this.treeControl.dataNodes[nodeIndex + 1],
    );
  }

  getPreviousExpandedNodes(): string[] {
    const expandedNodes: string[] = [];
    if (this.treeControl.dataNodes)
      this.treeControl.dataNodes.forEach(node => {
        if (this.treeControl.isExpandable(node) && this.treeControl.isExpanded(node))
          expandedNodes.push(node.name.toString());
      });
    return expandedNodes;
  }

  setExpandedNodes(expandedNodes: string[]): void {
    if (this.treeControl.dataNodes)
      this.treeControl.dataNodes
        .filter(node => this.treeControl.isExpanded(node) || expandedNodes.find(name => name === node.name))
        .forEach(nodeToExpand => {
          this.treeControl.expand(nodeToExpand);
        });
  }

  canDisableButton() {
    if (this.data.type.includes('actual_configs')) {
      this.configuratorService
        .getConfigurationData()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(data => {
          let invalidForm = false;
          this.isDisabled = true;
          for (const configFormOutlet of this.allFormOutlets) {
            for (const formMeta of configFormOutlet.formContent) {
              if (formMeta.formGroup.invalid) {
                this.isDisabled = true;
                invalidForm = true;
                break;
              }
            }
            if (invalidForm) break;
          }
          if (!invalidForm) {
            Object.keys(data).forEach(key => {
              if (
                this.checkForTrailingZeroes(String(this.initialConfigData[key as keyof ConfigAttributes])) !==
                this.checkForTrailingZeroes(String(data[key as keyof ConfigAttributes]))
              )
                this.isDisabled = false;
            });
          }
        });
    } else this.isDisabled = true;
  }

  checkForTrailingZeroes(value: string) {
    const pattern = trailingZeroRegExPattern;
    const match = pattern.exec(value);
    return match ? parseFloat(match[1] + match[3]) : value;
  }

  setSideMenu(deviceType: string): Menu[] {
    switch (deviceType) {
      case DeviceType.Ngc40htc3:
        return cloneDeep(htc3Menu);
      case DeviceType.Htc910:
        return cloneDeep(htc910Menu);
      case DeviceType.Htc920:
        return cloneDeep(htc920Menu);
      case DeviceType.Cm2000:
        return cloneDeep(cm2000Menu);
      case DeviceType.Cm2000p:
        return cloneDeep(cm2000pMenu);
      case DeviceType.Elexant40X0i:
        return cloneDeep(elexant40x0Menu);
      case DeviceType.Elexant5010i:
        return cloneDeep(elexant5010Menu);
      case DeviceType.Ngc40htc:
        return cloneDeep(htcMenu);
      case DeviceType.Ngc40io:
        return cloneDeep(ioMenu);
      case DeviceType.Ngc40slim:
        return cloneDeep(slimMenu);
      case DeviceType.Elexant3500i:
        return cloneDeep(elexant3500iMenu);
      default:
        return [];
    }
  }

  onActualConfigCreateorUpdate(deviceConfigEvent: Event) {
    this.initialized
      .then(() => {
        const relationshipType = eventDeviceRelationships[deviceConfigEvent.data.type.replace('_actual_config', '')];
        if (
          this.device?.id ===
            deviceConfigEvent.data.relationships[relationshipType as keyof ResourceObject['relationships']]!.data.id &&
          !this.isEditable &&
          deviceConfigEvent.op === EventOperation.Create &&
          this.configuratorData
        ) {
          this.configuratorData = { ...this.configuratorData, data: deviceConfigEvent.data };
          this.setConfigurationLayout();
          this.setFormOutlet();
        }
      })
      .catch(err => {
        console.error(err);
      });
  }

  onDesiredConfigCreateorUpdate(desiredConfigEvent: Event) {
    this.initialized
      .then(() => {
        const relationshipType = eventDeviceRelationships[desiredConfigEvent.data.type.replace('_desired_config', '')];
        if (
          this.device?.id ===
          desiredConfigEvent.data.relationships[relationshipType as keyof ResourceObject['relationships']]!.data.id
        ) {
          if (desiredConfigEvent.op === EventOperation.Update) {
            const result = String(desiredConfigEvent.data.attributes!.result);
            switch (result) {
              case ActualConfigUpdateStatus.Ok:
                this.getDeviceConfiguration();
                break;
              case ActualConfigUpdateStatus.Incomplete:
              case ActualConfigUpdateStatus.Merged:
              case ActualConfigUpdateStatus.IoFailed:
              case ActualConfigUpdateStatus.ReadOnly:
              case ActualConfigUpdateStatus.Invalid:
              case ActualConfigUpdateStatus.Obsolete:
              case ActualConfigUpdateStatus.Nop:
              case ActualConfigUpdateStatus.Expired:
                this.utilsService.showSnackBar(
                  'Device Configuration update failure. Please try again.',
                  ToastrType.error,
                );
                this.device!.desiredConfigId = 'NA';
                this.device!.desiredConfigDeadline = null;
                break;
            }
            this.isDisabled = false;
            if (result !== ActualConfigUpdateStatus.Ok) {
              const expandedNodes = this.getPreviousExpandedNodes();
              this.setConfigurationLayout();
              this.finalFormOutlet = this.outletObj[this.finalFormOutlet.name];
              this.setExpandedNodes(expandedNodes);
              this.configuratorService.clearConfigurationData();
            }
          }
        }
      })
      .catch(err => {
        console.error(err);
      });
  }

  private getCurrentForm(menuObj: Menu[]): string {
    if (menuObj[0].child && !menuObj[0].child.isPartOfForm) {
      let currentFormHeader = this.getCurrentForm(menuObj[0].child.menu);
      return currentFormHeader;
    }
    return menuObj[0].parent;
  }

  private bindEvents(): void {
    this.initialized = new Promise<void>((resolve, _reject) => {
      this.deviceActualConfigUpdateEventId = this.eventService.register(
        Events.ActualConfigUpdate,
        this.onActualConfigCreateorUpdate.bind(this),
      );
      this.deviceActualConfigCreateEventId = this.eventService.register(
        Events.ActualConfigCreate,
        this.onActualConfigCreateorUpdate.bind(this),
      );
      this.deviceDesiredConfigCreateEventId = this.eventService.register(
        Events.DesiredConfigCreate,
        this.onDesiredConfigCreateorUpdate.bind(this),
      );
      this.deviceDesiredConfigUpdateEventId = this.eventService.register(
        Events.DesiredConfigUpdate,
        this.onDesiredConfigCreateorUpdate.bind(this),
      );
      resolve();
    });
  }

  ngOnDestroy(): void {
    this.blockUI.reset();
    if (
      this.deviceActualConfigUpdateEventId !== 0 &&
      this.deviceActualConfigCreateEventId !== 0 &&
      this.deviceDesiredConfigUpdateEventId !== 0 &&
      this.deviceDesiredConfigCreateEventId !== 0
    ) {
      this.eventService.deregister(Events.ActualConfigUpdate, this.deviceActualConfigUpdateEventId);
      this.eventService.deregister(Events.DesiredConfigCreate, this.deviceDesiredConfigCreateEventId);
      this.eventService.deregister(Events.ActualConfigCreate, this.deviceActualConfigCreateEventId);
      this.eventService.deregister(Events.DesiredConfigUpdate, this.deviceDesiredConfigUpdateEventId);
    }
  }
}
