export interface Menu {
  parent: string;
  child?: { menu: Menu[]; isPartOfForm?: boolean };
}

export const htc3Menu: Menu[] = [
  {
    parent: 'Temperature Settings',
    child: {
      menu: [
        { parent: 'Control Temperature' },
        { parent: 'Control Modes' },
        { parent: 'Local RTD' },
        { parent: 'Temperature Source' },
      ],
    },
  },
  {
    parent: 'Alarm Settings',
    child: {
      menu: [
        {
          parent: 'Failure and Trip Alarms',
          child: {
            isPartOfForm: true,
            menu: [
              { parent: 'Device Reset Alarm' },
              { parent: 'Digital Input Remote Source Failure Alarm' },
              { parent: 'Load Shed Source Failure Alarm' },
              { parent: 'High Limit Cutout Alarm' },
            ],
          },
        },
        {
          parent: 'Contactor and Heater Time Alarm',
          child: {
            isPartOfForm: true,
            menu: [{ parent: 'Contactor Cycle Count Alarm' }, { parent: 'Heater Time Alarm' }],
          },
        },
        { parent: 'Alarm Output' },
        {
          parent: 'Temperature Alarms',
          child: {
            menu: [
              {
                parent: 'Control Temperature Alarms',
                child: {
                  isPartOfForm: true,
                  menu: [
                    { parent: 'Control Temperature Failure Alarm' },
                    { parent: 'Control Temperature High Alarm' },
                    { parent: 'Control Temperature Low Alarm' },
                  ],
                },
              },
              {
                parent: 'Local Temperature Sensor Alarms',
                child: {
                  isPartOfForm: true,
                  menu: [
                    { parent: 'Local Temperature Sensor Failure Alarm' },
                    { parent: 'Local Temperature Sensor High Alarm' },
                    { parent: 'Local Temperature Sensor Low Alarm' },
                  ],
                },
              },
              {
                parent: 'Temperature Source Alarms',
                child: {
                  isPartOfForm: true,
                  menu: [
                    { parent: 'Temperature Source 1 Failure Alarm' },
                    { parent: 'Temperature Source 2 Failure Alarm' },
                    { parent: 'Temperature Source 3 Failure Alarm' },
                    { parent: 'Temperature Source 4 Failure Alarm' },
                    { parent: 'Temperature Source 5 Failure Alarm' },
                    { parent: 'Temperature Source 6 Failure Alarm' },
                    { parent: 'Temperature Source 7 Failure Alarm' },
                    { parent: 'Temperature Source 8 Failure Alarm' },
                  ],
                },
              },
            ],
          },
        },
        {
          parent: 'Electrical Alarms',
          child: {
            menu: [
              {
                parent: 'Circuit Breaker and Output Switch Alarms',
                child: {
                  isPartOfForm: true,
                  menu: [
                    { parent: 'Line 1 Circuit Breaker and Output Switch Alarms' },
                    { parent: 'Line 2 Circuit Breaker and Output Switch Alarms' },
                    { parent: 'Line 3 Circuit Breaker and Output Switch Alarms' },
                  ],
                },
              },
              {
                parent: 'Line Current Alarms',
                child: {
                  isPartOfForm: true,
                  menu: [
                    { parent: 'Line 1 High Current Alarms' },
                    { parent: 'Line 1 Low Current Alarms' },
                    { parent: 'Line 2 High Current Alarms' },
                    { parent: 'Line 2 Low Current Alarms' },
                    { parent: 'Line 3 High Current Alarms' },
                    { parent: 'Line 3 Low Current Alarms' },
                  ],
                },
              },
              {
                parent: 'Ground Fault Alarms',
                child: {
                  isPartOfForm: true,
                  menu: [
                    { parent: 'Ground Fault High Alarm' },
                    { parent: 'Ground Fault Trip' },
                    { parent: 'GFI Current Transformer Failure Alarm' },
                  ],
                },
              },
              {
                parent: 'Heating Cable Resistance Alarms',
                child: {
                  isPartOfForm: true,
                  menu: [
                    { parent: 'Line 1 Heating Cable Resistance High Current Alarms' },
                    { parent: 'Line 1 Heating Cable Resistance Low Current Alarms' },
                    { parent: 'Line 1 Heating Cable Nominal Tracing Resistance' },
                    { parent: 'Line 2 Heating Cable Resistance High Current Alarms' },
                    { parent: 'Line 2 Heating Cable Resistance Low Current Alarms' },
                    { parent: 'Line 2 Heating Cable Nominal Tracing Resistance' },
                    { parent: 'Line 3 Heating Cable Resistance High Current Alarms' },
                    { parent: 'Line 3 Heating Cable Resistance Low Current Alarms' },
                    { parent: 'Line 3 Heating Cable Nominal Tracing Resistance' },
                  ],
                },
              },
            ],
          },
        },
        {
          parent: 'Other Alarms',
          child: {
            isPartOfForm: true,
            menu: [
              { parent: 'Digital Input Alarm' },
              { parent: 'Safety Temperature Limiter Tripped Alarm' },
              { parent: 'Safety Temperature Limiter Communication Failure Alarm' },
            ],
          },
        },
      ],
    },
  },
  {
    parent: 'Electrical Settings',
    child: {
      menu: [
        { parent: 'Electrical General' },
        {
          parent: 'Circuit Breaker And Output Switch',
          child: {
            isPartOfForm: true,
            menu: [
              { parent: 'Line 1 Circuit Breaker And Output Switch Settings' },
              { parent: 'Line 2 Circuit Breaker And Output Switch Settings' },
              { parent: 'Line 3 Circuit Breaker And Output Switch Settings' },
            ],
          },
        },
      ],
    },
  },
  {
    parent: 'Misc Settings',
    child: {
      menu: [
        { parent: 'Load Shedding' },
        {
          parent: 'Auto Cycle',
          child: {
            isPartOfForm: true,
            menu: [{ parent: 'Auto Cycle Interval' }, { parent: 'Digital Input' }],
          },
        },
        { parent: 'Device Information' },
      ],
    },
  },
];

export const htc910Menu: Menu[] = [
  {
    parent: 'Temperature Settings',
    child: {
      menu: [
        { parent: 'Control Temperature' },
        { parent: 'Control Modes' },
        { parent: 'Temperature Sensors' },
        { parent: 'Miscellaneous' },
      ],
    },
  },
  {
    parent: 'Temperature Alarm Settings',
    child: {
      menu: [
        {
          parent: 'TS1',
          child: {
            isPartOfForm: true,
            menu: [
              { parent: 'High TS1 Temperature' },
              { parent: 'Low TS1 Temperature' },
              { parent: 'TS1 Failure' },
              { parent: 'High Limit Temperature CutOut 1' },
            ],
          },
        },
        {
          parent: 'TS2',
          child: {
            isPartOfForm: true,
            menu: [
              { parent: 'High TS2 Temperature' },
              { parent: 'Low TS2 Temperature' },
              { parent: 'TS2 Failure' },
              { parent: 'High Limit Temperature CutOut 2' },
            ],
          },
        },
        {
          parent: 'TS Control',
        },
      ],
    },
  },
  {
    parent: 'Other Alarm Settings',
    child: {
      menu: [
        {
          parent: 'Failure and Trip Alarms',
          child: {
            isPartOfForm: true,
            menu: [{ parent: 'HTC Reset' }, { parent: 'EEROM Data Failure' }],
          },
        },
        {
          parent: 'Limiting and Contactor Alarms',
          child: {
            isPartOfForm: true,
            menu: [{ parent: 'Switch Limiting' }, { parent: 'Contactor Count' }],
          },
        },
        {
          parent: 'Alarm Priorities and Output',
          child: {
            isPartOfForm: true,
            menu: [{ parent: 'Flash Alarm Output' }],
          },
        },
      ],
    },
  },
  {
    parent: 'Electrical Settings',
    child: {
      menu: [
        {
          parent: 'Load Current Alarms',
          child: {
            isPartOfForm: true,
            menu: [{ parent: 'High Load Current' }, { parent: 'Low Load Current' }],
          },
        },
        {
          parent: 'Ground Fault Current Alarms',
          child: {
            isPartOfForm: true,
            menu: [{ parent: 'High Ground Fault Current' }, { parent: 'Ground Fault Trip Current' }],
          },
        },
        {
          parent: 'Voltage Alarms',
          child: {
            isPartOfForm: true,
            menu: [{ parent: 'High Voltage' }, { parent: 'Low Voltage' }],
          },
        },
        {
          parent: 'Resistance Alarms',
          child: {
            isPartOfForm: true,
            menu: [
              { parent: 'High Resistance' },
              { parent: 'Low Resistance' },
              { parent: 'Nominal Tracing Resistance' },
            ],
          },
        },
        {
          parent: 'Electrical Setup',
          child: {
            isPartOfForm: true,
            menu: [
              { parent: 'Switch Rating' },
              { parent: 'C.B. Current' },
              { parent: 'Maximum Power' },
              { parent: 'Voltage Turns Ratio' },
              { parent: 'Current Turns Ratio' },
            ],
          },
        },
      ],
    },
  },
  {
    parent: 'Miscellaneous Settings',
    child: {
      menu: [
        {
          parent: 'Load Shedding',
          child: {
            isPartOfForm: true,
            menu: [
              { parent: 'Load Shedding Enable' },
              { parent: 'Fail Safe Mode' },
              { parent: 'Load Shedding Input #1' },
              { parent: 'Load Shedding Input #2' },
              { parent: 'Load Shedding Input #3' },
              { parent: 'Load Shedding Input #4' },
            ],
          },
        },
        {
          parent: 'External Input/Output',
          child: {
            isPartOfForm: true,
            menu: [{ parent: 'External Input Settings' }],
          },
        },
        {
          parent: 'Auto Cycle',
        },
      ],
    },
  },
];

export const htc920Menu: Menu[] = [
  {
    parent: 'Temperature Settings',
    child: {
      menu: [
        { parent: 'Control Temperature' },
        { parent: 'Control Modes' },
        { parent: 'Temperature Sensors' },
        { parent: 'Miscellaneous' },
      ],
    },
  },
  {
    parent: 'Temperature Alarm Settings',
    child: {
      menu: [
        {
          parent: 'TS1',
          child: {
            isPartOfForm: true,
            menu: [
              { parent: 'High TS1 Temperature' },
              { parent: 'Low TS1 Temperature' },
              { parent: 'TS1 Failure' },
              { parent: 'High Limit Temperature CutOut 1' },
            ],
          },
        },
        {
          parent: 'TS2',
          child: {
            isPartOfForm: true,
            menu: [
              { parent: 'High TS2 Temperature' },
              { parent: 'Low TS2 Temperature' },
              { parent: 'TS2 Failure' },
              { parent: 'High Limit Temperature CutOut 2' },
            ],
          },
        },
        {
          parent: 'TS Control',
        },
      ],
    },
  },
  {
    parent: 'Other Alarm Settings',
    child: {
      menu: [
        {
          parent: 'Failure and Trip Alarms',
          child: {
            isPartOfForm: true,
            menu: [
              { parent: 'Over current Trip' },
              { parent: 'Switch Failure' },
              { parent: 'HTC Reset' },
              { parent: 'EEROM Data Failure' },
            ],
          },
        },
        {
          parent: 'Limiting and Contactor Alarms',
          child: {
            isPartOfForm: true,
            menu: [
              { parent: 'C.B. Limiting' },
              { parent: 'Output Limiting' },
              { parent: 'Switch Limiting' },
              { parent: 'Contactor Count' },
            ],
          },
        },
        {
          parent: 'Alarm Priorities and Output',
          child: {
            isPartOfForm: true,
            menu: [{ parent: 'Flash Alarm Output' }],
          },
        },
      ],
    },
  },
  {
    parent: 'Electrical Settings',
    child: {
      menu: [
        {
          parent: 'Load Current Alarms',
          child: {
            isPartOfForm: true,
            menu: [{ parent: 'High Load Current' }, { parent: 'Low Load Current' }],
          },
        },
        {
          parent: 'Ground Fault Current Alarms',
          child: {
            isPartOfForm: true,
            menu: [{ parent: 'High Ground Fault Current' }, { parent: 'Ground Fault Trip Current' }],
          },
        },
        {
          parent: 'Voltage Alarms',
          child: {
            isPartOfForm: true,
            menu: [{ parent: 'High Voltage' }, { parent: 'Low Voltage' }],
          },
        },
        {
          parent: 'Resistance Alarms',
          child: {
            isPartOfForm: true,
            menu: [
              { parent: 'High Resistance' },
              { parent: 'Low Resistance' },
              { parent: 'Nominal Tracing Resistance' },
            ],
          },
        },
        {
          parent: 'Electrical Setup',
          child: {
            isPartOfForm: true,
            menu: [
              { parent: 'Switch Rating' },
              { parent: 'C.B. Current' },
              { parent: 'Maximum Power' },
              { parent: 'Voltage Source' },
              { parent: 'Voltage Turns Ratio' },
              { parent: 'Current Turns Ratio' },
              { parent: 'Three Phase Calculation' },
            ],
          },
        },
      ],
    },
  },
  {
    parent: 'Miscellaneous Settings',
    child: {
      menu: [
        {
          parent: 'Load Shedding',
          child: {
            isPartOfForm: true,
            menu: [
              { parent: 'Load Shedding Enable' },
              { parent: 'Fail Safe Mode' },
              { parent: 'Load Shedding Input #1' },
              { parent: 'Load Shedding Input #2' },
              { parent: 'Load Shedding Input #3' },
              { parent: 'Load Shedding Input #4' },
            ],
          },
        },
        {
          parent: 'External Input/Output',
          child: {
            isPartOfForm: true,
            menu: [{ parent: 'External Input Settings' }],
          },
        },
        { parent: 'Auto Cycle' },
      ],
    },
  },
];

export const cm2000Menu: Menu[] = [
  {
    parent: 'Temperature Settings',
    child: {
      menu: [
        {
          parent: 'Control Temperature',
        },
        {
          parent: 'Control Modes',
        },
        {
          parent: 'Miscellaneous',
        },
      ],
    },
  },
  {
    parent: 'Temperature Alarm Settings',
    child: {
      menu: [
        {
          parent: 'TS1',
          child: {
            isPartOfForm: true,
            menu: [{ parent: 'High TS1 Temperature' }, { parent: 'Low TS1 Temperature' }, { parent: 'TS1 Failure' }],
          },
        },
        {
          parent: 'TS2',
          child: {
            isPartOfForm: true,
            menu: [{ parent: 'High TS2 Temperature' }, { parent: 'Low TS2 Temperature' }, { parent: 'TS2 Failure' }],
          },
        },
        {
          parent: 'TS Control',
          child: {
            isPartOfForm: true,
            menu: [{ parent: 'TS Control Failure' }],
          },
        },
      ],
    },
  },
  {
    parent: 'Other Alarm Settings',
    child: {
      menu: [
        {
          parent: 'Failure and Trip Alarms',
          child: {
            isPartOfForm: true,
            menu: [
              { parent: 'Communication Failure' },
              { parent: 'Over Current Trip' },
              { parent: 'Switch Failure' },
              { parent: 'HTC Reset' },
              { parent: 'EEROM Data Failure' },
            ],
          },
        },
        {
          parent: 'Limiting and Contactor Alarms',
          child: {
            isPartOfForm: true,
            menu: [{ parent: 'C.B. Limiting' }, { parent: 'Output Limiting' }, { parent: 'Switch Limiting' }],
          },
        },
        {
          parent: 'Alarm Priorities and Output',
        },
      ],
    },
  },
  {
    parent: 'Electrical Settings',
    child: {
      menu: [
        {
          parent: 'Load Current Alarms',
          child: {
            isPartOfForm: true,
            menu: [{ parent: 'High Load Current' }, { parent: 'Low Load Current' }],
          },
        },
        {
          parent: 'Ground Fault Current Alarms',
          child: {
            isPartOfForm: true,
            menu: [{ parent: 'High Ground Fault Current' }, { parent: 'Ground Fault Trip Current' }],
          },
        },
        {
          parent: 'Voltage Alarms',
          child: {
            isPartOfForm: true,
            menu: [{ parent: 'High Voltage' }, { parent: 'Low Voltage' }],
          },
        },
        {
          parent: 'Electrical Setup',
        },
      ],
    },
  },
  {
    parent: 'Miscellaneous Settings',
    child: {
      menu: [
        {
          parent: 'Load Shedding',
        },
        {
          parent: 'Auto-cycle',
        },
      ],
    },
  },
];

export const cm2000pMenu: Menu[] = [
  {
    parent: 'Temperature Settings',
    child: {
      menu: [{ parent: 'Control Temperature' }, { parent: 'Control Modes' }, { parent: 'Miscellaneous' }],
    },
  },
  {
    parent: 'Temperature Alarm Settings',
    child: {
      menu: [
        {
          parent: 'TS1',
          child: {
            isPartOfForm: true,
            menu: [{ parent: 'High TS1 Temperature' }, { parent: 'Low TS1 Temperature' }, { parent: 'TS1 Failure' }],
          },
        },
        {
          parent: 'TS2',
          child: {
            isPartOfForm: true,
            menu: [{ parent: 'High TS2 Temperature' }, { parent: 'Low TS2 Temperature' }, { parent: 'TS2 Failure' }],
          },
        },
        {
          parent: 'TS Control',
          child: {
            isPartOfForm: true,
            menu: [{ parent: 'TS Control Failure' }],
          },
        },
      ],
    },
  },
  {
    parent: 'Other Alarm Settings',
    child: {
      menu: [
        {
          parent: 'Failure and Trip Alarms',
          child: {
            isPartOfForm: true,
            menu: [
              { parent: 'Communication Failure' },
              { parent: 'Over Current Trip' },
              { parent: 'Switch Failure' },
              { parent: 'HTC Reset' },
              { parent: 'EEROM Data Failure' },
            ],
          },
        },
        {
          parent: 'Limiting and Contactor Alarms',
          child: {
            isPartOfForm: true,
            menu: [{ parent: 'C.B. Limiting' }, { parent: 'Output Limiting' }, { parent: 'Switch Limiting' }],
          },
        },
        {
          parent: 'Alarm Priorities and Output',
        },
      ],
    },
  },
  {
    parent: 'Electrical Settings',
    child: {
      menu: [
        {
          parent: 'Load Current Alarms',
          child: {
            isPartOfForm: true,
            menu: [{ parent: 'High Load Current' }, { parent: 'Low Load Current' }],
          },
        },
        {
          parent: 'Ground Fault Current Alarms',
          child: {
            isPartOfForm: true,
            menu: [{ parent: 'High Ground Fault Current' }, { parent: 'Ground Fault Trip Current' }],
          },
        },
        {
          parent: 'Voltage Alarms',
          child: {
            isPartOfForm: true,
            menu: [{ parent: 'High Voltage' }, { parent: 'Low Voltage' }],
          },
        },
        {
          parent: 'Electrical Setup',
        },
      ],
    },
  },
  {
    parent: 'Miscellaneous Settings',
    child: {
      menu: [
        {
          parent: 'Load Shedding',
        },
        {
          parent: 'Auto-cycle',
        },
      ],
    },
  },
];

export const htcMenu: Menu[] = [
  {
    parent: 'Temperature Settings',
    child: {
      menu: [
        { parent: 'Control Temperature' },
        { parent: 'Control Modes' },
        { parent: 'Local RTD' },
        { parent: 'Temperature Source' },
      ],
    },
  },
  {
    parent: 'Alarm Settings',
    child: {
      menu: [
        {
          parent: 'Failure and Trip Alarms',
          child: {
            isPartOfForm: true,
            menu: [
              { parent: 'Device Reset Alarm' },
              { parent: 'Digital Input Remote Source Failure Alarm' },
              { parent: 'Load Shed Source Failure Alarm' },
              { parent: 'High Limit Cutout Alarm' },
            ],
          },
        },
        {
          parent: 'Contactor and Heater Time Alarm',
          child: {
            isPartOfForm: true,
            menu: [{ parent: 'Contactor Cycle Count Alarm' }, { parent: 'Heater Time Alarm' }],
          },
        },
        { parent: 'Alarm Output' },
        {
          parent: 'Temperature Alarms',
          child: {
            menu: [
              {
                parent: 'Control Temperature Alarms',
                child: {
                  isPartOfForm: true,
                  menu: [
                    { parent: 'Control Temperature Failure Alarm' },
                    { parent: 'Control Temperature High Alarm' },
                    { parent: 'Control Temperature Low Alarm' },
                  ],
                },
              },
              {
                parent: 'Local Temperature Sensor Alarms',
                child: {
                  isPartOfForm: true,
                  menu: [
                    { parent: 'Local Temperature Sensor Failure Alarm' },
                    { parent: 'Local Temperature Sensor High Alarm' },
                    { parent: 'Local Temperature Sensor Low Alarm' },
                  ],
                },
              },
              {
                parent: 'Temperature Source Alarms',
                child: {
                  isPartOfForm: true,
                  menu: [
                    { parent: 'Temperature Source 1 Failure Alarm' },
                    { parent: 'Temperature Source 2 Failure Alarm' },
                    { parent: 'Temperature Source 3 Failure Alarm' },
                    { parent: 'Temperature Source 4 Failure Alarm' },
                    { parent: 'Temperature Source 5 Failure Alarm' },
                    { parent: 'Temperature Source 6 Failure Alarm' },
                    { parent: 'Temperature Source 7 Failure Alarm' },
                    { parent: 'Temperature Source 8 Failure Alarm' },
                  ],
                },
              },
            ],
          },
        },
        {
          parent: 'Electrical Alarms',
          child: {
            menu: [
              {
                parent: 'Circuit Breaker and Output Switch Alarms',
              },
              {
                parent: 'Line Current Alarms',
                child: {
                  isPartOfForm: true,
                  menu: [{ parent: 'High Current Alarms' }, { parent: 'Low Current Alarms' }],
                },
              },
              {
                parent: 'Ground Fault Alarms',
                child: {
                  isPartOfForm: true,
                  menu: [
                    { parent: 'Ground Fault High Alarm' },
                    { parent: 'Ground Fault Trip' },
                    { parent: 'GFI Current Transformer Failure Alarm' },
                  ],
                },
              },
              {
                parent: 'Heating Cable Resistance Alarms',
                child: {
                  isPartOfForm: true,
                  menu: [
                    { parent: 'Heating Cable Resistance High Current Alarms' },
                    { parent: 'Heating Cable Resistance Low Current Alarms' },
                    { parent: 'Heating Cable Nominal Tracing Resistance' },
                  ],
                },
              },
            ],
          },
        },
        {
          parent: 'Other Alarms',
          child: {
            isPartOfForm: true,
            menu: [
              { parent: 'Digital Input Alarm' },
              { parent: 'Safety Temperature Limiter Tripped Alarm' },
              { parent: 'Safety Temperature Limiter Communication Failure Alarm' },
            ],
          },
        },
      ],
    },
  },
  {
    parent: 'Electrical Settings',
    child: {
      menu: [
        { parent: 'Electrical General' },
        {
          parent: 'Circuit Breaker',
        },
      ],
    },
  },
  {
    parent: 'Misc Settings',
    child: {
      menu: [
        { parent: 'Load Shedding' },
        {
          parent: 'Auto Cycle',
          child: {
            isPartOfForm: true,
            menu: [{ parent: 'Auto Cycle Interval' }, { parent: 'Digital Input' }],
          },
        },
        { parent: 'Device Information' },
      ],
    },
  },
];

export const elexant40x0Menu: Menu[] = [
  {
    parent: 'Temperature Settings',
    child: {
      menu: [
        {
          parent: 'Control Temperature',
          child: {
            isPartOfForm: true,
            menu: [
              { parent: 'Control Temperature' },
              { parent: 'Control Temperature High Alarm' },
              { parent: 'Control Temperature Low Alarm' },
              { parent: 'High Limit Cutout' },
              { parent: 'Low Limit Cutout' },
            ],
          },
        },
        { parent: 'Control Modes' },
        {
          parent: 'Temperature Sensors',
          child: {
            menu: [
              {
                parent: 'TS1',
                child: {
                  isPartOfForm: true,
                  menu: [
                    { parent: 'TS1' },
                    { parent: 'TS1 Failure Alarm' },
                    { parent: 'TS1 High Alarm' },
                    { parent: 'TS1 Low Alarm' },
                  ],
                },
              },
              {
                parent: 'TS2',
                child: {
                  isPartOfForm: true,
                  menu: [
                    { parent: 'TS2' },
                    { parent: 'TS2 Failure Alarm' },
                    { parent: 'TS2 High Alarm' },
                    { parent: 'TS2 Low Alarm' },
                  ],
                },
              },
              {
                parent: 'TS3',
                child: {
                  isPartOfForm: true,
                  menu: [
                    { parent: 'TS3' },
                    { parent: 'TS3 Failure Alarm' },
                    { parent: 'TS3 High Alarm' },
                    { parent: 'TS3 Low Alarm' },
                  ],
                },
              },
            ],
          },
        },
      ],
    },
  },
  {
    parent: 'Alarm Settings',
    child: {
      menu: [
        {
          parent: 'Failure and Trip Alarms',
          child: {
            isPartOfForm: true,
            menu: [
              { parent: 'Device Reset Alarm' },
              { parent: 'Limiter Tripped Alarm' },
              { parent: 'Limiter Temperature Sensor Failure Alarm Enable' },
            ],
          },
        },
        {
          parent: 'Contactor and Heater Time Alarm',
          child: {
            isPartOfForm: true,
            menu: [{ parent: 'Contactor Cycle Count Alarm' }, { parent: 'Heater Time Alarm' }],
          },
        },
        { parent: 'Alarm Output' },
        {
          parent: 'Electrical Alarms',
          child: {
            isPartOfForm: true,
            menu: [
              { parent: 'High Ground Fault Current Alarm' },
              { parent: 'High Trace Voltage Alarm' },
              { parent: 'Low Trace Voltage Alarm' },
            ],
          },
        },
        {
          parent: 'Circuit Breaker and Output Switch Alarms',
        },
      ],
    },
  },
  {
    parent: 'Electrical Settings',
    child: {
      menu: [
        {
          parent: 'GF Currents',
        },
        {
          parent: 'Trace Currents',
          child: {
            menu: [
              {
                parent: 'Line 1 Currents',
                child: {
                  isPartOfForm: true,
                  menu: [{ parent: 'High Trace Current L1' }, { parent: 'Low Trace Current L1' }],
                },
              },
            ],
          },
        },
        {
          parent: 'Resistances',
          child: {
            menu: [
              {
                parent: 'Line 1 Resistances',
                child: {
                  isPartOfForm: true,
                  menu: [
                    { parent: 'Nominal Trace Resistance L1' },
                    { parent: 'High Trace Resistance L1' },
                    { parent: 'Low Trace Resistance L1' },
                  ],
                },
              },
            ],
          },
        },
        {
          parent: 'Electrical Setup',
          child: {
            menu: [
              { parent: 'General' },
              {
                parent: 'Failure and Limiting Alarms',
              },
            ],
          },
        },
        {
          parent: 'Voltage Setup',
        },
      ],
    },
  },
  {
    parent: 'Misc Settings',
    child: {
      menu: [{ parent: 'Load Shedding' }, { parent: 'Digital Input' }, { parent: 'Auto-Cycle' }],
    },
  },
  {
    parent: 'Limiter Settings',
    child: {
      menu: [{ parent: 'Limiter Settings' }],
    },
  },
  {
    parent: 'Port Settings',
    child: {
      menu: [{ parent: 'Serial' }, { parent: 'Ethernet' }],
    },
  },
];

export const elexant5010Menu: Menu[] = [
  {
    parent: 'Temperature Settings',
    child: {
      menu: [
        {
          parent: 'Control Temperature',
          child: {
            isPartOfForm: true,
            menu: [
              { parent: 'Control Temperature' },
              { parent: 'Control Temperature High Alarm' },
              { parent: 'Control Temperature Low Alarm' },
              { parent: 'Low Limit Cutout' },
            ],
          },
        },
        {
          parent: 'Control Modes',
          child: {
            isPartOfForm: true,
            menu: [{ parent: 'Control Modes' }],
          },
        },
        {
          parent: 'Local RTD 1',
          child: {
            isPartOfForm: true,
            menu: [
              { parent: 'Local RTD 1' },

              {
                parent: 'Local RTD 1 High Alarm',
              },
              {
                parent: 'Local RTD 1 Low Alarm',
              },
            ],
          },
        },
        {
          parent: 'Local RTD 2',
          child: {
            isPartOfForm: true,
            menu: [
              { parent: 'Local RTD 2' },

              {
                parent: 'Local RTD 2 High Alarm',
              },
              {
                parent: 'Local RTD 2 Low Alarm',
              },
            ],
          },
        },
        {
          parent: 'Temperature Source 1-4',
          child: {
            menu: [
              {
                parent: 'Temperature Source 1',
                child: {
                  isPartOfForm: true,
                  menu: [
                    { parent: 'Temperature Source 1' },
                    {
                      parent: 'Temperature Source 1 Failure',
                    },
                  ],
                },
              },
              {
                parent: 'Temperature Source 2',
                child: {
                  isPartOfForm: true,
                  menu: [
                    { parent: 'Temperature Source 2' },
                    {
                      parent: 'Temperature Source 2 Failure',
                    },
                  ],
                },
              },
              {
                parent: 'Temperature Source 3',
                child: {
                  isPartOfForm: true,
                  menu: [
                    { parent: 'Temperature Source 3' },
                    {
                      parent: 'Temperature Source 3 Failure',
                    },
                  ],
                },
              },
              {
                parent: 'Temperature Source 4',
                child: {
                  isPartOfForm: true,
                  menu: [
                    { parent: 'Temperature Source 4' },
                    {
                      parent: 'Temperature Source 4 Failure',
                    },
                  ],
                },
              },
            ],
          },
        },
        {
          parent: 'Temperature Source 5-8',
          child: {
            menu: [
              {
                parent: 'Temperature Source 5',
                child: {
                  isPartOfForm: true,
                  menu: [
                    { parent: 'Temperature Source 5' },
                    {
                      parent: 'Temperature Source 5 Failure',
                    },
                  ],
                },
              },
              {
                parent: 'Temperature Source 6',
                child: {
                  isPartOfForm: true,
                  menu: [
                    { parent: 'Temperature Source 6' },
                    {
                      parent: 'Temperature Source 6 Failure',
                    },
                  ],
                },
              },
              {
                parent: 'Temperature Source 7',
                child: {
                  isPartOfForm: true,
                  menu: [
                    { parent: 'Temperature Source 7' },
                    {
                      parent: 'Temperature Source 7 Failure',
                    },
                  ],
                },
              },
              {
                parent: 'Temperature Source 8',
                child: {
                  isPartOfForm: true,
                  menu: [
                    { parent: 'Temperature Source 8' },
                    {
                      parent: 'Temperature Source 8 Failure',
                    },
                  ],
                },
              },
            ],
          },
        },
        {
          parent: 'Miscellaneous',
          child: {
            menu: [
              {
                parent: 'Console Temperature Unit',
                child: {
                  isPartOfForm: true,
                  menu: [{ parent: 'Display Units In' }],
                },
              },
            ],
          },
        },
      ],
    },
  },
  {
    parent: 'Alarms Settings',
    child: {
      menu: [
        {
          parent: 'Failures and Trip Alarms',
        },
        {
          parent: 'Contactor and Heater Time Alarm',
          child: {
            isPartOfForm: true,
            menu: [
              {
                parent: 'Contactor Cycle Count Alarm',
              },
              {
                parent: 'Heater Time Alarm',
              },
            ],
          },
        },
        {
          parent: 'Alarm Output',
        },
        {
          parent: 'Alarm Latch Settings',
          child: {
            isPartOfForm: true,
            menu: [
              {
                parent: 'Temperature Alarms',
              },

              {
                parent: 'Control Temperature Failure Alarm',
              },

              {
                parent: 'Alarm Latch Settings Electrical Alarms',
              },
              {
                parent: 'Other Alarms',
              },

              {
                parent: 'Limiter Communication Failure Alarm',
              },

              {
                parent: 'Configuration Lost Alarm Factory',
              },
              {
                parent: 'Configuration Lost Alarm User',
              },
            ],
          },
        },
        {
          parent: 'Electrical Alarms',
          child: {
            isPartOfForm: true,
            menu: [
              {
                parent: 'Heating Cable Resistance Alarms',
              },

              {
                parent: 'Heating Cable Resistance Alarms High Alarm',
              },
              {
                parent: 'Heating Cable Resistance Alarms Low Alarm',
              },

              {
                parent: 'Voltage Alarms High Alarm',
              },
              {
                parent: 'Voltage Alarms Low Alarm',
              },

              {
                parent: 'Current Alarms High Alarm',
              },
              {
                parent: 'Current Alarms Low Alarm',
              },
            ],
          },
        },
        {
          parent: 'Limiter Alarms',
          child: {
            isPartOfForm: true,
            menu: [
              {
                parent: 'Safety Temperature Limiter Alarms',
              },
            ],
          },
        },
      ],
    },
  },
  {
    parent: 'Electrical Settings',
    child: {
      menu: [
        {
          parent: 'Ground Fault Current',
          child: {
            isPartOfForm: true,
            menu: [
              { parent: 'Ground Fault Current' },
              { parent: 'Ground Fault Current High Alarm' },
              { parent: 'Ground Fault Trip' },
            ],
          },
        },
      ],
    },
  },
  {
    parent: 'Limiter Settings',
    child: {
      menu: [
        {
          parent: 'Safety Temperature Limiter Trip Setpoint',
          child: {
            isPartOfForm: true,
            menu: [{ parent: 'Safety Temperature Limiter Trip Setpoint' }],
          },
        },
      ],
    },
  },
  {
    parent: 'Misc Settings',
    child: {
      menu: [
        {
          parent: 'Misc Settings',
          child: {
            isPartOfForm: true,
            menu: [
              {
                parent: 'Load Shedding',
              },
              {
                parent: 'Auto-Cycle',
              },
              {
                parent: 'Communication Settings',
              },
              {
                parent: 'Communication Failure',
              },
              {
                parent: 'Device Settings',
              },
            ],
          },
        },
      ],
    },
  },
];

export const elexant3500iMenu: Menu[] = [
  {
    parent: 'Temperature Settings',
    child: {
      menu: [
        {
          parent: 'Control Temperature',
          child: {
            isPartOfForm: true,
            menu: [
              { parent: 'Control Temperature' },
              { parent: 'Control Temperature High Alarm' },
              { parent: 'Control Temperature Low Alarm' },
            ],
          },
        },
        { parent: 'Control Modes' },
        {
          parent: 'Local RTD 1',
          child: {
            isPartOfForm: true,
            menu: [
              { parent: 'Local RTD 1' },
              { parent: 'Local RTD 1 High Alarm' },
              { parent: 'Local RTD 1 Low Alarm' },
            ],
          },
        },
        {
          parent: 'Local RTD 2',
          child: {
            isPartOfForm: true,
            menu: [
              { parent: 'Local RTD 2' },
              { parent: 'Local RTD 2 High Alarm' },
              { parent: 'Local RTD 2 Low Alarm' },
            ],
          },
        },
        { parent: 'Temperature Source 1' },
        { parent: 'Temperature Source 2' },
      ],
    },
  },
  {
    parent: 'Alarm Settings',
    child: {
      menu: [
        {
          parent: 'Failure and Trip Alarms',
        },
        {
          parent: 'Contactor and Heater Time Alarm',
          child: {
            isPartOfForm: true,
            menu: [{ parent: 'Contactor Cycle Count Alarm' }, { parent: 'Heater Time Alarm' }],
          },
        },
        { parent: 'Alarm Output' },
        {
          parent: 'Alarm Latch Settings',
          child: {
            menu: [
              { parent: 'Temperature Alarms' },
              {
                parent: 'Electrical Alarms',
                child: {
                  isPartOfForm: true,
                  menu: [{ parent: 'Electrical Alarms' }, { parent: 'Low Line Current Alarm' }],
                },
              },
              {
                parent: 'Other Alarms',
                child: {
                  menu: [
                    { parent: 'Other Alarms' },
                    {
                      parent: 'Configuration Lost Alarm',
                      child: {
                        isPartOfForm: true,
                        menu: [{ parent: 'Factory' }, { parent: 'User' }],
                      },
                    },
                  ],
                },
              },
            ],
          },
        },
        {
          parent: 'Limiter Alarms',
          child: {
            menu: [
              {
                parent: 'Safety Temperature Limiter Alarms',
                child: {
                  isPartOfForm: true,
                  menu: [
                    { parent: 'Safety Temperature Limiter Alarms' },
                    { parent: 'Temperature Sensor Failure Alarm' },
                  ],
                },
              },
            ],
          },
        },
      ],
    },
  },
  {
    parent: 'Electrical Settings',
    child: {
      menu: [
        {
          parent: 'Ground Fault Current',
          child: {
            isPartOfForm: true,
            menu: [
              // The only field in the below form is not available in DB so commenting the entire form
              // { parent: 'Ground Fault Current' },
              { parent: 'Ground Fault Current High Alarm' },
              { parent: 'Ground Fault Trip' },
            ],
          },
        },
      ],
    },
  },
  {
    parent: 'Limiter Settings',
    child: {
      menu: [
        {
          parent: 'Safety Temperature Limiter Trip Setpoint',
        },
      ],
    },
  },
  {
    parent: 'Misc Settings',
    child: {
      menu: [
        { parent: 'Auto-Cycle' },
        { parent: 'Communication Settings' },
        { parent: 'Communication Failure' },
        { parent: 'Device Settings' },
      ],
    },
  },
];

export const slimMenu: Menu[] = [
  {
    parent: 'Temperature Settings',
    child: {
      menu: [
        {
          parent: 'Temperature Sensors 1-3',
          child: {
            isPartOfForm: true,
            menu: [{ parent: 'Temperature Sensor Settings' }, { parent: 'Temperature Usage Settings' }],
          },
        },
      ],
    },
  },
  {
    parent: 'Alarm Settings',
    child: {
      menu: [
        { parent: 'Alarm Output' },
        {
          parent: 'Failure and Trip Alarms',
          child: {
            isPartOfForm: true,
            menu: [
              { parent: 'Temperature Sensor 1 Failure Alarm' },
              { parent: 'Temperature Sensor 2 Failure Alarm' },
              { parent: 'Temperature Sensor 3 Failure Alarm' },
              { parent: 'Limiter Reset Alarm' },
              { parent: 'Limiter Tripped Alarm' },
              { parent: 'HTC Communication Failure Alarm' },
            ],
          },
        },
        { parent: 'Contactor Alarm' },
      ],
    },
  },
  {
    parent: 'Misc Settings',
    child: {
      menu: [{ parent: 'Device Information' }],
    },
  },
];

export const ioMenu: Menu[] = [
  {
    parent: 'Temperature Settings',
    child: {
      menu: [
        {
          parent: 'General',
          child: {
            menu: [{ parent: 'Alarms and Output' }],
          },
        },
        {
          parent: 'Temperature Sensors',
          child: {
            menu: [
              {
                parent: 'TS 1',
                child: {
                  isPartOfForm: true,
                  menu: [
                    { parent: 'TS 1 RTD' },
                    { parent: 'TS 1 High Alarm' },
                    { parent: 'TS 1 Low Alarm' },
                    { parent: 'TS 1 Failure Alarm' },
                  ],
                },
              },
              {
                parent: 'TS 2',
                child: {
                  isPartOfForm: true,
                  menu: [
                    { parent: 'TS 2 RTD' },
                    { parent: 'TS 2 High Alarm' },
                    { parent: 'TS 2 Low Alarm' },
                    { parent: 'TS 2 Failure Alarm' },
                  ],
                },
              },
              {
                parent: 'TS 3',
                child: {
                  isPartOfForm: true,
                  menu: [
                    { parent: 'TS 3 RTD' },
                    { parent: 'TS 3 High Alarm' },
                    { parent: 'TS 3 Low Alarm' },
                    { parent: 'TS 3 Failure Alarm' },
                  ],
                },
              },
              {
                parent: 'TS 4',
                child: {
                  isPartOfForm: true,
                  menu: [
                    { parent: 'TS 4 RTD' },
                    { parent: 'TS 4 High Alarm' },
                    { parent: 'TS 4 Low Alarm' },
                    { parent: 'TS 4 Failure Alarm' },
                  ],
                },
              },
            ],
          },
        },
      ],
    },
  },
  {
    parent: 'Alarm Settings',
    child: {
      menu: [
        // No configurables given for the following menu-item
        { parent: 'IO Alarms and Output' },
        {
          parent: 'Alarm Sources',
          child: {
            menu: [
              {
                parent: 'Alarm Source 1-10',
                child: {
                  isPartOfForm: true,
                  menu: [{ parent: 'Source 1 To 5 Failure Alarm' }, { parent: 'Source 6 To 10 Failure Alarm' }],
                },
              },
              {
                parent: 'Alarm Source 11-20',
                child: {
                  isPartOfForm: true,
                  menu: [{ parent: 'Source 11 To 15 Failure Alarm' }, { parent: 'Source 16 To 20 Failure Alarm' }],
                },
              },
              {
                parent: 'Alarm Source 21-30',
                child: {
                  isPartOfForm: true,
                  menu: [{ parent: 'Source 21 To 25 Failure Alarm' }, { parent: 'Source 26 To 30 Failure Alarm' }],
                },
              },
              {
                parent: 'Alarm Source 31-40',
                child: {
                  isPartOfForm: true,
                  menu: [{ parent: 'Source 31 To 35 Failure Alarm' }, { parent: 'Source 36 To 40 Failure Alarm' }],
                },
              },
              {
                parent: 'Alarm Source 41-50',
                child: {
                  isPartOfForm: true,
                  menu: [{ parent: 'Source 41 To 45 Failure Alarm' }, { parent: 'Source 46 To 50 Failure Alarm' }],
                },
              },
              {
                parent: 'Alarm Source 51-60',
                child: {
                  isPartOfForm: true,
                  menu: [{ parent: 'Source 51 To 55 Failure Alarm' }, { parent: 'Source 56 To 60 Failure Alarm' }],
                },
              },
              {
                parent: 'Alarm Source 61-70',
                child: {
                  isPartOfForm: true,
                  menu: [{ parent: 'Source 61 To 65 Failure Alarm' }, { parent: 'Source 66 To 70 Failure Alarm' }],
                },
              },
              {
                parent: 'Alarm Source 71-80',
                child: {
                  isPartOfForm: true,
                  menu: [{ parent: 'Source 71 To 75 Failure Alarm' }, { parent: 'Source 76 To 80 Failure Alarm' }],
                },
              },
            ],
          },
        },
        {
          parent: 'Other Alarms',
          child: {
            menu: [
              {
                parent: 'Temperature Sensor 1 Alarms',
              },
              {
                parent: 'Temperature Sensor 2 Alarms',
              },
              {
                parent: 'Temperature Sensor 3 Alarms',
              },
              { parent: 'Digital Input Alarm' },
            ],
          },
        },
      ],
    },
  },
  {
    parent: 'Misc Settings',
    child: {
      menu: [{ parent: 'Device Information' }],
    },
  },
];
