import { TextOnBooleanValues } from '@app/models/configurator-models/form-fields';

export const enableAttributes: TextOnBooleanValues = {
  textOnFalse: 'Disabled',
  textOnTrue: 'Enabled',
};

export const modeAttributes: TextOnBooleanValues = {
  textOnFalse: 'Off',
  textOnTrue: 'On',
};

export const yesNoAttributes: TextOnBooleanValues = {
  textOnFalse: 'Yes',
  textOnTrue: 'No',
};

export const periodAttributes: TextOnBooleanValues = {
  textOnFalse: 'Hour',
  textOnTrue: 'minute',
};

export const switchAttributes: TextOnBooleanValues = {
  textOnFalse: 'Normally Closed',
  textOnTrue: 'Normally Open',
};

export const externalInputSourceAttributes: TextOnBooleanValues = {
  textOnFalse: 'Remote',
  textOnTrue: 'External Input',
};

export const latchAttributes: TextOnBooleanValues = {
  textOnFalse: 'Non-Latching',
  textOnTrue: 'Latching',
};

export const priorityAttributes: TextOnBooleanValues = {
  textOnFalse: 'Warning',
  textOnTrue: 'Alarm',
};

export const alarmPriorityAttributes: TextOnBooleanValues = {
  textOnFalse: 'Global Priorities',
  textOnTrue: 'Local Priorities',
};

export const temperatureAttributes: TextOnBooleanValues = {
  textOnFalse: 'Fahrenheit',
  textOnTrue: 'Celsius',
};

export const timeAttributes: TextOnBooleanValues = {
  textOnFalse: 'Hour',
  textOnTrue: 'Minute',
};

export const comfirmChangesColumns = [
  { header: 'Field', field: 'name' },
  { header: 'Old Value', field: 'previousValue' },
  { header: 'New Value', field: 'newValue' },
];
