<div class="device-details-outlet">
  <div class="row-flex device-details-header">
    <div class="row-flex add-gap">
      <span class="font-lg">{{ device.deviceTag | uppercase }}</span>
      <button class="disabled" [disabled]="true" [matTooltip]="disabledTooltipMessage">
        <span class="material-symbols-filled"> edit </span>
      </button>
    </div>
    <div class="row-flex add-gap">
      @for (iconName of disabledButtonIcons; track $index) {
        <button class="disabled" [disabled]="true" [matTooltip]="disabledTooltipMessage">
          <span class="material-symbols-filled"> {{ iconName }} </span>
        </button>
      }
      <button
        [ngClass]="notesOverlayToggle ? 'notes-btn-enabled' : 'notes-btn-disabled'"
        matTooltip="Notes"
        (click)="toggleNotesOverlay()">
        <span class="material-symbols-filled"> text_snippet </span>
      </button>
      <button class="close-button" [routerLink]="'/devices'">
        <span class="material-symbols-filled"> close </span>
      </button>
    </div>
  </div>
  <div class="tab-container">
    <mat-tab-group (selectedTabChange)="tabClick($event)" [selectedIndex]="activeTabIndex" animationDuration="0ms">
      <mat-tab label="Details">
        <div class="details-content">
          @for (cardInfo of overviewContent; track $index) {
            <ng-container
              [ngTemplateOutlet]="cardTemplate"
              [ngTemplateOutletContext]="{ cardInfo: cardInfo }"></ng-container>
          }
        </div>
      </mat-tab>
      <mat-tab label="Alarms">
        <block-ui>
          @if (this.deviceAlarms) {
            <app-alarms-table-view
              [alarmsList]="this.deviceAlarms"
              [displayedColumns]="displayedColumns"
              [listOfTableColumns]="listOfTableColumns"
              [isFilterVisible]="false"
              class="alarms-content" />
          }
        </block-ui>
      </mat-tab>

      <mat-tab label="Configure" [disabled]="!configureDeviceTypes.includes(device.deviceType!)">
        <block-ui>
          <div *blockUI="'configureBody'">
            @if (configureDeviceTypes.includes(device.deviceType!) && peerDevicesList) {
              <app-configurator [device]="device" [peerDevices]="peerDevicesList" />
            }
          </div>
        </block-ui>
      </mat-tab>
      <mat-tab label="Trend">
        <block-ui>
          @if (showTrendsTab) {
            <app-trend [device]="device"></app-trend>
          }
        </block-ui>
      </mat-tab>
      <mat-tab label="Maintenance Info" [disabled]="maintenanceDisabledDeviceTypes.includes(device.deviceType!)">
        <block-ui>
          <div *blockUI="'maintenanceBody'">
            @if (device && !maintenanceDisabledDeviceTypes.includes(device.deviceType!)) {
              <app-maintenance-info
                [device]="device"
                [isDeviceExist]="isDeviceExist"
                [latestMaintenanceInfo]="deviceMaintenanceInfo" />
            }
          </div>
        </block-ui>
      </mat-tab>
      @for (tab of disabledTabs; track $index) {
        <mat-tab class="disabled" label="{{ tab }}" [disabled]="true"></mat-tab>
      }
    </mat-tab-group>
  </div>
</div>

@if (notesOverlayToggle) {
  <app-device-notes [deviceId]="device.id!" [deviceTag]="device.deviceTag!" (closeEvent)="toggleNotesOverlay()">
  </app-device-notes>
}

<ng-template #cardTemplate let-cardInfo="cardInfo">
  <div ngClass="{{ cardInfo.title === 'Device Info' ? 'device-info-data' : 'temperature-electrical-data' }}">
    <mat-card>
      <mat-card-header>{{ cardInfo.title }}</mat-card-header>
      <mat-card-content>
        <div class="{{ cardInfo.class }}">
          @for (attribute of cardInfo.attributes; track $index) {
            <div class="col-flex attribute-container">
              <span class="attribute-header">{{ attribute.header }}</span>
              <div class="row-flex add-gap">
                <span class="font-med attribute-value">
                  @if (attribute.header === 'Type') {
                    {{ attribute.field | deviceAttributeValue: device : true | titlecase }}
                  } @else {
                    {{ attribute.field | deviceAttributeValue: device : true }}
                  }
                </span>
                @if (attribute.field === 'firmwareVersion') {
                  <button class="disabled refresh-button" [disabled]="true" [matTooltip]="disabledTooltipMessage">
                    <span class="material-symbols-filled"> cached </span>
                  </button>
                }
              </div>
            </div>
          }
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</ng-template>
