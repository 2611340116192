<div class="table-wrapper devices-table" [ngClass]="{ 'device-details-table': deviceTag }">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" #table>
    @for (column of listOfTableColumns; track $index) {
      <ng-container
        [matColumnDef]="column.field"
        [sticky]="column.isStickyStart && !this.deviceTag"
        [stickyEnd]="column.isStickyEnd && !this.deviceTag">
        <th
          mat-header-cell
          *matHeaderCellDef
          data-cy="table-header"
          [isResizingOn]="!this.deviceTag"
          (setTable)="updateTableState()"
          column-resizer
          matSort
          mat-sort-header="{{ column.field }}"
          matSortActive="deviceTag"
          matSortDirection="asc"
          matSortDisableClear
          (matSortChange)="sortData($event)">
          @switch (column.field) {
            @case (this.customizeColumns.field) {
              <app-customize-columns
                [columns]="listOfTableColumns"
                (onColumnsChange)="setTableColumns($event)"
                tableName="Devices" />
            }
            @default {
              <div class="table-header flex-center">
                <div class="flex-col-center">
                  @if (column.isStickyStart) {
                    <span class="material-symbols-outlined general-icon">lock</span>
                  }
                  <span>{{ column.header }}</span>
                  <span class="attribute-unit">{{ column.unit }}</span>
                </div>
                <span class="material-symbols-outlined sort-header">
                  <mat-icon
                    class="arrow-up-icon"
                    [ngClass]="
                      lastSort.direction === 'asc' && column.field === lastSort.active
                        ? 'arrow-active'
                        : 'arrow-inactive'
                    ">
                    arrow_drop_up
                  </mat-icon>
                  <mat-icon
                    class="arrow-down-icon"
                    [ngClass]="
                      lastSort.direction === 'asc' && column.field === lastSort.active
                        ? 'arrow-active'
                        : 'arrow-inactive'
                    ">
                    arrow_drop_down
                  </mat-icon>
                </span>
              </div>
            }
          }
        </th>

        <td mat-cell *matCellDef="let data">
          @if (data.deviceData && column.field | deviceAttributeValue: data.deviceData; as attributeValue) {
            @if (column.field === 'controlTemperature' || column.field === 'deviceTag') {
              <div class="col-flex device-data-attribute">
                <span [ngClass]="column.field === 'controlTemperature' ? 'control-temperature-column' : ''">
                  {{ attributeValue | uppercase }}
                </span>
                <span class="table-sub-row-content">
                  @if (column.field === 'controlTemperature') {
                    Set point:
                    {{ 'setPoint' | deviceAttributeValue: data.deviceData }}
                  } @else {
                    {{ 'deviceType' | deviceAttributeValue: data.deviceData }}
                  }
                </span>
              </div>
            } @else if (column.field === 'alarmsCount' && attributeValue !== '0') {
              <div class="flex-col-center table-notification alarm">
                <span class="material-symbols-filled"> notifications_active </span>
                <div>
                  {{ attributeValue }}
                </div>
              </div>
            } @else if (column.field === 'outputState' && attributeValue !== '-') {
              <div class="flex-col-center table-notification output-state-{{ attributeValue }}">
                {{ attributeValue }}
              </div>
            } @else if (column.field === customizeColumns.field) {
              <div class="flex-around">
                <button
                  mat-icon-button
                  [ngClass]="
                    notesOverlayToggle && data.deviceData.id === selectedNoteDeviceId
                      ? 'notes-btn-enabled'
                      : 'notes-btn-disabled'
                  "
                  matTooltip="Notes"
                  (click)="$event.stopPropagation(); toggleNotesOverlay(data.deviceData)">
                  <span class="material-symbols-filled"> text_snippet </span>
                </button>
                <button
                  *check-permissions="[devicePermissions.DeleteDevice, devicePermissions.UpdateDevice]"
                  mat-icon-button
                  [ngClass]="trigger.menuOpen ? 'active-menu' : 'inactive-menu'"
                  #trigger="matMenuTrigger"
                  [matMenuTriggerFor]="menu"
                  class="material-symbols-outlined"
                  (click)="$event.stopPropagation()">
                  more_horiz
                </button>
              </div>
              <mat-menu #menu="matMenu" class="overlay-container">
                @for (menuItem of actionMenuItems; track $index) {
                  @if (menuItem.subMenu) {
                    <button
                      *check-permissions="[menuItem.permission]"
                      mat-menu-item
                      [disabled]="menuItem.disabled"
                      [matTooltip]="featureUnavailableTooltip"
                      [matMenuTriggerFor]="sub_menu">
                      {{ menuItem.title }}
                    </button>
                  } @else {
                    <button
                      *check-permissions="[menuItem.permission]"
                      mat-menu-item
                      [matTooltip]="menuItem.disabled ? featureUnavailableTooltip : ''"
                      [disabled]="menuItem.disabled"
                      (click)="onActionMenuOptionClick(menuItem.title, data)">
                      {{ menuItem.title }}
                    </button>
                  }
                  <mat-menu #sub_menu="matMenu">
                    @for (subMenuItem of menuItem.subMenu; track $index) {
                      <button [matTooltip]="featureUnavailableTooltip" mat-menu-item [disabled]="subMenuItem.disabled">
                        {{ subMenuItem.title }}
                      </button>
                    }
                  </mat-menu>
                }
              </mat-menu>
            } @else {
              <span>{{ attributeValue }}</span>
            }
          }
        </td>
      </ng-container>
    }
    <tr mat-header-row *matHeaderRowDef="displayedColumnFields(); sticky: true"></tr>
    <tr
      mat-row
      [id]="row.deviceData.deviceTag"
      [ngClass]="{ 'highlight-row': deviceTag === row.deviceData.deviceTag }"
      [routerLink]="['/devices', row.deviceData.deviceTag]"
      *matRowDef="let row; columns: displayedColumnFields()"></tr>

    <ng-container matColumnDef="groupHeader" sticky="true">
      <td mat-cell *matCellDef="let data" colspan="4" class="group-header">
        <div class="flex-col-center group-title" (click)="treeControl.toggle(data); updateTableState()">
          <span>{{ data.name.toString() | titlecase }}</span>
          <span class="item-count">({{ data.itemCount }})</span>
          <span class="material-symbols-outlined arrow-icon" [style.visibility]="!data.expandable ? 'hidden' : ''">
            @if (treeControl.isExpanded(data)) {
              arrow_drop_down
            } @else {
              arrow_right
            }
          </span>
        </div>
      </td>
    </ng-container>
    @if (!this.deviceTag) {
      <ng-container matColumnDef="groupScrollable">
        <td mat-cell *matCellDef="let data" colspan="999" class="group-scrollable"></td>
      </ng-container>
    }
    <tr mat-row *matRowDef="let row; columns: headerColumns; when: hasChild" class="group-container"></tr>
  </table>
  <div #resizeHelper class="column-resizer-helper"></div>
</div>
@if (modal.show) {
  <app-dialog [modal]="modal" (onConfirm)="onDeviceRemove()"></app-dialog>
}
@if (notesOverlayToggle) {
  <app-device-notes
    [deviceId]="selectedNoteDevices.id!"
    [deviceTag]="selectedNoteDevices.deviceTag!"
    (closeEvent)="toggleNotesOverlay()">
  </app-device-notes>
}
