<app-header [title]="pageTitle" (selectedGroupEvent)="onGroupUpdate($event)" (searchEvent)="onDeviceListUpdate($event)">
  <ng-container header-left-content>
    <button
      (click)="selectOverlay(groupOverlay, 'groupByBtn')"
      cdkOverlayOrigin
      #groupOverlay="cdkOverlayOrigin"
      class="round-edge-button">
      <div class="flex font-med cl-dark">
        <span>Group by {{ selectedGroup }}</span>
        <span class="material-symbols-outlined cl-dark"
          >arrow_drop_{{ isDropdownOpen && showGroupOptions ? 'up' : 'down' }}</span
        >
      </div>
    </button>
  </ng-container>
  <ng-container header-right-content>
    <button
      *check-permissions="[permissionList.DevicePermissions.CreateDevice]"
      (click)="selectOverlay(addOverlay, 'addBtn')"
      cdkOverlayOrigin
      #addOverlay="cdkOverlayOrigin"
      class="round-edge-button btn-black add-device-button">
      <div class="cl-light flex-col-center font-med">
        <span>Add Device</span>
        <div class="btn-divider"></div>
        <span class="cl-light material-symbols-outlined"
          >arrow_drop_{{ isDropdownOpen && !showGroupOptions ? 'up' : 'down' }}</span
        >
      </div>
    </button>
  </ng-container>
</app-header>
<block-ui>
  @if (devicesData.length) {
    <div [ngClass]="{ 'device-details': selectedDevice }" class="devices-container">
      <div [ngClass]="{ 'device-details-table': selectedDevice }">
        <app-devices-table-view
          [deviceList]="devicesData"
          [listOfTableColumns]="listOfTableColumns"
          [selectedGroupKey]="selectedGroupKey"
          [groupList]="groupList"
          (selectedDevice)="setDeviceDetails($event)" />
      </div>
      @if (selectedDevice) {
        <div class="device-details-container">
          <app-device-details [device]="selectedDevice"></app-device-details>
        </div>
      }
    </div>
  } @else if (!isloading && !devicesData.length) {
    <div class="col-flex no-result-container">
      <app-no-data-available
        [header]="'No Device Available'"
        [subHeader]="'Please try with different search criteria.'" />
    </div>
  }
</block-ui>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="overlayType"
  [cdkConnectedOverlayOpen]="isDropdownOpen"
  (overlayOutsideClick)="isDropdownOpen = false"
  [cdkConnectedOverlayHasBackdrop]="true"
  cdkConnectedOverlayBackdropClass="no-style">
  <div class="overlay-container">
    @if (showGroupOptions) {
      <app-accordion
        (onGroupSelect)="onGroupUpdate($event)"
        [menus]="groups"
        [selectedGroup]="selectedGroup"></app-accordion>
    } @else {
      <mat-nav-list>
        <a mat-list-item routerLink="/add-device"> <span>Create new device</span></a>
        <a mat-list-item disabled="true"> <span class="disabled">Add by uploading file</span></a>
      </mat-nav-list>
    }
  </div>
</ng-template>
