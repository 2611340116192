<div *ngIf="alarmsList.length === 0 && !isLoading; then noDataFoundTemplate"></div>
<ng-template #noDataFoundTemplate>
  <app-no-data-available [header]="'No Alarm Data'" [subHeader]="'There is no alarm data to display.'" />
</ng-template>
<div *blockUI="blockUI.name" [hidden]="alarmsList.length === 0 && !isLoading" class="flex-between col-flex">
  <div class="table-wrapper alarm-table">
    <table
      mat-table
      [dataSource]="dataSource"
      [trackBy]="trackAlarms"
      id="alarm-list"
      matSort
      matSortActive="timestamp"
      matSortDirection="desc"
      (matSortChange)="sortDataDirection($event)"
      matSortDisableClear>
      @if (filterForm) {
        <form [formGroup]="filterForm">
          @for (column of listOfTableColumns; track $index) {
            <ng-container [matColumnDef]="column.field">
              <tr>
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  data-cy="table-header"
                  [isResizingOn]="isResizingOn"
                  column-resizer>
                  <div class="table-header">
                    <div class="col-flex table-sub-header">
                      <div mat-sort-header="{{ column.field }}" class="flex-center">
                        @if (column.header !== 'Device Notes') {
                          <span>
                            {{ column.header }}
                          </span>
                          <div class="col-flex">
                            <span class="material-symbols-outlined col-flex" [matTooltip]="'Sort'">
                              <mat-icon
                                class="arrow-up-icon"
                                [ngClass]="
                                  sortState.direction === 'asc' && column.field === sortState.active
                                    ? 'arrow-active'
                                    : 'arrow-inactive'
                                ">
                                arrow_drop_up
                              </mat-icon>
                              <mat-icon
                                class="arrow-down-icon"
                                [ngClass]="
                                  sortState.direction === 'desc' && column.field === sortState.active
                                    ? 'arrow-active'
                                    : 'arrow-inactive'
                                ">
                                arrow_drop_down
                              </mat-icon>
                            </span>
                          </div>
                        }
                        @if (column.field === 'alarmType') {
                          <button
                            class="info-button"
                            [matTooltip]="'Show Description'"
                            (click)="setAlarmDescription(); $event.stopPropagation()">
                            <span class="material-symbols-filled"> info </span>
                          </button>
                        }
                      </div>
                      @if (isFilterVisible) {
                        <div
                          id="alarmFilters"
                          [ngClass]="column.header === 'Device Notes' ? 'empty-search-field' : 'search-field-wrapper'">
                          @if (column.header !== 'Device Notes') {
                            <mat-form-field appearance="outline" class="search-field">
                              <div class="flex-center alarm-search">
                                <span class="flex-center">
                                  <span class="material-symbols-outlined flex-col-center cl-grey search-bar"
                                    >search</span
                                  >
                                  <input
                                    matInput
                                    formControlName="{{ column.field }}"
                                    placeholder="Search"
                                    id="{{ column.field }}" />
                                </span>
                                @if (filterForm.get(column.field)?.value?.length) {
                                  <span
                                    id="{{ column.field }}"
                                    class="material-symbols-outlined clear-icon"
                                    (click)="clearSearch(column.field)"
                                    >close</span
                                  >
                                }
                              </div>
                            </mat-form-field>
                          }
                        </div>
                      }
                    </div>
                  </div>
                </th>
              </tr>
              <td mat-cell *matCellDef="let data">
                @if (column.field | alarmAttributeValue: data; as attributeValue) {
                  @switch (column.header) {
                    @case ('Device Tag') {
                      <div class="col-flex device-tag-column" [routerLink]="['/devices', attributeValue]">
                        <span>{{ attributeValue.toString() | uppercase }} </span>
                        <span class="table-sub-row-content">
                          {{ 'deviceType' | alarmAttributeValue: data }}
                        </span>
                      </div>
                    }
                    @case ('Alarm State') {
                      <div class="alarm-state-select">
                        <mat-select
                          placeholder="{{ data.alarmState }}"
                          (selectionChange)="onSelected(data, $event)"
                          [disabled]="disableStateChange"
                          data-testid="alarmStateSelect"
                          id="alarmStateSelect-{{ data.id }}"
                          class="alarm-state-dropdown">
                          @for (alarmState of alarmStates; track $index) {
                            <mat-option
                              [value]="alarmState.updatedState"
                              [hidden]="disabledAlarmStates(data.alarmState, alarmState)">
                              {{
                                alarmState.updatedState === data.alarmState ? alarmState.updatedState : alarmState.state
                              }}
                            </mat-option>
                          }
                        </mat-select>
                      </div>
                    }
                    @case ('Shelve Time Remaining') {
                      @if (attributeValue !== 'NA') {
                        <app-alarm-timer
                          class="shelve-timer"
                          [date]="attributeValue"
                          (isShelveTimeCompletedEmitter)="updateAlarmState(data, '')"></app-alarm-timer>
                      } @else {
                        {{ attributeValue }}
                      }
                    }
                    @case ('Timestamp') {
                      <div class="time-column-wrapper">
                        <div class="col-flex time-column-content">
                          <span class="table-sub-row-content">{{ attributeValue[0] }} </span>
                          <span class="time-value">
                            {{ attributeValue[1] }}
                          </span>
                        </div>
                      </div>
                    }
                    @default {
                      {{ attributeValue }}
                    }
                  }
                } @else if (column.header === 'Device Notes') {
                  <div class="flex-center">
                    <button
                      mat-icon-button
                      [ngClass]="
                        notesOverlayToggle && data.deviceId === selectedDeviceId
                          ? 'notes-btn-enabled'
                          : 'notes-btn-disabled'
                      "
                      matTooltip="Notes"
                      (click)="toggleNotesOverlay(data.deviceId, data.deviceTag!)">
                      <span class="material-symbols-filled"> text_snippet </span>
                    </button>
                  </div>
                }
              </td>
            </ng-container>
          }
        </form>
      }
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <div *ngIf="filteredAlarms.length === 0; then noDataFoundTemplate"></div>
    @if (notesOverlayToggle) {
      <app-device-notes
        [deviceId]="selectedDeviceId!"
        [deviceTag]="selectedDeviceTag!"
        (closeEvent)="toggleNotesOverlay()">
      </app-device-notes>
    }
    @if (showAlarmShelveForm && selectedAlarm) {
      <app-alarm-shelve-form
        [alarmData]="selectedAlarm"
        (saveSuccesEvent)="closeAlarmOnSave()"
        (cancelOnClickEvent)="closeAlarmOnCancel()"
        (errorEvent)="displayUpdateStateError()" />
    }
    @if (showAlarmDescription) {
      <app-alarm-description (closeEvent)="setAlarmDescription()"></app-alarm-description>
    }
  </div>
  <mat-paginator
    [hidden]="!isAlarmPage"
    #paginator
    [pageSizeOptions]="pageOptions"
    showFirstLastButtons
    class="sticky-paginator" />
  <div #resizeHelper class="column-resizer-helper"></div>
</div>
