import { Configurable } from '@app/models/configurator-models/form-fields';

export const updateBooleanAttributes = (
  params: Configurable,
  texts: { textOnFalse: string; textOnTrue: string },
): Configurable => {
  params.type = 'choice';
  params.options = [
    { key: 0, value: false, displayText: texts.textOnFalse },
    { key: 1, value: true, displayText: texts.textOnTrue },
  ];
  return params;
};
