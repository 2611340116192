import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { deviceTypes, siteHierarchy } from '@app/utils/constants/device-constants';
import cloneDeep from 'lodash-es/cloneDeep';
import { Location } from '@angular/common';
import { ipv4_pattern, remove_leading_space, remove_trailing_space } from '@app/utils/patterns';

@Component({
  selector: 'app-add-devices',
  templateUrl: './add-devices.component.html',
  styleUrl: './add-devices.component.css',
  encapsulation: ViewEncapsulation.None,
})
export class AddDevicesComponent implements OnInit {
  addDevicesForm!: FormGroup;
  deviceTypes = cloneDeep(deviceTypes);

  constructor(
    public location: Location,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.addDevicesForm = this.formBuilder.group({
      deviceTag: new FormControl<string>('', {
        validators: [Validators.required],
        updateOn: 'change',
      }),
      deviceType: new FormControl<string>('', { validators: [Validators.required] }),
      siteHierarchy: new FormControl<string>({ value: '', disabled: true }, { validators: [Validators.required] }),
      modbusAddress: new FormControl<string>('', {
        validators: [Validators.required, this.modbusAddressValidator],
        updateOn: 'change',
      }),
      ipAddress: new FormControl<string>('', {
        validators: [Validators.required, this.ipAddressValidator],
        updateOn: 'change',
      }),
      offlineState: new FormControl<string>(''),
    });
    this.checkforChanges();
  }

  checkforChanges(): void {
    Object.keys(this.addDevicesForm.controls).forEach(key => {
      this.addDevicesForm.get(key)?.valueChanges.subscribe(value => {
        if (typeof value === 'string') {
          if (key === 'deviceTag') {
            const newValue = value.replace(remove_leading_space, '');
            this.addDevicesForm.get(key)?.setValue(newValue, { emitEvent: false });
          } else {
            this.addDevicesForm.get(key)?.setValue(value.trim(), { emitEvent: false });
          }
          this.addDevicesForm.get(key)?.markAsTouched();
        }
      });
    });
  }

  modbusAddressValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (!value) return null;
    const valid = Number.isInteger(+value) && +value >= 1 && +value <= 247 && value.toString().indexOf('.') === -1;
    return valid ? null : { modbusAddress: { value: 'Modbus address must be an integer between 1 and 247' } };
  }

  ipAddressValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (!value) return null;
    const valid = ipv4_pattern.test(value);
    return valid ? null : { ipAddress: { value: 'Please enter valid IP address (eg: 192.168.1.1)' } };
  }

  onCancel(): void {
    this.addDevicesForm.reset();
    this.location.back();
  }

  onSave(): void {
    if (this.addDevicesForm.valid) {
      const deviceTagControl = this.addDevicesForm.get('deviceTag');
      if (deviceTagControl) {
        const deviceTagValue = deviceTagControl.value;
        if (typeof deviceTagValue === 'string') {
          deviceTagControl.setValue(deviceTagValue.replace(remove_trailing_space, ''));
        }
      }
      this.location.back();
    }
  }
}
