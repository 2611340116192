import { FlatTreeControl } from '@angular/cdk/tree';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatMenuTrigger } from '@angular/material/menu';
import { Sort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { ActivatedRoute, Router } from '@angular/router';
import { AlarmUIModel } from '@models/alarm';
import { DeviceUIModel } from '@models/device';
import { DialogOptions } from '@models/dialog-options';
import { DeviceMenuItem } from '@models/menu-item';
import { AttributesInfo, GroupNode, TableContents } from '@models/table-items';
import { ConversionService } from '@services/conversion.service';
import { DashboardStoreService } from '@services/dashboard-store.service';
import { UtilsService } from '@services/utils.service';
import { actionMenuItems } from '@utils/constants/action-menu-items';
import { customizeColumnDetails } from '@utils/constants/customize-columns';
import { FeatureUnavailable } from '@utils/constants/feature-unavailable';
import { PermissionsList } from '@utils/constants/user-permissions';
import { GroupNames } from '@utils/enums/device-enums';
import { ToastrType } from '@utils/enums/snackbar-enum';
import { take } from 'rxjs';

@Component({
  selector: 'app-devices-table-view',
  templateUrl: './devices-table-view.component.html',
  styleUrl: './devices-table-view.component.css',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DevicesTableViewComponent implements OnChanges, AfterViewInit, OnInit {
  private tableRef!: MatTable<any>;
  @ViewChild(MatMenuTrigger) trigger!: MatMenuTrigger;
  @Input() deviceList!: DeviceUIModel[];
  @Input() listOfTableColumns!: AttributesInfo[];
  @Input() selectedGroupKey!: string;
  @Input() groupList!: string[];
  @Output() selectedDevice = new EventEmitter<DeviceUIModel>();
  devicePermissions = PermissionsList.DevicePermissions;
  @ViewChild('table', { static: false })
  set table(matTable: MatTable<any>) {
    if (matTable) {
      this.tableRef = matTable;
      this.tableRef.updateStickyColumnStyles();
    }
  }
  lastSort: Sort = {
    active: 'deviceTag',
    direction: 'asc',
  };
  deviceTag!: string;
  displayedColumnFields = signal<string[]>([]);
  headerColumns: string[] = ['groupHeader'];
  treeControl = new FlatTreeControl<GroupNode>(
    node => node.level,
    node => node.expandable,
  );
  treeFlattener = new MatTreeFlattener(
    this.transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  hasChild = (_: number, node: GroupNode) => node.expandable;
  destroyRef = inject(DestroyRef);
  customizeColumns = customizeColumnDetails;
  actionMenuItems: DeviceMenuItem[] = actionMenuItems;
  modal: DialogOptions = { show: false };
  notesOverlayToggle: boolean = false;
  selectedNoteDevices!: DeviceUIModel;
  selectedNoteDeviceId!: string;
  selectedNoteDeviceTag!: string;
  featureUnavailableTooltip = FeatureUnavailable;

  constructor(
    @Inject(ActivatedRoute) private activatedRoute: ActivatedRoute,
    @Inject(Router) private router: Router,
    @Inject(NgZone) private ngZone: NgZone,
    private utilsService: UtilsService,
    private conversionService: ConversionService,
    private dashboardStoreService: DashboardStoreService,
  ) {}

  ngOnInit(): void {
    if (!this.deviceTag) {
      this.headerColumns = ['groupHeader', 'groupScrollable'];
    } else {
      this.activatedRoute.params.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(data => {
        if (data.deviceTag) {
          const device = this.deviceList.find(d => d.deviceTag === data.deviceTag);
          this.onDeviceChange(device!);
        }
      });
    }
    this.setTableColumns(this.listOfTableColumns);
  }

  toggleNotesOverlay(device?: DeviceUIModel) {
    this.notesOverlayToggle = !this.notesOverlayToggle;
    if (device) {
      this.selectedNoteDevices = device;
      this.selectedNoteDeviceId = this.selectedNoteDevices.id!;
      this.selectedNoteDeviceTag = this.selectedNoteDevices.deviceTag!;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.deviceTag = this.activatedRoute.snapshot.paramMap.get('deviceTag') || '';
    for (const changedObject in changes) {
      const prevValue = JSON.stringify(changes[changedObject].previousValue);
      const newValue = JSON.stringify(changes[changedObject].currentValue);
      if (prevValue !== newValue) {
        this.setTableData();
        break;
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.deviceTag) {
      this.scrollToDevice();
      this.router.events.pipe(take(1), takeUntilDestroyed(this.destroyRef)).subscribe((route: any) => {
        if (route.routerEvent) this.scrollToDevice();
      });
    }
    const dashboardGroup = this.dashboardStoreService.getGroup();
    if (dashboardGroup && dashboardGroup.state) {
      const node = this.treeControl.dataNodes.find(
        node => this.treeControl.isExpandable(node) && node.name === dashboardGroup.state,
      );
      !node
        ? this.utilsService.showSnackBar(`No ${dashboardGroup.state} devices found`, ToastrType.warning)
        : this.treeControl.expand(node);
      this.dashboardStoreService.clearGroupState();
    }
  }

  setTableColumns(columns: AttributesInfo[]) {
    this.displayedColumnFields.set(columns.map(column => column.field));
  }

  getPreviousExpandedGroups(): string[] {
    const expandedGroups: string[] = [];
    if (this.treeControl.dataNodes)
      this.treeControl.dataNodes.forEach(node => {
        if (this.treeControl.isExpandable(node) && this.treeControl.isExpanded(node))
          expandedGroups.push(node.name.toString());
      });
    return expandedGroups;
  }

  setExpandedGroups(expandedGroups: string[]): void {
    if (this.treeControl.dataNodes)
      this.treeControl.dataNodes
        .filter(node => this.treeControl.isExpanded(node) || expandedGroups.find(name => name === node.name))
        .forEach(nodeToExpand => {
          this.treeControl.expand(nodeToExpand);
        });
  }

  sortTitleList(titleList: TableContents[]) {
    titleList.sort((a, b) => {
      if (a.name.toString() === GroupNames.DevicesWithNoAlarms) return 1;
      if (b.name.toString() === GroupNames.DevicesWithNoAlarms) return -1;
      return a.name.toString().localeCompare(b.name.toString());
    });
  }

  setTableData(): void {
    const expandedGroups = this.getPreviousExpandedGroups();
    let titleList: TableContents[] = [];
    this.groupList.forEach(title => {
      titleList.push({ name: title, children: [] });
    });
    this.sortTitleList(titleList);
    this.deviceList.forEach(device => {
      const groups = titleList.filter(
        item =>
          item.name === GroupNames.AllDevices ||
          (item.name === GroupNames.Online && device[this.selectedGroupKey as keyof DeviceUIModel]) ||
          (item.name === GroupNames.Offline && !device[this.selectedGroupKey as keyof DeviceUIModel]) ||
          device.alarmDetails?.filter(alarm => alarm[this.selectedGroupKey as keyof AlarmUIModel] === item.name)
            .length ||
          (item.name === GroupNames.DevicesWithNoAlarms && device.alarmDetails?.length === 0) ||
          item.name === device[this.selectedGroupKey as keyof DeviceUIModel],
      );
      groups.forEach(group => {
        group.children!.push({ name: device.deviceTag!, deviceData: device });
        if (group?.name && this.deviceTag === device.deviceTag! && !expandedGroups.includes(group.name))
          expandedGroups.push(group.name);
      });
    });
    this.dataSource.data = titleList.filter(device => device.children?.length != 0);
    this.sortData(this.lastSort);
    this.setExpandedGroups(expandedGroups);
  }

  onDeviceChange(device: DeviceUIModel): void {
    this.deviceTag = device.deviceTag!;
    this.selectedDevice.emit(device);
  }

  scrollToDevice(): void {
    const deviceElement = document.getElementById(this.deviceTag) as HTMLElement;
    if (deviceElement && !this.isInViewport(deviceElement))
      deviceElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  isInViewport(element: HTMLElement): boolean {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 120 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) - element.clientHeight
    );
  }

  private transformer(node: TableContents, level: number): GroupNode {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      deviceData: node.deviceData,
      level: level,
      itemCount: node.children?.length || 0,
    };
  }

  updateTableState() {
    this.ngZone.onMicrotaskEmpty.pipe(take(3), takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.tableRef.updateStickyColumnStyles();
      this.tableRef.updateStickyHeaderRowStyles();
    });
  }

  sortData(sort: Sort) {
    const expandedGroups = this.getPreviousExpandedGroups();
    this.dataSource.data = this.dataSource.data.map(group => {
      group.children = group.children?.sort((deviceA, deviceB) => {
        type DeviceUIModelKey = keyof DeviceUIModel;
        type primitiveType = string | number | boolean | undefined | null;
        let valueA = deviceA.deviceData![sort.active as DeviceUIModelKey];
        let valueB = deviceB.deviceData![sort.active as DeviceUIModelKey];
        valueA = (typeof valueA === 'string' ? this.conversionService.removeUnit(valueA) : valueA) as primitiveType;
        valueB = (typeof valueB === 'string' ? this.conversionService.removeUnit(valueB) : valueB) as primitiveType;
        return this.utilsService.compare(valueA, valueB, sort.direction);
      });
      return group;
    });
    this.lastSort = {
      active: sort.active,
      direction: sort.direction,
    };
    this.setExpandedGroups(expandedGroups);
  }

  onActionMenuOptionClick(menuItemName: string, data: GroupNode) {
    switch (menuItemName) {
      case 'Remove Device':
        this.openDialog(data.name.toString().toUpperCase(), data.deviceData?.id);
        break;
      default:
        break;
    }
  }

  openDialog(name: string, id: string | null | undefined) {
    this.modal = {
      show: true,
      entityName: name,
      confirmationButtonText: 'Yes, Delete',
      id: id,
      message: `Are you sure you want to remove
      "${name}" from the system`,
    };
  }

  onDeviceRemove() {
    this.utilsService.showSnackBar(`"${this.modal.entityName}" removed from system`, ToastrType.success);
  }
}
