export const maintenanceInfoAttributes: { [key: string]: string } = {
  device_up_time: 'Number of Hours Since Last Reset',
  device_use_time: 'Number of Hours in Use',
  htc_0_contactor_cycle_count: 'Contactor Cycle Count',
  htc_0_energy_consumption_all_phases: 'Total Power Accumulator All Phases',
  htc_0_ground_fault_current_highest_instantaneous: 'Highest Instantaneous Ground Fault Current Ever Measured',
  htc_0_heater_time: 'Heater Time(Hour)',
  htc_0_load_current_highest_instantaneous: 'Highest Instantaneous Load Current Ever Measured',
  htc_0_maximum_control_temperature: 'Control Temperature Maximum(°C)',
  htc_0_minimum_control_temperature: 'Control Temperature Minimum(°C)',
  htc_0_test_tracing_time: 'Test Tracing Time Count',
  htc_0_voltage_maximum: 'Maximum voltage(V)',
  htc_0_voltage_minimum: 'Minimum voltage(V)',
  limiter_0_contactor_cycle_count: 'Limiter Contactor Cycle Count',
};
