import { AbstractControl, Validators } from '@angular/forms';
import { Configurable, Context } from '@app/models/configurator-models/form-fields';

export const cm2000 = {
  alarm_0_mode: {
    article: 'alarm_0_mode',
    defaultValue: 'True',
    group: 'Regs/Config/ControlOption/AlarmOutputMode',
    label: 'Alarm 0 mode',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([]);
      };
    },
  } as Configurable,
  alarm_0_normal_state: {
    article: 'alarm_0_normal_state',
    defaultValue: 'True',
    group: 'Regs/Config/ControlOption/AlarmOutputNormalState',
    label: 'Alarm 0 normal state',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([]);
      };
    },
  } as Configurable,
  console_0_temperature_units: {
    article: 'console_0_temperature_units',
    defaultValue: 'True',
    group: 'Regs/Config/ControlOption/ConsoleUnitsInDegreesF',
    label: 'Temperature units',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([]);
      };
    },
  } as Configurable,
  device_reset_mask: {
    article: 'device_reset_mask',
    defaultValue: 'False',
    group: 'Regs/Config/AlarmMask/ControllerReset',
    label: 'Device reset mask',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([]);
      };
    },
  } as Configurable as Configurable,
  device_reset_priority: {
    article: 'device_reset_priority',
    defaultValue: 'False',
    group: 'Regs/Config/AlarmPriority/ControllerReset',
    label: 'Device reset priority',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([]);
      };
    },
  } as Configurable,
  htc_0_atc_master: {
    article: 'htc_0_atc_master',
    defaultValue: 'False',
    group: 'Regs/Config/ControlOption/ATCMaster',
    label: 'Atc master',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([]);
      };
    },
  } as Configurable,
  htc_0_auto_cycle_enabled: {
    article: 'htc_0_auto_cycle_enabled',
    defaultValue: 'True',
    group: 'Regs/Config/ControlOption/AutoCycleEnabled',
    label: 'Auto cycle enabled',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        if (control.value) {
          control.parent?.get('htc_0_auto_cycle_interval')?.enable();
          control.parent?.get('htc_0_auto_cycle_unit')?.enable();
        } else {
          control.parent?.get('htc_0_auto_cycle_interval')?.disable();
          control.parent?.get('htc_0_auto_cycle_interval')?.reset();
          control.parent?.get('htc_0_auto_cycle_unit')?.disable();
          control.parent?.get('htc_0_auto_cycle_unit')?.reset();
        }
      };
    },
  } as Configurable,
  htc_0_auto_cycle_interval: {
    article: 'htc_0_auto_cycle_interval',
    defaultValue: 8,
    group: 'Regs/Config/ControlParameters/AutoCycleInterval',
    label: 'Auto cycle interval',
    type: 'int',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        [this.min, this.max] = [1, 240];
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_auto_cycle_unit: {
    article: 'htc_0_auto_cycle_unit',
    defaultValue: 'False',
    group: 'Regs/Config/ControlOption/AutoCycleUnit',
    label: 'Auto cycle unit',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([]);
      };
    },
  } as Configurable,
  htc_0_circuit_breaker_0_current_rating: {
    article: 'htc_0_circuit_breaker_0_current_rating',
    defaultValue: '50.0A',
    group: 'Regs/Config/ACParameters/CircuitBreakerCurrentRating',
    label: 'Circuit breaker 0 current rating',
    type: 'range',
    unit: '_A',
    validator() {
      return (control: AbstractControl): any | null => {
        this.min = 0.3;
        this.max = 60.0;
        this.min *= Number(cm2000.htc_0_current_turns_ratio.defaultValue);
        this.max *= Number(cm2000.htc_0_current_turns_ratio.defaultValue);
        if (this.min < 0.1) this.min = 0.1;
        if (this.max > 300) this.max = 300;
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_circuit_breaker_0_limiting_mask: {
    article: 'htc_0_circuit_breaker_0_limiting_mask',
    defaultValue: 'False',
    group: 'Regs/Config/AlarmMask/CircuitBreakerLimiting',
    label: 'Circuit breaker 0 limiting mask',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([]);
      };
    },
  } as Configurable,
  htc_0_circuit_breaker_0_limiting_priority: {
    article: 'htc_0_circuit_breaker_0_limiting_priority',
    defaultValue: 'False',
    group: 'Regs/Config/AlarmPriority/CircuitBreakerLimiting',
    label: 'Circuit breaker 0 limiting priority',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([]);
      };
    },
  } as Configurable,
  htc_0_communication_failure_mask: {
    article: 'htc_0_communication_failure_mask',
    defaultValue: 'True',
    group: 'Regs/Config/AlarmMask/CommunicationFailure',
    label: 'Communication failure mask',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([]);
      };
    },
  } as Configurable,
  htc_0_communication_failure_priority: {
    article: 'htc_0_communication_failure_priority',
    defaultValue: 'False',
    group: 'Regs/Config/AlarmPriority/CommunicationFailure',
    label: 'Communication failure priority',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([]);
      };
    },
  } as Configurable,
  htc_0_control_temperature_failure_mask: {
    article: 'htc_0_control_temperature_failure_mask',
    defaultValue: 'False',
    group: 'Regs/Config/AlarmMask/ControlTemperatureFailure',
    label: 'Control temperature failure mask',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([]);
      };
    },
  } as Configurable,
  htc_0_control_temperature_failure_priority: {
    article: 'htc_0_control_temperature_failure_priority',
    defaultValue: 'False',
    group: 'Regs/Config/AlarmPriority/ControlTemperatureFailure',
    label: 'Control temperature failure priority',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([]);
      };
    },
  } as Configurable,
  htc_0_control_temperature_setpoint: {
    article: 'htc_0_control_temperature_setpoint',
    defaultValue: '20°C',
    group: 'Regs/Config/ControlParameters/ControlTemperatureSetpoint',
    label: 'Control temperature setpoint',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        this.min = -60.0;
        this.max = 570.0;
        if (ctx?.temperaturePreferenceUnit !== 'Celsius') {
          this.min = (this.min * 9) / 5 + 32;
          this.max = (this.max * 9) / 5 + 32;
        }
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_current_turns_ratio: {
    article: 'htc_0_current_turns_ratio',
    defaultValue: 1.0,
    group: 'Regs/Config/ACParameters/CurrentTurnsRatio',
    label: 'Current turns ratio',
    type: 'range',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        [this.min, this.max] = [0.1, 60.0];
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_deadband: {
    article: 'htc_0_deadband',
    defaultValue: '3Δ°C',
    group: 'Regs/Config/ControlParameters/Deadband',
    label: 'Deadband',
    type: 'range',
    unit: 'Δ°C',
    validator(ctx?: Context) {
      this.min = 1;
      this.max = 50;
      if (ctx!.fwVersion >= 3.19) this.max = 350;
      if (ctx?.deviceType != 'cm2000p') {
        this.min = 3;
        this.max = 50;
      }
      if (ctx?.temperaturePreferenceUnit !== 'Celsius') {
        this.min = (this.min * 9) / 5;
        this.max = (this.max * 9) / 5;
      }
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_ground_fault_current_high_alarm_setpoint: {
    article: 'htc_0_ground_fault_current_high_alarm_setpoint',
    defaultValue: '50mA',
    group: 'Regs/Config/ACParameters/HighGFIAlarmSetpoint',
    label: 'Ground fault current high alarm setpoint',
    type: 'range',
    unit: 'mA',
    validator() {
      return (control: AbstractControl): any | null => {
        [this.min, this.max] = [20, 100];
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_ground_fault_current_high_mask: {
    article: 'htc_0_ground_fault_current_high_mask',
    defaultValue: 'True',
    group: 'Regs/Config/AlarmMask/HighGroundFaultCurrent',
    label: 'Ground fault current high mask',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        if (control.value) {
          control.parent?.get('htc_0_ground_fault_current_high_alarm_setpoint')?.enable();
          control.parent?.get('htc_0_ground_fault_current_high_priority')?.enable();
        } else {
          control.parent?.get('htc_0_ground_fault_current_high_alarm_setpoint')?.disable();
          control.parent?.get('htc_0_ground_fault_current_high_alarm_setpoint')?.reset();
          control.parent?.get('htc_0_ground_fault_current_high_priority')?.disable();
          control.parent?.get('htc_0_ground_fault_current_high_priority')?.reset();
        }
      };
    },
  } as Configurable,
  htc_0_ground_fault_current_high_priority: {
    article: 'htc_0_ground_fault_current_high_priority',
    defaultValue: 'False',
    group: 'Regs/Config/AlarmPriority/HighGroundFaultCurrent',
    label: 'Ground fault current high priority',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([]);
      };
    },
  } as Configurable,
  htc_0_ground_fault_current_trip_setpoint: {
    article: 'htc_0_ground_fault_current_trip_setpoint',
    defaultValue: '75mA',
    group: 'Regs/Config/ACParameters/GFITripAlarmSetpoint',
    label: 'Ground fault current trip setpoint',
    type: 'range',
    unit: 'mA',
    validator() {
      return (control: AbstractControl): any | null => {
        [this.min, this.max] = [20, 100];
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_ground_fault_trip_mask: {
    article: 'htc_0_ground_fault_trip_mask',
    defaultValue: 'True',
    group: 'Regs/Config/AlarmMask/GroundFaultTrip',
    label: 'Ground fault trip mask',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        if (control.value) {
          control.parent?.get('htc_0_ground_fault_current_trip_setpoint')?.enable();
          control.parent?.get('htc_0_ground_fault_trip_priority')?.enable();
        } else {
          control.parent?.get('htc_0_ground_fault_current_trip_setpoint')?.disable();
          control.parent?.get('htc_0_ground_fault_current_trip_setpoint')?.reset();
          control.parent?.get('htc_0_ground_fault_trip_priority')?.disable();
          control.parent?.get('htc_0_ground_fault_trip_priority')?.reset();
        }
      };
    },
  } as Configurable,
  htc_0_ground_fault_trip_priority: {
    article: 'htc_0_ground_fault_trip_priority',
    defaultValue: 'False',
    group: 'Regs/Config/AlarmPriority/GroundFaultTrip',
    label: 'Ground fault trip priority',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([]);
      };
    },
  } as Configurable,
  htc_0_line_current_0_over_mask: {
    article: 'htc_0_line_current_0_over_mask',
    defaultValue: 'True',
    group: 'Regs/Config/AlarmMask/OvercurrentTrip',
    label: 'Line current 0 over mask',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([]);
      };
    },
  } as Configurable,
  htc_0_line_current_0_over_priority: {
    article: 'htc_0_line_current_0_over_priority',
    defaultValue: 'False',
    group: 'Regs/Config/AlarmPriority/OvercurrentTrip',
    label: 'Line current 0 over priority',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([]);
      };
    },
  } as Configurable,
  htc_0_line_voltage_high_mask: {
    article: 'htc_0_line_voltage_high_mask',
    defaultValue: 'True',
    group: 'Regs/Config/AlarmMask/HighVoltage',
    label: 'Line voltage high mask',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([]);
      };
    },
  } as Configurable,
  htc_0_line_voltage_high_priority: {
    article: 'htc_0_line_voltage_high_priority',
    defaultValue: 'False',
    group: 'Regs/Config/AlarmPriority/HighVoltage',
    label: 'Line voltage high priority',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([]);
      };
    },
  } as Configurable,
  htc_0_line_voltage_low_mask: {
    article: 'htc_0_line_voltage_low_mask',
    defaultValue: 'True',
    group: 'Regs/Config/AlarmMask/LowVoltage',
    label: 'Line voltage low mask',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([]);
      };
    },
  } as Configurable,
  htc_0_line_voltage_low_priority: {
    article: 'htc_0_line_voltage_low_priority',
    defaultValue: 'False',
    group: 'Regs/Config/AlarmPriority/LowVoltage',
    label: 'Line voltage low priority',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([]);
      };
    },
  } as Configurable,
  htc_0_load_current_high_alarm_setpoint: {
    article: 'htc_0_load_current_high_alarm_setpoint',
    defaultValue: '30.0A',
    group: 'Regs/Config/ACParameters/HighLoadCurrentAlarmSetpoint',
    label: 'Load current high alarm setpoint',
    type: 'range',
    unit: '_A',
    validator() {
      return (control: AbstractControl): any | null => {
        this.min = 0.3;
        this.max = 60.0;
        this.min *= Number(cm2000.htc_0_current_turns_ratio.defaultValue);
        this.max *= Number(cm2000.htc_0_current_turns_ratio.defaultValue);
        if (this.min < 0.3) this.min = 0.3;
        if (this.max > 300.0) this.max = 300;
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_load_current_high_mask: {
    article: 'htc_0_load_current_high_mask',
    defaultValue: 'False',
    group: 'Regs/Config/AlarmMask/HighLoadCurrent',
    label: 'Load current high mask',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([]);
      };
    },
  } as Configurable,
  htc_0_load_current_high_priority: {
    article: 'htc_0_load_current_high_priority',
    defaultValue: 'False',
    group: 'Regs/Config/AlarmPriority/HighLoadCurrent',
    label: 'Load current high priority',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([]);
      };
    },
  } as Configurable,
  htc_0_load_current_low_alarm_setpoint: {
    article: 'htc_0_load_current_low_alarm_setpoint',
    defaultValue: '0.3A',
    group: 'Regs/Config/ACParameters/LowLoadCurrentAlarmSetpoint',
    label: 'Load current low alarm setpoint',
    type: 'range',
    unit: '_A',
    validator() {
      return (control: AbstractControl): any | null => {
        this.min = 0.3;
        this.max = 60.0;
        this.min *= Number(cm2000.htc_0_current_turns_ratio.defaultValue);
        this.max *= Number(cm2000.htc_0_current_turns_ratio.defaultValue);
        if (this.min < 0.3) this.min = 0.3;
        if (this.max > 300.0) this.max = 300;
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_load_current_low_mask: {
    article: 'htc_0_load_current_low_mask',
    defaultValue: 'True',
    group: 'Regs/Config/AlarmMask/LowLoadCurrent',
    label: 'Load current low mask',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([]);
      };
    },
  } as Configurable,
  htc_0_load_current_low_priority: {
    article: 'htc_0_load_current_low_priority',
    defaultValue: 'False',
    group: 'Regs/Config/AlarmPriority/LowLoadCurrent',
    label: 'Load current low priority',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([]);
      };
    },
  } as Configurable,
  htc_0_load_shed_enabled: {
    article: 'htc_0_load_shed_enabled',
    defaultValue: 'False',
    group: 'Regs/Config/LoadShedding/LoadSheddingEnabled',
    label: 'Load shed enabled',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        if (control.value) {
          control.parent?.get('htc_0_load_shed_fail_safe_enabled')?.enable();
          control.parent?.get('htc_0_load_shed_zone_0_enabled')?.enable();
          control.parent?.get('htc_0_load_shed_zone_1_enabled')?.enable();
          control.parent?.get('htc_0_load_shed_zone_2_enabled')?.enable();
          control.parent?.get('htc_0_load_shed_zone_3_enabled')?.enable();
        } else {
          control.parent?.get('htc_0_load_shed_fail_safe_enabled')?.disable();
          control.parent?.get('htc_0_load_shed_fail_safe_enabled')?.reset();
          control.parent?.get('htc_0_load_shed_zone_0_enabled')?.disable();
          control.parent?.get('htc_0_load_shed_zone_0_enabled')?.reset();
          control.parent?.get('htc_0_load_shed_zone_1_enabled')?.disable();
          control.parent?.get('htc_0_load_shed_zone_1_enabled')?.reset();
          control.parent?.get('htc_0_load_shed_zone_2_enabled')?.disable();
          control.parent?.get('htc_0_load_shed_zone_2_enabled')?.reset();
          control.parent?.get('htc_0_load_shed_zone_3_enabled')?.disable();
          control.parent?.get('htc_0_load_shed_zone_3_enabled')?.reset();
        }
      };
    },
  } as Configurable,
  htc_0_load_shed_fail_safe_enabled: {
    article: 'htc_0_load_shed_fail_safe_enabled',
    defaultValue: 'True',
    group: 'Regs/Config/LoadShedding/LoadSheddingFailSafeEnabled',
    label: 'Load shed fail safe enabled',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([]);
      };
    },
  } as Configurable,
  htc_0_load_shed_zone_0_enabled: {
    article: 'htc_0_load_shed_zone_0_enabled',
    defaultValue: 'True',
    group: 'Regs/Config/LoadShedding/GroupLoadSheddingEnabled/elem',
    label: 'Load shed zone 0 enabled',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([]);
      };
    },
  } as Configurable,
  htc_0_load_shed_zone_1_enabled: {
    article: 'htc_0_load_shed_zone_1_enabled',
    defaultValue: 'True',
    group: 'Regs/Config/LoadShedding/GroupLoadSheddingEnabled/elem',
    label: 'Load shed zone 1 enabled',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([]);
      };
    },
  } as Configurable,
  htc_0_load_shed_zone_2_enabled: {
    article: 'htc_0_load_shed_zone_2_enabled',
    defaultValue: 'True',
    group: 'Regs/Config/LoadShedding/GroupLoadSheddingEnabled/elem',
    label: 'Load shed zone 2 enabled',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([]);
      };
    },
  } as Configurable,
  htc_0_load_shed_zone_3_enabled: {
    article: 'htc_0_load_shed_zone_3_enabled',
    defaultValue: 'True',
    group: 'Regs/Config/LoadShedding/GroupLoadSheddingEnabled/elem',
    label: 'Load shed zone 3 enabled',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([]);
      };
    },
  } as Configurable,
  htc_0_local_alarm_priority_enabled: {
    article: 'htc_0_local_alarm_priority_enabled',
    defaultValue: 'True',
    group: 'Regs/Config/ControlOption/LocalAlarmPriorityEnabled',
    label: 'Local alarm priority enabled',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([]);
      };
    },
  } as Configurable,
  htc_0_maximum_power_setpoint: {
    article: 'htc_0_maximum_power_setpoint',
    defaultValue: '12000W',
    group: 'Regs/Config/ACParameters/MaximumPowerSetpoint',
    label: 'Maximum power setpoint',
    type: 'range',
    unit: '_W',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        this.min = 27.0;
        this.max = 16800.0;
        const currentTurnsRatio = Number(cm2000.htc_0_current_turns_ratio.defaultValue);
        const voltageTurnsRatio = Number(cm2000.htc_0_voltage_turns_ratio.defaultValue);
        this.min *= currentTurnsRatio * voltageTurnsRatio;
        this.max *= currentTurnsRatio * voltageTurnsRatio;
        if (this.min < 3.0) this.min = 3.0;
        if (ctx?.deviceType == 'cm2000p') {
          if (this.max > 60000.0) this.max = 60000.0;
        } else {
          if (this.max > 30000.0) this.max = 30000.0;
        }
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_non_latching_temperature_alarms: {
    article: 'htc_0_non_latching_temperature_alarms',
    defaultValue: 'False',
    group: 'Regs/Config/ControlOption/TemperatureAlarmsLatchMode',
    label: 'Non latching temperature alarms',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([]);
      };
    },
  } as Configurable,
  htc_0_power_limiting_mask: {
    article: 'htc_0_power_limiting_mask',
    defaultValue: 'False',
    group: 'Regs/Config/AlarmMask/PowerLimiting',
    label: 'Power limiting mask',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([]);
      };
    },
  } as Configurable,
  htc_0_power_limiting_priority: {
    article: 'htc_0_power_limiting_priority',
    defaultValue: 'False',
    group: 'Regs/Config/AlarmPriority/PowerLimiting',
    label: 'Power limiting priority',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([]);
      };
    },
  } as Configurable,
  htc_0_ssr_rating: {
    article: 'htc_0_ssr_rating',
    defaultValue: '0.3A',
    group: 'Regs/Config/ACParameters/SwitchControllerCurrentRating',
    label: 'Ssr rating',
    type: 'range',
    unit: '_A',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        this.min = 0.3;
        this.max = 30.0;
        if (ctx?.deviceType === 'cm2000p') this.max = 60.0;
        if (ctx?.deviceType === 'cm2000') this.max = 50.0;
        this.min *= Number(cm2000.htc_0_current_turns_ratio.defaultValue);
        this.max *= Number(cm2000.htc_0_current_turns_ratio.defaultValue);
        if (this.min < 0.1) this.min = 0.1;
        if (this.max > 300.0) this.max = 300;
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_switch_0_failure_mask: {
    article: 'htc_0_switch_0_failure_mask',
    defaultValue: 'True',
    group: 'Regs/Config/AlarmMask/SwitchFailure',
    label: 'Switch 0 failure mask',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([]);
      };
    },
  } as Configurable,
  htc_0_switch_0_failure_priority: {
    article: 'htc_0_switch_0_failure_priority',
    defaultValue: 'False',
    group: 'Regs/Config/AlarmPriority/SwitchFailure',
    label: 'Switch 0 failure priority',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([]);
      };
    },
  } as Configurable,
  htc_0_switch_0_limiting_mask: {
    article: 'htc_0_switch_0_limiting_mask',
    defaultValue: 'False',
    group: 'Regs/Config/AlarmMask/SwitchLimiting',
    label: 'Switch 0 limiting mask',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([]);
      };
    },
  } as Configurable,
  htc_0_switch_0_limiting_priority: {
    article: 'htc_0_switch_0_limiting_priority',
    defaultValue: 'False',
    group: 'Regs/Config/AlarmPriority/SwitchLimiting',
    label: 'Switch 0 limiting priority',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([]);
      };
    },
  } as Configurable,
  htc_0_switch_control_mode: {
    article: 'htc_0_switch_control_mode',
    defaultValue: 'True',
    group: 'Regs/Config/ControlOption/SwitchControlMode',
    label: 'Switch control mode',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        switch (control.value) {
          case 0: // PROPORTIONAL
            //ENABLE
            control.parent?.get('htc_0_proportional_band')?.enable();
            control.parent?.get('htc_0_circuit_breaker_0_current_rating')?.enable();
            control.parent?.get('htc_0_maximum_power_setpoint')?.enable();
            control.parent?.get('htc_0_ssr_rating')?.enable();
            control.parent?.get('htc_0_power_limiting_mask')?.enable();
            control.parent?.get('htc_0_circuit_breaker_0_limiting_mask')?.enable();
            //DISABLE
            control.parent?.get('htc_0_deadband')?.disable();
            control.parent?.get('htc_0_deadband')?.reset();
            control.parent?.get('htc_0_proportional_ambient_cycle_time')?.disable();
            control.parent?.get('htc_0_proportional_ambient_cycle_time')?.reset();
            control.parent?.get('htc_0_current_turns_ratio')?.disable();
            control.parent?.get('htc_0_current_turns_ratio')?.reset();
            control.parent?.get('htc_0_contactor_cycle_count_setpoint')?.disable();
            control.parent?.get('htc_0_contactor_cycle_count_setpoint')?.reset();
            control.parent?.get('htc_0_contactor_cycle_count_limit_exceeded_mask')?.disable();
            control.parent?.get('htc_0_contactor_cycle_count_limit_exceeded_mask')?.reset();
            control.parent?.get('htc_0_contactor_cycle_count_limit_exceeded_priority')?.disable();
            control.parent?.get('htc_0_contactor_cycle_count_limit_exceeded_priority')?.reset();
            break;
          case 1: // PROPORTIONAL_AMBIENT_SSR
            //ENABLE
            control.parent?.get('htc_0_proportional_band')?.enable();
            control.parent?.get('htc_0_proportional_ambient_cycle_time')?.enable();
            control.parent?.get('htc_0_maximum_power_setpoint')?.enable();
            control.parent?.get('htc_0_ssr_rating')?.enable();
            control.parent?.get('htc_0_power_limiting_mask')?.enable();
            control.parent?.get('htc_0_circuit_breaker_0_limiting_mask')?.enable();
            //DISABLE
            control.parent?.get('htc_0_deadband')?.disable();
            control.parent?.get('htc_0_deadband')?.reset();
            control.parent?.get('htc_0_proportional_ambient_cycle_time')?.disable();
            control.parent?.get('htc_0_proportional_ambient_cycle_time')?.reset();
            control.parent?.get('htc_0_current_turns_ratio')?.disable();
            control.parent?.get('htc_0_current_turns_ratio')?.reset();
            control.parent?.get('htc_0_contactor_cycle_count_setpoint')?.disable();
            control.parent?.get('htc_0_contactor_cycle_count_setpoint')?.reset();
            control.parent?.get('htc_0_contactor_cycle_count_limit_exceeded_mask')?.disable();
            control.parent?.get('htc_0_contactor_cycle_count_limit_exceeded_mask')?.reset();
            control.parent?.get('htc_0_contactor_cycle_count_limit_exceeded_priority')?.disable();
            control.parent?.get('htc_0_contactor_cycle_count_limit_exceeded_priority')?.reset();
            break;
          case 2: // DEADBAND
            //ENABLE
            control.parent?.get('htc_0_deadband')?.enable();
            control.parent?.get('htc_0_current_turns_ratio')?.enable();
            control.parent?.get('htc_0_contactor_cycle_count_setpoint')?.enable();
            control.parent?.get('htc_0_contactor_cycle_count_limit_exceeded_mask')?.enable();
            control.parent?.get('htc_0_contactor_cycle_count_limit_exceeded_priority')?.enable();
            //DISABLE
            control.parent?.get('htc_0_proportional_band')?.disable();
            control.parent?.get('htc_0_proportional_band')?.reset();
            control.parent?.get('htc_0_proportional_ambient_cycle_time')?.disable();
            control.parent?.get('htc_0_proportional_ambient_cycle_time')?.reset();
            control.parent?.get('htc_0_circuit_breaker_0_current_rating')?.disable();
            control.parent?.get('htc_0_circuit_breaker_0_current_rating')?.reset();
            control.parent?.get('htc_0_maximum_power_setpoint')?.disable();
            control.parent?.get('htc_0_maximum_power_setpoint')?.reset();
            control.parent?.get('htc_0_ssr_rating')?.disable();
            control.parent?.get('htc_0_ssr_rating')?.reset();
            control.parent?.get('htc_0_power_limiting_mask')?.disable();
            control.parent?.get('htc_0_power_limiting_mask')?.reset();
            control.parent?.get('htc_0_circuit_breaker_0_limiting_mask')?.disable();
            control.parent?.get('htc_0_circuit_breaker_0_limiting_mask')?.reset();
            break;
          case 3: // PROPORTIONAL_AMBIENT_CONTACTOR
            //ENABLE
            control.parent?.get('htc_0_proportional_band')?.enable();
            control.parent?.get('htc_0_proportional_ambient_cycle_time')?.enable();
            control.parent?.get('htc_0_current_turns_ratio')?.enable();
            control.parent?.get('htc_0_contactor_cycle_count_setpoint')?.enable();
            control.parent?.get('htc_0_contactor_cycle_count_limit_exceeded_mask')?.enable();
            control.parent?.get('htc_0_contactor_cycle_count_limit_exceeded_priority')?.enable();
            //DISABLE
            control.parent?.get('htc_0_deadband')?.disable();
            control.parent?.get('htc_0_deadband')?.reset();
            control.parent?.get('htc_0_circuit_breaker_0_current_rating')?.disable();
            control.parent?.get('htc_0_circuit_breaker_0_current_rating')?.reset();
            control.parent?.get('htc_0_maximum_power_setpoint')?.disable();
            control.parent?.get('htc_0_maximum_power_setpoint')?.reset();
            control.parent?.get('htc_0_ssr_rating')?.disable();
            control.parent?.get('htc_0_ssr_rating')?.reset();
            control.parent?.get('htc_0_power_limiting_mask')?.disable();
            control.parent?.get('htc_0_power_limiting_mask')?.reset();
            control.parent?.get('htc_0_circuit_breaker_0_limiting_mask')?.disable();
            control.parent?.get('htc_0_circuit_breaker_0_limiting_mask')?.reset();
            break;
          default:
            break;
        }
      };
    },
  } as Configurable,
  htc_0_temperature_control_mode: {
    article: 'htc_0_temperature_control_mode',
    defaultValue: 0,
    group: 'Regs/Config/ControlParameters/TemperatureSensorControlMode',
    label: 'Temperature control mode',
    options: [
      { key: 0, value: 'TS1_FAIL_OFF_ON', displayText: 'TS1 FAIL OFF ON' },
      { key: 1, value: 'TS1_FAIL_TS2', displayText: 'TS1 FAIL TS2' },
      { key: 2, value: 'TS2_FAIL_OFF_ON', displayText: 'TS2 FAIL OFF ON' },
      { key: 3, value: 'TS2_FAIL_TS1', displayText: 'TS2 FAIL TS1' },
      { key: 4, value: 'AVERAGE_FAIL_OFF_ON', displayText: 'AVERAGE FAIL OFF ON' },
      { key: 5, value: 'AVERAGE_FAIL_GOOD', displayText: 'AVERAGE FAIL GOOD' },
      { key: 6, value: 'LOWEST_FAIL_OFF_ON', displayText: 'LOWEST FAIL OFF ON' },
      { key: 7, value: 'LOWEST_FAIL_GOOD', displayText: 'LOWEST FAIL GOOD' },
      { key: 8, value: 'ATC_SLAVE', displayText: 'ATC SLAVE' },
    ],
    type: 'choice',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([]);
      };
    },
  } as Configurable,
  htc_0_voltage_high_alarm_setpoint: {
    article: 'htc_0_voltage_high_alarm_setpoint',
    defaultValue: '270V',
    group: 'Regs/Config/ACParameters/HighACVoltageAlarmSetpoint',
    label: 'Voltage high alarm setpoint',
    type: 'range',
    unit: '_V',
    validator() {
      return (control: AbstractControl): any | null => {
        this.min = 90;
        this.max = 280;
        this.min *= Number(cm2000.htc_0_voltage_turns_ratio.defaultValue);
        this.max *= Number(cm2000.htc_0_voltage_turns_ratio.defaultValue);
        if (this.min < 1) this.min = 1;
        if (this.max > 1000) this.max = 1000;
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_voltage_low_alarm_setpoint: {
    article: 'htc_0_voltage_low_alarm_setpoint',
    defaultValue: '90V',
    group: 'Regs/Config/ACParameters/LowACVoltageAlarmSetpoint',
    label: 'Voltage low alarm setpoint',
    type: 'range',
    unit: '_V',
    validator() {
      return (control: AbstractControl): any | null => {
        this.min = 90;
        this.max = 280;
        this.min *= Number(cm2000.htc_0_voltage_turns_ratio.defaultValue);
        this.max *= Number(cm2000.htc_0_voltage_turns_ratio.defaultValue);
        if (this.min < 1) this.min = 1;
        if (this.max > 1000) this.max = 1000;
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_voltage_turns_ratio: {
    article: 'htc_0_voltage_turns_ratio',
    defaultValue: 1.0,
    group: 'Regs/Config/ACParameters/VoltageTurnsRatio',
    label: 'Voltage turns ratio',
    type: 'range',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  nvram_0_total_failure_mask: {
    article: 'nvram_0_total_failure_mask',
    defaultValue: 'False',
    group: 'Regs/Config/AlarmMask/EEROMFailure',
    label: 'Nvram 0 total failure mask',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([]);
      };
    },
  } as Configurable,
  nvram_0_total_failure_priority: {
    article: 'nvram_0_total_failure_priority',
    defaultValue: 'False',
    group: 'Regs/Config/AlarmPriority/EEROMFailure',
    label: 'Nvram 0 total failure priority',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([]);
      };
    },
  } as Configurable,
  temperature_0_failure_mask: {
    article: 'temperature_0_failure_mask',
    defaultValue: 'True',
    group: 'Regs/Config/AlarmMask/TemperatureSensorFailure/elem',
    label: 'Temperature failure mask',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([]);
      };
    },
  } as Configurable,
  temperature_0_failure_priority: {
    article: 'temperature_0_failure_priority',
    defaultValue: 'True',
    group: 'Regs/Config/AlarmPriority/TemperatureSensorFailure/elem',
    label: 'Temperature failure priority',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([]);
      };
    },
  } as Configurable,
  temperature_0_high_alarm_setpoint: {
    article: 'temperature_0_high_alarm_setpoint',
    defaultValue: '100°C',
    group: 'Regs/Config/TemperatureSetup/TemperatureSensorHighAlarmSetpoint/elem',
    label: 'Temperature high alarm setpoint',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      this.min = -60.0;
      this.max = 570.0;
      if (ctx?.temperaturePreferenceUnit !== 'Celsius') {
        this.min = (this.min * 9) / 5 + 32;
        this.max = (this.max * 9) / 5 + 32;
      }
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  temperature_0_high_mask: {
    article: 'temperature_0_high_mask',
    defaultValue: 'True',
    group: 'Regs/Config/AlarmMask/TemperatureSensorHigh/elem',
    label: 'Temperature high mask',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        if (control.value) {
          control.parent?.get('temperature_0_high_alarm_setpoint')?.enable();
          control.parent?.get('temperature_0_high_alarm_filter')?.enable();
          control.parent?.get('temperature_0_high_priority')?.enable();
        } else {
          control.parent?.get('temperature_0_high_alarm_setpoint')?.disable();
          control.parent?.get('temperature_0_high_alarm_setpoint')?.reset();
          control.parent?.get('temperature_0_high_alarm_filter')?.disable();
          control.parent?.get('temperature_0_high_alarm_filter')?.reset();
          control.parent?.get('temperature_0_high_priority')?.disable();
          control.parent?.get('temperature_0_high_priority')?.reset();
        }
      };
    },
  } as Configurable,
  temperature_0_high_priority: {
    article: 'temperature_0_high_priority',
    defaultValue: 'False',
    group: 'Regs/Config/AlarmPriority/TemperatureSensorHigh/elem',
    label: 'Temperature high priority',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([]);
      };
    },
  } as Configurable,
  temperature_0_low_alarm_setpoint: {
    article: 'temperature_0_low_alarm_setpoint',
    defaultValue: '50°C',
    group: 'Regs/Config/TemperatureSetup/TemperatureSensorLowAlarmSetpoint/elem',
    label: 'Temperature low alarm setpoint',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      this.min = -60.0;
      this.max = 570.0;
      if (ctx?.temperaturePreferenceUnit !== 'Celsius') {
        this.min = (this.min * 9) / 5 + 32;
        this.max = (this.max * 9) / 5 + 32;
      }
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  temperature_0_low_mask: {
    article: 'temperature_0_low_mask',
    defaultValue: 'True',
    group: 'Regs/Config/AlarmMask/TemperatureSensorLow/elem',
    label: 'Temperature low mask',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        if (control.value) {
          control.parent?.get('temperature_0_low_alarm_setpoint')?.enable();
          control.parent?.get('temperature_0_low_alarm_filter')?.enable();
          control.parent?.get('temperature_0_low_priority')?.enable();
        } else {
          control.parent?.get('temperature_0_low_alarm_setpoint')?.disable();
          control.parent?.get('temperature_0_low_alarm_setpoint')?.reset();
          control.parent?.get('temperature_0_low_alarm_filter')?.disable();
          control.parent?.get('temperature_0_low_alarm_filter')?.reset();
          control.parent?.get('temperature_0_low_priority')?.disable();
          control.parent?.get('temperature_0_low_priority')?.reset();
        }
      };
    },
  } as Configurable,
  temperature_0_low_priority: {
    article: 'temperature_0_low_priority',
    defaultValue: 'True',
    group: 'Regs/Config/AlarmPriority/TemperatureSensorLow/elem',
    label: 'Temperature low priority',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([]);
      };
    },
  } as Configurable,
  temperature_1_failure_mask: {
    article: 'temperature_1_failure_mask',
    defaultValue: 'True',
    group: 'Regs/Config/AlarmMask/TemperatureSensorFailure/elem',
    label: 'Temperature 1 failure mask',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([]);
      };
    },
  } as Configurable,
  temperature_1_failure_priority: {
    article: 'temperature_1_failure_priority',
    defaultValue: 'True',
    group: 'Regs/Config/AlarmPriority/TemperatureSensorFailure/elem',
    label: 'Temperature 1 failure priority',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([]);
      };
    },
  } as Configurable,
  temperature_1_high_alarm_setpoint: {
    article: 'temperature_1_high_alarm_setpoint',
    defaultValue: '100°C',
    group: 'Regs/Config/TemperatureSetup/TemperatureSensorHighAlarmSetpoint/elem',
    label: 'Temperature 1 high alarm setpoint',
    type: 'range',
    unit: '°C',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  temperature_1_high_mask: {
    article: 'temperature_1_high_mask',
    defaultValue: 'True',
    group: 'Regs/Config/AlarmMask/TemperatureSensorHigh/elem',
    label: 'Temperature 1 high mask',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        if (control.value) {
          control.parent?.get('temperature_1_high_alarm_setpoint')?.enable();
          control.parent?.get('temperature_1_high_alarm_filter')?.enable();
          control.parent?.get('temperature_1_high_priority')?.enable();
        } else {
          control.parent?.get('temperature_1_high_alarm_setpoint')?.disable();
          control.parent?.get('temperature_1_high_alarm_setpoint')?.reset();
          control.parent?.get('temperature_1_high_alarm_filter')?.disable();
          control.parent?.get('temperature_1_high_alarm_filter')?.reset();
          control.parent?.get('temperature_1_high_priority')?.disable();
          control.parent?.get('temperature_1_high_priority')?.reset();
        }
      };
    },
  } as Configurable,
  temperature_1_high_priority: {
    article: 'temperature_1_high_priority',
    defaultValue: 'False',
    group: 'Regs/Config/AlarmPriority/TemperatureSensorHigh/elem',
    label: 'Temperature 1 high priority',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([]);
      };
    },
  } as Configurable,
  temperature_1_low_alarm_setpoint: {
    article: 'temperature_1_low_alarm_setpoint',
    defaultValue: '50°C',
    group: 'Regs/Config/TemperatureSetup/TemperatureSensorLowAlarmSetpoint/elem',
    label: 'Temperature 1 low alarm setpoint',
    type: 'range',
    unit: '°C',
    validator() {
      return (control: AbstractControl) => {
        [this.min, this.max] = [-60, 570];
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  temperature_1_low_mask: {
    article: 'temperature_1_low_mask',
    defaultValue: 'True',
    group: 'Regs/Config/AlarmMask/TemperatureSensorLow/elem',
    label: 'Temperature 1 low mask',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        if (control.value) {
          control.parent?.get('temperature_1_low_alarm_setpoint')?.enable();
          control.parent?.get('temperature_1_low_alarm_filter')?.enable();
          control.parent?.get('temperature_1_low_priority')?.enable();
        } else {
          control.parent?.get('temperature_1_low_alarm_setpoint')?.disable();
          control.parent?.get('temperature_1_low_alarm_setpoint')?.reset();
          control.parent?.get('temperature_1_low_alarm_filter')?.disable();
          control.parent?.get('temperature_1_low_alarm_filter')?.reset();
          control.parent?.get('temperature_1_low_priority')?.disable();
          control.parent?.get('temperature_1_low_priority')?.reset();
        }
      };
    },
  } as Configurable,
  temperature_1_low_priority: {
    article: 'temperature_1_low_priority',
    defaultValue: 'True',
    group: 'Regs/Config/AlarmPriority/TemperatureSensorLow/elem',
    label: 'Temperature 1 low priority',
    type: 'bit',
    unit: '',
    validator() {
      return (control: AbstractControl): any | null => {
        control.setValidators([]);
      };
    },
  },
};
