@if (device) {
  <app-header [title]="pageTitle" />
  <div>
    <p class="changes-header-text">Here are the changes to be made</p>
  </div>
  <div class="confirm-container flex-between">
    <div class="left-container">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" #table>
        @for (column of listOfTableColumns; track $index) {
          <ng-container [matColumnDef]="column.field">
            <th mat-header-cell *matHeaderCellDef column-resizer>
              <div class="config-confirmation-table-header row-flex">
                <div class="col-flex">
                  <span>{{ column.header }}</span>
                </div>
              </div>
            </th>
            <td mat-cell *matCellDef="let data">
              @if (column.field == 'name') {
                <div class="header">
                  <span>{{ data.name }}</span>
                </div>
              } @else {
                <div>
                  <span>{{ data.values[column.field] }}</span>
                </div>
              }
            </td>
          </ng-container>
        }
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <ng-container matColumnDef="groupHeader" sticky="true">
          <td mat-cell *matCellDef="let data" colspan="4" class="accordion-header">
            <div class="flex-col-center accordion-title" (click)="treeControl.toggle(data)">
              <span>{{ data.name.toString() | titlecase }}</span>
              <span class="item-count">({{ data.itemCount }})</span>
              <span class="material-symbols-outlined arrow-icon" [style.visibility]="!data.expandable ? 'hidden' : ''">
                @if (treeControl.isExpanded(data)) {
                  arrow_drop_down
                } @else {
                  arrow_right
                }
              </span>
            </div>
          </td>
        </ng-container>
        <tr mat-row *matRowDef="let row; columns: headerColumns; when: hasChild" class="group-container"></tr>
      </table>
    </div>
    <div>
      <div class="right-container">
        <mat-card>
          <mat-card-header>
            <mat-card-title>Comment for the changes made</mat-card-title>
          </mat-card-header>
          <span class="comment-container">
            <textarea
              matInput
              placeholder="Type your comment here..."
              [formControl]="comments"
              class="comment"></textarea>
          </span>
          @if (!comments.valid) {
            <p class="warning-msg">Your comment cannot exceed 240 characters.</p>
          }
        </mat-card>
      </div>
    </div>
  </div>
  <div class="flex-between flex-col-center confirm-config-footer">
    @if (changesInProgressWarning) {
      <div class="flex-center warning-container">
        <span class="material-symbols-filled warning-message"> warning </span>
        <span class="font-med warning-message">{{ changesInProgressWarning }}</span>
      </div>
    }
    <div class="row-flex action-buttons">
      <button
        class="round-edge-button btn-light"
        [disabled]="isDisabled"
        (click)="clearConfigData()"
        [routerLink]="['/configure', device.deviceTag!]">
        Back
      </button>
      <button class="round-edge-button btn-black" [disabled]="!comments.valid" (click)="onSave()">Submit</button>
    </div>
  </div>
}
