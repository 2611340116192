<block-ui>
  <div class="configurator-container" *blockUI="'configure'">
    <ng-container
      [ngTemplateOutlet]="
        isConfigFeatureAvailable ? configAvailableTemplate : configNotAvailableTemplate
      "></ng-container>
  </div>
</block-ui>
<ng-template #configNotAvailableTemplate>
  <app-no-data-available [header]="noDataMessage" />
</ng-template>
<ng-template #configAvailableTemplate>
  @if (isEditable) {
    <app-header [title]="pageTitle">
      <ng-container header-right-content>
        @for (buttonText of disabledButtons; track $index) {
          <button
            [disabled]="true"
            [matTooltip]="featureUnavailableTooltip"
            class="round-edge-button btn-light config-page-button">
            <div class="no-drop cl-dark flex-col-center font-med">
              <span>{{ buttonText }}</span>
            </div>
          </button>
        }
      </ng-container>
    </app-header>
  }
  @if (device && treeControl.dataNodes) {
    <div class="parent-container row-flex" [ngClass]="{ 'config-page': isEditable }" id="configurationForm">
      <div class="left-container col-flex">
        @if (isEditable) {
          <div class="col-flex device-info">
            <span class="font-lg">{{ device.deviceTag | uppercase }}</span>
            <span class="font-med cl-grey">{{ device.deviceType }}</span>
          </div>
        }
        <div [ngClass]="{ 'config-tab-menu': !isEditable }">
          <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
            <mat-tree-node
              *matTreeNodeDef="let node"
              matTreeNodePadding
              [ngClass]="{ 'background-highlight': node.name === this.finalFormOutlet.name }">
              <button class="menu-sub-header" (click)="onMenuOptionChange(node)">
                {{ node.name }}
              </button>
            </mat-tree-node>
            <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
              <button
                class="menu-header"
                matTreeNodeToggle
                (click)="closeOtherMenuItems(node)"
                [attr.aria-label]="'Toggle ' + node.name"
                [ngClass]="{ 'text-highlight': selectedParentNodes.includes(node.name) }">
                <div class="row-flex menu-header-position">
                  <span
                    class="material-symbols-outlined arrow-icon"
                    [style.visibility]="!node.expandable ? 'hidden' : ''">
                    @if (treeControl.isExpanded(node)) {
                      arrow_drop_down
                    } @else {
                      arrow_right
                    }
                  </span>
                  {{ node.name }}
                </div>
              </button>
            </mat-tree-node>
          </mat-tree>
        </div>
        @if (!isEditable) {
          <div class="col-flex flex-col-center edit-info-container">
            <button
              class="round-edge-button btn-light font-med edit-button"
              [routerLink]="['/configure', device.deviceTag]">
              Edit
            </button>
            <span class="cl-grey">Last Edited {{ lastEditDate }}</span>
          </div>
        }
      </div>
      @if (finalFormOutlet) {
        <div class="col-flex flex-between right-container">
          <mat-card class="right-content">
            <mat-card-content>
              <app-configurator-form-container
                [configFormOutlet]="finalFormOutlet"
                [isEditable]="isEditable"
                [currentHeader]="formHeaderName"
                [parentHeader]="selectedParentNodes[0]"></app-configurator-form-container>
            </mat-card-content>
          </mat-card>
        </div>
      }
    </div>
    @if (isEditable) {
      <div class="flex-between flex-col-center config-footer">
        <div class="row-flex action-buttons">
          <button class="round-edge-button btn-light" [routerLink]="['/devices', device.deviceTag]">Cancel</button>
          <button
            class="round-edge-button btn-black"
            [disabled]="isDisabled"
            [routerLink]="['/configure', device.deviceTag, 'confirmChange']">
            Confirm change
          </button>
        </div>
      </div>
    }
  }
</ng-template>
