import { Component, DestroyRef, Input, OnChanges, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup } from '@angular/forms';
import { ConfigFormOutlet } from '@app/models/configurator-models/configurator-outline';
import { ConfirmChangeNode } from '@app/models/configurator-models/confirm-change';
import { Configurable } from '@app/models/configurator-models/form-fields';
import { ConfiguratorService } from '@app/services/configurator.service';
import { ConversionService } from '@app/services/conversion.service';
import { UtilsService } from '@app/services/utils.service';
import { MeasurementUnits, TemperatureUnits } from '@app/utils/enums/unit-enums';
import { usageRegExPattern } from '@app/utils/patterns';
import { debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'app-configurator-forms',
  templateUrl: './configurator-forms.component.html',
  styleUrl: './configurator-forms.component.css',
  encapsulation: ViewEncapsulation.None,
})
export class ConfiguratorFormsComponent implements OnInit, OnChanges {
  @Input() formFields!: Configurable[];
  @Input() configurationFormGroup!: FormGroup;
  @Input() formHeader!: string;
  @Input() isEditable = false;
  @Input() positionIndex!: number;
  @Input() currentHeader!: string;
  @Input() parentHeader!: string;
  isFieldEmpty = false;
  destroyRef = inject(DestroyRef);
  intialFormOutlet!: { [key: string]: ConfigFormOutlet };

  constructor(
    private conversionService: ConversionService,
    private configuratorService: ConfiguratorService,
    private utilsService: UtilsService,
  ) {}

  ngOnInit(): void {
    if (this.isEditable) this.setConfigDataOnChange();
    this.configuratorService
      .getConfigFormOutlet()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: configFormOutlet => {
          this.intialFormOutlet = configFormOutlet;
        },
      });
  }

  ngOnChanges(): void {
    this.setUnits();
  }

  setConfigDataOnChange(): void {
    this.configurationFormGroup.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(500), takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          const data = this.configurationFormGroup.getRawValue();
          const usagePattern = usageRegExPattern;
          let configurationObject: { [key: string]: any } = {};
          const usageKey = this.utilsService.getKeyWithPattern(data, usagePattern);
          if (usageKey) {
            this.formFields.forEach(usageField => {
              if (usageRegExPattern.test(usageField.article)) {
                const chosenOption = data[usageField.article].toString();
                usageField?.childConfigurables?.forEach((config, index) => {
                  configurationObject[config.article] = !!Number(chosenOption[index]);
                });
              }
            });
          }
          this.isFieldEmpty = !!Object.values(this.configurationFormGroup.getRawValue()).some(value => value === '');
          if (!this.isFieldEmpty) {
            this.checkValueDifference(data);
            const updatedConfig = this.appendUnits();
            configurationObject = {
              ...configurationObject,
              ...updatedConfig,
            };
            this.formFields.forEach(formField => {
              if (usageRegExPattern.test(formField.article)) delete configurationObject[formField.article];
            });
            if ('digital_input_source' in data) {
              if (configurationObject.digital_input_source === 'LOCAL')
                configurationObject.digital_input_0_path = 'digital_input_0';
              else if (configurationObject.digital_input_source === 'NOT_USED')
                configurationObject.digital_input_0_path = null;
              delete configurationObject.digital_input_source;
            }
            this.configuratorService.setConfigurationData(configurationObject);
          }
        },
      });
  }

  getToolTip(formInputConfigurable: Configurable): string {
    if (formInputConfigurable && formInputConfigurable.type === 'choice') {
      return formInputConfigurable.options!.find(
        option => option.value === this.configurationFormGroup.get(formInputConfigurable.article)!.value,
      )!.displayText;
    }
    return this.configurationFormGroup.get(formInputConfigurable.article)!.value as string;
  }

  appendUnits() {
    const formGroupValues = this.configurationFormGroup.getRawValue() as {
      [key: string]: string | boolean | number;
    };
    for (const formValue of Object.entries(formGroupValues)) {
      const requiredField = this.formFields.find(field => field.article === formValue[0]);
      if (requiredField?.disabled) {
        delete formGroupValues[formValue[0]];
        continue;
      }
      if (requiredField?.unit) {
        const fieldValue =
          requiredField.unit === MeasurementUnits.Fahrenheit
            ? this.conversionService.temperatureUnitConversion(Number(formValue[1]), TemperatureUnits.Celsius)
            : requiredField.unit === MeasurementUnits.DeltaFahrenheit
              ? this.conversionService.deltaTemperatureUnitConversion(Number(formValue[1]), TemperatureUnits.Celsius)
              : formValue[1];
        const requiredUnit =
          requiredField.unit === MeasurementUnits.Fahrenheit
            ? MeasurementUnits.Celsius
            : requiredField.unit === MeasurementUnits.DeltaFahrenheit
              ? MeasurementUnits.DeltaCelsius
              : this.conversionService.removeExtraCharactersFromUnit(requiredField?.unit);
        formGroupValues[formValue[0]] = `${String(fieldValue)}${requiredUnit}`;
      }
    }
    return formGroupValues;
  }

  onKeyPress(event: KeyboardEvent, unit: string | undefined) {
    if (unit === '%') {
      const charCode = event.key?.charCodeAt(0);
      return charCode >= 48 && charCode <= 57;
    }
    return true;
  }

  setUnits(): void {
    if (this.formFields)
      this.formFields.forEach(field => {
        field.unit = field.unit !== 'None' ? this.conversionService.removeExtraCharactersFromUnit(field.unit!) : '';
      });
  }

  getDisplayText(field: Configurable, configFormGroup: FormGroup = this.configurationFormGroup): string | undefined {
    const fieldValue = configFormGroup.get(field.article)?.value;
    return field.options?.find(option => option.value === fieldValue)?.displayText;
  }

  checkValueDifference(configChanges: Record<string, string | boolean | number>) {
    if (this.intialFormOutlet[this.currentHeader].formContent.length)
      this.intialFormOutlet[this.currentHeader].formContent.forEach(ele => {
        ele.controls.forEach(formControl => {
          if (this.formHeader === ele.formName) {
            let previousValue = ele.formGroup.value[formControl.article];
            let updatedValue = configChanges[formControl.article];
            const unitOfValue = formControl.unit;
            if (formControl.options?.length) {
              previousValue = this.getDisplayText(formControl, ele.formGroup) as string;
              updatedValue = this.getDisplayText(formControl) as string;
            }
            if (
              updatedValue !== undefined &&
              previousValue !== undefined &&
              previousValue?.toString() !== updatedValue.toString() &&
              this.configurationFormGroup.valid
            ) {
              if (unitOfValue) {
                previousValue = previousValue + unitOfValue;
                updatedValue = updatedValue + unitOfValue;
              }
              const changedValue = this.mapFormChanges(formControl.label, previousValue, updatedValue);
              this.configuratorService.addChangedData(this.parentHeader, changedValue);
            } else {
              this.configuratorService.deleteChangedValue(this.parentHeader, formControl.label);
            }
          }
        });
      });
  }

  mapFormChanges(label: string, previousValue: any, updatedValue: any): ConfirmChangeNode {
    return {
      name: label,
      changedValues: {
        previousValue: previousValue,
        newValue: updatedValue,
      },
    };
  }
}
