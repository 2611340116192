import { Injectable } from '@angular/core';
import { ConfiguratorData } from '@models/configurator';
import { cm2000ConfigurablesForForms } from '@models/configurator-models/cm2000-forms';
import { cm2000pConfigurablesForForms } from '@models/configurator-models/cm2000p-forms';
import { ConfigFormOutlet, ConfigureFormMeta } from '@models/configurator-models/configurator-outline';
import { elexant3500iConfigurablesForForms } from '@models/configurator-models/elexant3500i-forms';
import { elexant40x0ConfigurablesForForms } from '@models/configurator-models/elexant40x0-forms';
import { elexant5010iConfigurablesForForms } from '@models/configurator-models/elexant5010i-forms';
import { Configurable } from '@models/configurator-models/form-fields';
import { htc910ConfigurablesForForms } from '@models/configurator-models/htc910-forms';
import { htc920ConfigurablesForForms } from '@models/configurator-models/htc920-forms';
import { Menu } from '@models/configurator-models/menu-items';
import { htcConfigurablesForForms } from '@models/configurator-models/ngc40htc-forms';
import { htc3ConfigurablesForForms } from '@models/configurator-models/ngc40htc3-forms';
import { ngc40ioConfigurablesForForms } from '@models/configurator-models/ngc40io-forms';
import { slimConfigurablesForForms } from '@models/configurator-models/ngc40slim-forms';
import { ConfiguratorService } from '@services/configurator.service';
import { UtilsService } from '@services/utils.service';
import { DeviceType } from '@utils/enums/device-enums';
import { cloneDeep } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class CreateFormMetaService {
  deviceType = '';
  formsOnPage: ConfigureFormMeta[] = [];
  isActualConfig = true;
  constructor(
    private configuratorService: ConfiguratorService,
    private utilsService: UtilsService,
  ) {}

  createformOutlet(menu: Menu, itemLabel: string, data: ConfiguratorData, isActualConfig: boolean): ConfigFormOutlet {
    this.deviceType = this.utilsService.getDeviceTypeName(data.deviceType!).toUpperCase();
    this.isActualConfig = isActualConfig;
    this.formsOnPage = [];
    if (menu.child) {
      menu.child.menu.forEach(forms => {
        if (forms.child) {
          forms.child.menu.forEach((childMenu: Menu) => {
            this.setFormContent(childMenu.parent, data);
          });
        } else {
          this.setFormContent(forms.parent, data);
        }
      });
    } else {
      this.setFormContent(menu.parent, data);
    }
    return { name: itemLabel, formContent: this.formsOnPage };
  }

  createConfigurableArrayKey(formName: string, deviceType: string) {
    return formName.replace(/[ -.]/g, '') + deviceType;
  }

  setFormContent(formName: string, data: ConfiguratorData) {
    const configurableFormName = this.getConfigsByType(this.deviceType);
    const configs = cloneDeep(configurableFormName[this.createConfigurableArrayKey(formName, this.deviceType)]);
    this.formsOnPage.push({
      formName,
      formGroup: this.configuratorService.createFormGroup(configs, data, this.isActualConfig),
      controls: configs,
    });
  }

  getConfigsByType(deviceType: string): { [key: string]: Configurable[] } {
    switch (deviceType.toLowerCase()) {
      case DeviceType.Htc3:
        return htc3ConfigurablesForForms;
      case DeviceType.Cm2000:
        return cm2000ConfigurablesForForms;
      case DeviceType.Cm2000p:
        return cm2000pConfigurablesForForms;
      case DeviceType.Htc910:
        return htc910ConfigurablesForForms;
      case DeviceType.Htc920:
        return htc920ConfigurablesForForms;
      case DeviceType.Elexant40X0i:
        return elexant40x0ConfigurablesForForms;
      case DeviceType.Elexant5010i:
        return elexant5010iConfigurablesForForms;
      case DeviceType.Elexant3500i:
        return elexant3500iConfigurablesForForms;
      case DeviceType.Htc:
        return htcConfigurablesForForms;
      case DeviceType.Io:
        return ngc40ioConfigurablesForForms;
      case DeviceType.Slim:
        return slimConfigurablesForForms;
      default:
        return { '': [] };
    }
  }
}
