<div class="info-container">
  <div class="row-flex row">
    <span class="info-text cl-grey">Description</span>
    <span class="info-text cl-grey">Value</span>
  </div>
  @if (isDeviceExist) {
    @for (attr of latestMaintenanceInfo | keyvalue; track $index) {
      <div class="row-flex row">
        <div class="flex info-text">{{ attr.key }}</div>
        <div class="flex-center">
          <div class="highlighted">{{ attr.value[0] }}</div>
          <div class="small-container">{{ attr.value[1] }}</div>
        </div>
      </div>
    }
  } @else {
    <app-no-data-available></app-no-data-available>
  }
</div>
