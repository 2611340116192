import { updateBooleanAttributes } from '@app/utils/configurator-util';
import { enableAttributes, latchAttributes, modeAttributes } from '@app/utils/constants/configurator-constants';
import { Configurable } from '@models/configurator-models/form-fields';
import { elexant3500i } from '@utils/configurator-meta/elexant3500i-meta';

export const elexant3500iConfigurablesForForms: { [key: string]: Configurable[] } = {
  ControlTemperatureELEXANT3500I: [
    elexant3500i.htc_0_control_temperature_setpoint as Configurable,
    elexant3500i.htc_0_temperature_control_mode as Configurable,
    elexant3500i.htc_0_high_limit_cutout_setpoint,
    elexant3500i.htc_0_temperature_fail_mode as Configurable,
  ],
  ControlTemperatureHighAlarmELEXANT3500I: [
    updateBooleanAttributes(elexant3500i.htc_0_control_temperature_high_mask as Configurable, enableAttributes),
    elexant3500i.htc_0_control_temperature_high_alarm_setpoint as Configurable,
    elexant3500i.htc_0_control_temperature_high_alarm_filter as Configurable,
  ],
  ControlTemperatureLowAlarmELEXANT3500I: [
    updateBooleanAttributes(elexant3500i.htc_0_control_temperature_low_mask as Configurable, enableAttributes),
    elexant3500i.htc_0_control_temperature_low_alarm_setpoint as Configurable,
    elexant3500i.htc_0_control_temperature_low_alarm_filter as Configurable,
  ],
  ControlModesELEXANT3500I: [
    elexant3500i.htc_0_switch_control_mode as Configurable,
    elexant3500i.htc_0_deadband as Configurable,
    elexant3500i.htc_0_pasc_min_ambient_temperature as Configurable,
    elexant3500i.htc_0_pasc_min_pipe_size as Configurable,
  ],
  LocalRTD1ELEXANT3500I: [
    elexant3500i.temperature_0_type as Configurable,
    elexant3500i.temperature_0_tag as Configurable,
    updateBooleanAttributes(elexant3500i.temperature_0_failure_mask as Configurable, enableAttributes),
  ],
  LocalRTD1HighAlarmELEXANT3500I: [
    updateBooleanAttributes(elexant3500i.temperature_0_high_mask as Configurable, enableAttributes),
    elexant3500i.temperature_0_high_alarm_setpoint as Configurable,
    elexant3500i.temperature_0_high_alarm_filter as Configurable,
  ],
  LocalRTD1LowAlarmELEXANT3500I: [
    updateBooleanAttributes(elexant3500i.temperature_0_low_mask as Configurable, enableAttributes),
    elexant3500i.temperature_0_low_alarm_setpoint as Configurable,
    elexant3500i.temperature_0_low_alarm_filter as Configurable,
  ],
  LocalRTD2ELEXANT3500I: [
    elexant3500i.temperature_1_type as Configurable,
    elexant3500i.temperature_1_tag as Configurable,
    updateBooleanAttributes(elexant3500i.temperature_1_failure_mask as Configurable, enableAttributes),
  ],
  LocalRTD2HighAlarmELEXANT3500I: [
    updateBooleanAttributes(elexant3500i.temperature_1_high_mask as Configurable, enableAttributes),
    elexant3500i.temperature_1_high_alarm_setpoint as Configurable,
    elexant3500i.temperature_1_high_alarm_filter as Configurable,
  ],
  LocalRTD2LowAlarmELEXANT3500I: [
    updateBooleanAttributes(elexant3500i.temperature_1_low_mask as Configurable, enableAttributes),
    elexant3500i.temperature_1_low_alarm_setpoint as Configurable,
    elexant3500i.temperature_1_low_alarm_filter as Configurable,
  ],
  TemperatureSource1ELEXANT3500I: [
    updateBooleanAttributes(elexant3500i.htc_0_temperature_source_0_control_enabled as Configurable, modeAttributes),
    updateBooleanAttributes(
      elexant3500i.htc_0_temperature_source_0_high_cutout_enabled as Configurable,
      modeAttributes,
    ),
    elexant3500i.htc_0_temperature_source_0_path as Configurable,
  ],
  TemperatureSource2ELEXANT3500I: [
    updateBooleanAttributes(elexant3500i.htc_0_temperature_source_1_control_enabled as Configurable, modeAttributes),
    updateBooleanAttributes(
      elexant3500i.htc_0_temperature_source_1_high_cutout_enabled as Configurable,
      modeAttributes,
    ),
    elexant3500i.htc_0_temperature_source_1_path as Configurable,
  ],
  FailureandTripAlarmsELEXANT3500I: [
    updateBooleanAttributes(elexant3500i.device_reset_mask as Configurable, enableAttributes),
    updateBooleanAttributes(elexant3500i.htc_0_ground_fault_transformer_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(elexant3500i.htc_0_switch_0_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(elexant3500i.htc_0_high_limit_cutout_mask as Configurable, enableAttributes),
    updateBooleanAttributes(elexant3500i.htc_0_control_temperature_failure_mask as Configurable, enableAttributes),
  ],
  ContactorCycleCountAlarmELEXANT3500I: [
    updateBooleanAttributes(
      elexant3500i.htc_0_contactor_cycle_count_limit_exceeded_mask as Configurable,
      enableAttributes,
    ),
    elexant3500i.htc_0_contactor_cycle_count_setpoint as Configurable,
    updateBooleanAttributes(
      elexant3500i.htc_0_contactor_cycle_count_limit_exceeded_latch as Configurable,
      latchAttributes,
    ),
  ],
  HeaterTimeAlarmELEXANT3500I: [
    updateBooleanAttributes(elexant3500i.htc_0_heater_time_limit_exceeded_mask as Configurable, enableAttributes),
    elexant3500i.htc_0_heater_time_setpoint as Configurable,
    updateBooleanAttributes(elexant3500i.htc_0_heater_time_limit_exceeded_latch as Configurable, latchAttributes),
  ],
  AlarmOutputELEXANT3500I: [elexant3500i.alarm_0_mode as Configurable],
  TemperatureAlarmsELEXANT3500I: [
    updateBooleanAttributes(elexant3500i.htc_0_control_temperature_failure_latch as Configurable, latchAttributes),
    updateBooleanAttributes(elexant3500i.htc_0_control_temperature_high_latch as Configurable, latchAttributes),
    updateBooleanAttributes(elexant3500i.htc_0_control_temperature_low_latch as Configurable, latchAttributes),
    elexant3500i.htc_0_non_latching_high_temperature_cutout_alarms as Configurable,
    elexant3500i.htc_0_non_latching_temperature_alarms as Configurable,
    updateBooleanAttributes(elexant3500i.temperature_0_failure_latch as Configurable, latchAttributes),
    updateBooleanAttributes(elexant3500i.temperature_0_high_latch as Configurable, latchAttributes),
    updateBooleanAttributes(elexant3500i.temperature_0_low_latch as Configurable, latchAttributes),
    updateBooleanAttributes(elexant3500i.temperature_1_failure_latch as Configurable, latchAttributes),
    updateBooleanAttributes(elexant3500i.temperature_1_high_latch as Configurable, latchAttributes),
    updateBooleanAttributes(elexant3500i.temperature_1_low_latch as Configurable, latchAttributes),
  ],
  ElectricalAlarmsELEXANT3500I: [
    updateBooleanAttributes(elexant3500i.htc_0_ground_fault_current_high_latch as Configurable, latchAttributes),
    updateBooleanAttributes(elexant3500i.htc_0_ground_fault_transformer_failure_latch as Configurable, latchAttributes),
    updateBooleanAttributes(elexant3500i.htc_0_ground_fault_trip_latch as Configurable, latchAttributes),
  ],
  LowLineCurrentAlarmELEXANT3500I: [
    updateBooleanAttributes(elexant3500i.htc_0_load_current_low_mask as Configurable, enableAttributes),
    elexant3500i.htc_0_load_current_low_alarm_setpoint as Configurable,
    updateBooleanAttributes(elexant3500i.htc_0_load_current_low_latch as Configurable, latchAttributes),
    elexant3500i.htc_0_load_current_low_alarm_filter as Configurable,
  ],
  OtherAlarmsELEXANT3500I: [
    updateBooleanAttributes(elexant3500i.htc_0_switch_0_failure_latch as Configurable, latchAttributes),
    updateBooleanAttributes(elexant3500i.limiter_0_temperature_sensor_failure_latch as Configurable, latchAttributes),
    updateBooleanAttributes(elexant3500i.htc_0_high_limit_cutout_latch as Configurable, latchAttributes),
  ],
  ConfigurationLostAlarmELEXANT3500I: [
    updateBooleanAttributes(elexant3500i.htc_0_switch_0_failure_latch as Configurable, latchAttributes),
    updateBooleanAttributes(elexant3500i.limiter_0_temperature_sensor_failure_latch as Configurable, latchAttributes),
    updateBooleanAttributes(elexant3500i.htc_0_high_limit_cutout_latch as Configurable, latchAttributes),
  ],
  FactoryELEXANT3500I: [
    updateBooleanAttributes(
      elexant3500i.nvram_0_factory_configuration_data_lost_mask as Configurable,
      enableAttributes,
    ),
    updateBooleanAttributes(
      elexant3500i.nvram_0_factory_configuration_data_lost_latch as Configurable,
      latchAttributes,
    ),
  ],
  UserELEXANT3500I: [
    updateBooleanAttributes(elexant3500i.nvram_0_user_configuration_data_lost_mask as Configurable, enableAttributes),
    updateBooleanAttributes(elexant3500i.nvram_0_user_configuration_data_lost_latch as Configurable, latchAttributes),
  ],
  SafetyTemperatureLimiterAlarmsELEXANT3500I: [
    updateBooleanAttributes(elexant3500i.limiter_0_trip_mask as Configurable, enableAttributes),
    updateBooleanAttributes(elexant3500i.limiter_0_trip_latch as Configurable, latchAttributes),
  ],
  TemperatureSensorFailureAlarmELEXANT3500I: [
    updateBooleanAttributes(elexant3500i.limiter_0_temperature_sensor_failure_mask as Configurable, modeAttributes),
  ],
  // The only field in the below form is not available in DB so commenting the entire form
  // GroundFaultCurrentELEXANT3500I: [elexant3500i.htc_0_energize as Configurable],
  GroundFaultCurrentHighAlarmELEXANT3500I: [
    updateBooleanAttributes(elexant3500i.htc_0_ground_fault_current_high_mask as Configurable, enableAttributes),
    elexant3500i.htc_0_ground_fault_current_high_alarm_setpoint as Configurable,
  ],
  GroundFaultTripELEXANT3500I: [
    updateBooleanAttributes(elexant3500i.htc_0_ground_fault_trip_mask as Configurable, enableAttributes),
    elexant3500i.htc_0_ground_fault_current_trip_setpoint as Configurable,
  ],
  SafetyTemperatureLimiterTripSetpointELEXANT3500I: [
    elexant3500i.limiter_0_cutout_setpoint as Configurable,
    elexant3500i.limiter_0_tag as Configurable,
  ],
  AutoCycleELEXANT3500I: [elexant3500i.htc_0_auto_cycle_interval as Configurable],
  CommunicationSettingsELEXANT3500I: [
    elexant3500i.serial_0_baud_rate as Configurable,
    elexant3500i.serial_0_data_bits as Configurable,
    elexant3500i.serial_0_frame_type as Configurable,
    elexant3500i.serial_0_modbus_address as Configurable,
    elexant3500i.serial_0_parity as Configurable,
    elexant3500i.serial_0_stop_bits as Configurable,
    elexant3500i.serial_0_tx_delay as Configurable,
  ],
  CommunicationFailureELEXANT3500I: [
    updateBooleanAttributes(elexant3500i.agent_communication_failure_mask as Configurable, enableAttributes),
    elexant3500i.agent_communication_failure_limit as Configurable,
  ],
  DeviceSettingsELEXANT3500I: [
    updateBooleanAttributes(elexant3500i.device_reset_latch as Configurable, latchAttributes),
    {
      article: 'device_type',
      label: 'Device type',
      unit: 'None',
      type: 'constant',
      disabled: true,
      group: 'Misc Settings\\Device Settings',
    } as Configurable,
    {
      article: 'device_firmware_version',
      label: 'Firmware version',
      unit: 'None',
      type: 'constant',
      disabled: true,
      group: 'Misc Settings\\Device Settings',
    } as Configurable,
    {
      article: 'device_serial',
      label: 'Serial number',
      unit: 'None',
      type: 'constant',
      disabled: true,
      group: 'Misc Settings\\Device Settings',
    } as Configurable,
  ],
};
