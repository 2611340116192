import { AbstractControl, Validators } from '@angular/forms';
import { Configurable, Context } from '@models/configurator-models/form-fields';

export const ngc40slim = {
  agent_communication_failure_limit: {
    article: 'agent_communication_failure_limit',
    defaultValue: '1h',
    group: 'Regs/Config/ControllerSetupParameters/ControllerSetupParameterRegs/AgentCommunicationFailureLimit',
    label: 'Agent communication failure limit',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  agent_communication_failure_mask: {
    article: 'agent_communication_failure_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/AgentCommunicationFailure',
    label: 'Agent communication failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  alarm_0_mode: {
    article: 'alarm_0_mode',
    defaultValue: 'NORMAL_OPERATION',
    group: 'Regs/Config/ControllerSetupParameters/ControllerSetupParameterRegs/AlarmOutputMode',
    label: 'Alarm output mode',
    options: [
      { key: 0, value: 'NORMAL_OPERATION', displayText: 'NORMAL OPERATION' },
      { key: 1, value: 'TOGGLE', displayText: 'TOGGLE' },
      { key: 2, value: 'FLASH', displayText: 'FLASH' },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        if (!control.value) {
          control.parent?.get('alarm_0_toggle_time')?.disable();
          control.parent?.get('alarm_0_toggle_time')?.reset();
        } else {
          control.parent?.get('alarm_0_toggle_time')?.enable();
        }
      };
    },
  } as Configurable,
  alarm_0_sense: {
    article: 'alarm_0_sense',
    defaultValue: 'NORMALLY_CLOSED',
    group: 'Regs/Config/ControllerSetupParameters/ControllerSetupParameterRegs/AlarmRelaySense',
    label: 'Alarm relay sense',
    options: [
      { key: 0, value: 'NORMALLY_CLOSED', displayText: 'NORMALLY CLOSED' },
      { key: 1, value: 'NORMALLY_OPEN', displayText: 'NORMALLY OPEN' },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  alarm_0_toggle_time: {
    article: 'alarm_0_toggle_time',
    defaultValue: '60s',
    group: 'Regs/Config/ControllerSetupParameters/ControllerSetupParameterRegs/AlarmOutputToggleTime',
    label: 'Alarm output toggle time',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 1;
        this.max = 240;
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  device_broadcast_timeout: {
    article: 'device_broadcast_timeout',
    defaultValue: '1m',
    group: 'Regs/Config/ControllerSetupParameters/ControllerSetupParameterRegs/BroadcastTimeout',
    label: 'Broadcast timeout',
    type: 'range',
    unit: 'm',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 1;
        this.max = 10;
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  device_reset_mask: {
    article: 'device_reset_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/DeviceReset',
    label: 'Device reset mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  device_tag: {
    article: 'device_tag',
    defaultValue: 'NGC40-SLIM-{CAN Address}',
    group: 'Regs/Config/ControllerSetupParameters/ControllerSetupParameterRegs/Tag',
    label: 'Tag',
    type: 'string16',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        this.min = 1;
        this.max = 40;
        control.setValidators([Validators.maxLength(this.max), Validators.minLength(this.min)]);
      };
    },
  } as Configurable,
  limiter_0_contactor_cycle_count_limit_exceeded_mask: {
    article: 'limiter_0_contactor_cycle_count_limit_exceeded_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/ContactorCycleCount',
    label: 'Contactor cycle count mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        if (!control.value) {
          control.parent?.get('limiter_0_contactor_cycle_count_setpoint')?.disable();
          control.parent?.get('limiter_0_contactor_cycle_count_setpoint')?.reset();
        } else {
          control.parent?.get('limiter_0_contactor_cycle_count_setpoint')?.enable();
        }
      };
    },
  } as Configurable,
  limiter_0_contactor_cycle_count_setpoint: {
    article: 'limiter_0_contactor_cycle_count_setpoint',
    defaultValue: 100000,
    group: 'Regs/Config/ControllerSetupParameters/ControllerSetupParameterRegs/ContactorCycleCountAlarmLimitSetpoint',
    label: 'Contactor cycle count alarm limit setpoint',
    type: 'int',
    unit: '',
    validator(ctx?: Context) {
      [this.min, this.max] = [0, 999999];
      return (control: AbstractControl) => {
        return control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  limiter_0_htc_communication_failure_latch: {
    article: 'limiter_0_htc_communication_failure_latch',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Latch/HTCCommunicationFailure',
    label: 'Htc communication failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  limiter_0_htc_communication_failure_mask: {
    article: 'limiter_0_htc_communication_failure_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/HTCCommunicationFailure',
    label: 'Htc communication failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  limiter_0_trip_latch: {
    article: 'limiter_0_trip_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/LimiterTripped',
    label: 'Limiter tripped latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  limiter_0_trip_mask: {
    article: 'limiter_0_trip_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/LimiterTripped',
    label: 'Limiter tripped mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  nvram_0_factory_configuration_data_lost_mask: {
    article: 'nvram_0_factory_configuration_data_lost_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/FactoryConfigurationDataLost',
    label: 'Factory configuration data lost mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  nvram_0_user_configuration_data_lost_mask: {
    article: 'nvram_0_user_configuration_data_lost_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/UserConfigurationDataLost',
    label: 'User configuration data lost mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  temperature_0_failure_latch: {
    article: 'temperature_0_failure_latch',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Latch/RTDFailure/elem',
    label: 'Temperature 0 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  temperature_0_failure_mask: {
    article: 'temperature_0_failure_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/RTDFailure/elem',
    label: 'Temperature 0 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  temperature_0_tag: {
    article: 'temperature_0_tag',
    defaultValue: 'NGC40-SLIM-RTD{index}-{CAN Address}',
    group: 'Regs/Config/TemperatureSensorSetup/elem/Tag',
    label: 'Temperature 0 Tag',
    type: 'string16',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        this.min = 1;
        this.max = 40;
        control.setValidators([Validators.maxLength(this.max), Validators.minLength(this.min)]);
      };
    },
  } as Configurable,
  temperature_1_enabled: {
    article: 'temperature_1_enabled',
    defaultValue: false,
    group: 'Regs/Config/ControllerSetupParameters/ControllerSetupParameterBits/TemperatureSensorSetup_1',
    label: 'Temperature sensor setup 1',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  temperature_1_failure_latch: {
    article: 'temperature_1_failure_latch',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Latch/RTDFailure/elem',
    label: 'Temperature 1 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  temperature_1_failure_mask: {
    article: 'temperature_1_failure_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/RTDFailure/elem',
    label: 'Temperature 1 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  temperature_1_tag: {
    article: 'temperature_1_tag',
    defaultValue: 'NGC40-SLIM-RTD{index}-{CAN Address}',
    group: 'Regs/Config/TemperatureSensorSetup/elem/Tag',
    label: 'Temperature 1 Tag',
    type: 'string16',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        this.min = 1;
        this.max = 40;
        control.setValidators([Validators.maxLength(this.max), Validators.minLength(this.min)]);
      };
    },
  } as Configurable,
  temperature_2_enabled: {
    article: 'temperature_2_enabled',
    defaultValue: false,
    group: 'Regs/Config/ControllerSetupParameters/ControllerSetupParameterBits/TemperatureSensorSetup_2',
    label: 'Temperature sensor setup 2',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  temperature_2_failure_latch: {
    article: 'temperature_2_failure_latch',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Latch/RTDFailure/elem',
    label: 'Temperature 2 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  temperature_2_failure_mask: {
    article: 'temperature_2_failure_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/RTDFailure/elem',
    label: 'Temperature 2 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  temperature_2_tag: {
    article: 'temperature_2_tag',
    defaultValue: 'NGC40-SLIM-RTD{index}-{CAN Address}',
    group: 'Regs/Config/TemperatureSensorSetup/elem/Tag',
    label: 'Temperature 2 Tag',
    type: 'string16',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        this.min = 1;
        this.max = 40;
        control.setValidators([Validators.maxLength(this.max), Validators.minLength(this.min)]);
      };
    },
  } as Configurable,
};
