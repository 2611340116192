import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/auth.guard';
import { AboutPageComponent } from '@app/shared/about-page/about-page.component';
import { TermsComponent } from '@app/shared/terms/terms.component';
import { WaitForApprovalComponent } from '@app/shared/wait-for-approval/wait-for-approval.component';
import { AlarmsComponent } from '@components/alarms/alarms.component';
import { ConfiguratorComponent } from '@components/configurator/configurator.component';
import { AddDevicesComponent } from '@components/devices/add-devices/add-devices.component';
import { DevicesComponent } from '@components/devices/devices.component';
import { UnauthorizedComponent } from '@shared/unauthorized/unauthorized.component';
import { PermissionsList } from '@utils/constants/user-permissions';
import { ConfigConfirmationComponent } from './components/configurator/config-confirmation/config-confirmation.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'devices',
    component: DevicesComponent,
    canActivate: [AuthGuard],
    data: { permission: PermissionsList.DevicePermissions.ReadDevice },
  },
  { path: 'devices/:deviceTag', component: DevicesComponent, canActivate: [AuthGuard] },
  { path: 'configure/:deviceTag', component: ConfiguratorComponent, canActivate: [AuthGuard] },
  { path: 'configure/:deviceTag/confirmChange', component: ConfigConfirmationComponent, canActivate: [AuthGuard] },
  {
    path: 'add-device',
    component: AddDevicesComponent,
    canActivate: [AuthGuard],
    data: { permission: PermissionsList.DevicePermissions.CreateDevice },
  },
  {
    path: 'approval',
    component: WaitForApprovalComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'terms',
    component: TermsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'alarms',
    component: AlarmsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'about',
    component: AboutPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'settings',
    loadChildren: () => import('./components/settings/settings.module').then(m => m.SettingsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./components/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard],
  },
  { path: 'unauthorized', component: UnauthorizedComponent },
  {
    path: '**',
    redirectTo: 'dashboard',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
