import { updateBooleanAttributes } from '@app/utils/configurator-util';
import { Configurable } from './form-fields';
import { ngc40slim } from '@app/utils/configurator-meta/ngc40slim-meta';
import { enableAttributes, latchAttributes } from '@app/utils/constants/configurator-constants';

export const slimConfigurablesForForms: { [key: string]: Configurable[] } = {
  TemperatureSensorSettingsSLIM: [
    ngc40slim.temperature_0_tag as Configurable,
    ngc40slim.temperature_1_tag as Configurable,
    ngc40slim.temperature_2_tag as Configurable,
  ],
  TemperatureUsageSettingsSLIM: [
    updateBooleanAttributes(ngc40slim.temperature_1_enabled as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40slim.temperature_2_enabled as Configurable, enableAttributes),
  ],
  AlarmOutputSLIM: [
    ngc40slim.alarm_0_sense as Configurable,
    ngc40slim.alarm_0_mode as Configurable,
    ngc40slim.alarm_0_toggle_time as Configurable,
  ],
  TemperatureSensor1FailureAlarmSLIM: [
    updateBooleanAttributes(ngc40slim.temperature_0_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40slim.temperature_0_failure_latch as Configurable, latchAttributes),
  ],
  TemperatureSensor2FailureAlarmSLIM: [
    updateBooleanAttributes(ngc40slim.temperature_1_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40slim.temperature_1_failure_latch as Configurable, latchAttributes),
  ],
  TemperatureSensor3FailureAlarmSLIM: [
    updateBooleanAttributes(ngc40slim.temperature_2_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40slim.temperature_2_failure_latch as Configurable, latchAttributes),
  ],
  LimiterTrippedAlarmSLIM: [
    updateBooleanAttributes(ngc40slim.limiter_0_trip_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40slim.limiter_0_trip_latch as Configurable, latchAttributes),
  ],
  HTCCommunicationFailureAlarmSLIM: [
    updateBooleanAttributes(ngc40slim.limiter_0_htc_communication_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40slim.limiter_0_htc_communication_failure_latch as Configurable, latchAttributes),
  ],
  LimiterResetAlarmSLIM: [updateBooleanAttributes(ngc40slim.device_reset_mask as Configurable, enableAttributes)],
  ContactorAlarmSLIM: [
    updateBooleanAttributes(
      ngc40slim.limiter_0_contactor_cycle_count_limit_exceeded_mask as Configurable,
      enableAttributes,
    ),
    ngc40slim.limiter_0_contactor_cycle_count_setpoint as Configurable,
  ],
  DeviceInformationSLIM: [
    ngc40slim.device_tag as Configurable,
    {
      article: 'device_type',
      label: 'Device type',
      unit: 'None',
      type: 'constant',
      disabled: true,
      group: 'Misc Settings\\Device Information',
    } as Configurable,
    {
      article: 'device_firmware_version',
      label: 'Firmware version',
      unit: 'None',
      type: 'constant',
      disabled: true,
      group: 'Misc Settings\\Device Information',
    } as Configurable,
    {
      article: 'device_serial',
      label: 'Serial number',
      unit: 'None',
      type: 'constant',
      disabled: true,
      group: 'Misc Settings\\Device Information',
    } as Configurable,
  ],
};
