import { htc920 } from '@app/utils/configurator-meta/htc920-meta';
import { updateBooleanAttributes } from '@app/utils/configurator-util';
import {
  modeAttributes,
  yesNoAttributes,
  enableAttributes,
  externalInputSourceAttributes,
  periodAttributes,
} from '@app/utils/constants/configurator-constants';
import { Configurable } from './form-fields';

export const htc920ConfigurablesForForms: { [key: string]: Configurable[] } = {
  ControlTemperatureHTC920: [
    htc920.htc_0_control_temperature_setpoint as Configurable,
    htc920.htc_0_temperature_control_mode as Configurable,
    htc920.htc_0_external_output_configuration as Configurable,
    updateBooleanAttributes(htc920.htc_0_temperature_fail_mode as Configurable, modeAttributes),
  ],
  ControlModesHTC920: [
    htc920.htc_0_switch_control_mode as Configurable,
    htc920.htc_0_deadband as Configurable,
    htc920.htc_0_proportional_band as Configurable,
    htc920.htc_0_proportional_ambient_cycle_time as Configurable,
  ],
  TemperatureSensorsHTC920: [
    htc920.temperature_0_type as Configurable,
    htc920.temperature_0_lead_resistance as Configurable,
    htc920.temperature_1_type as Configurable,
    htc920.temperature_1_lead_resistance as Configurable,
  ],
  MiscellaneousHTC920: [
    htc920.console_0_control_temperature_setpoint_upper_bound as Configurable,
    htc920.console_0_control_temperature_setpoint_lower_bound as Configurable,
    //Note: Need to add attribute HTC Console Units
    updateBooleanAttributes(htc920.device_point_being_used as Configurable, yesNoAttributes),
  ],
  HighTS1TemperatureHTC920: [
    updateBooleanAttributes(htc920.temperature_0_high_mask as Configurable, enableAttributes),
    htc920.temperature_0_high_alarm_setpoint as Configurable,
    htc920.temperature_0_high_alarm_filter as Configurable,
  ],
  LowTS1TemperatureHTC920: [
    updateBooleanAttributes(htc920.temperature_0_low_mask as Configurable, enableAttributes),
    htc920.temperature_0_low_alarm_setpoint as Configurable,
    htc920.temperature_0_low_alarm_filter as Configurable,
  ],
  TS1FailureHTC920: [updateBooleanAttributes(htc920.temperature_0_failure_mask as Configurable, enableAttributes)],
  HighLimitTemperatureCutOut1HTC920: [
    updateBooleanAttributes(htc920.temperature_0_high_cutout_enabled as Configurable, enableAttributes),
  ],
  HighTS2TemperatureHTC920: [
    updateBooleanAttributes(htc920.temperature_1_high_mask as Configurable, enableAttributes),
    htc920.temperature_1_high_alarm_setpoint as Configurable,
  ],
  LowTS2TemperatureHTC920: [
    updateBooleanAttributes(htc920.temperature_1_low_mask as Configurable, enableAttributes),
    htc920.temperature_1_low_alarm_setpoint as Configurable,
  ],
  TS2FailureHTC920: [updateBooleanAttributes(htc920.temperature_1_failure_mask as Configurable, enableAttributes)],
  HighLimitTemperatureCutOut2HTC920: [
    updateBooleanAttributes(htc920.temperature_1_high_cutout_enabled as Configurable, enableAttributes),
  ],
  TSControlHTC920: [
    updateBooleanAttributes(htc920.htc_0_control_temperature_failure_mask as Configurable, enableAttributes),
  ],
  OvercurrentTripHTC920: [
    updateBooleanAttributes(htc920.htc_0_line_current_0_over_mask as Configurable, enableAttributes),
  ],
  SwitchFailureHTC920: [updateBooleanAttributes(htc920.htc_0_switch_0_failure_mask as Configurable, enableAttributes)],
  HTCResetHTC920: [updateBooleanAttributes(htc920.device_reset_mask as Configurable, enableAttributes)],
  EEROMDataFailureHTC920: [
    updateBooleanAttributes(htc920.nvram_0_total_failure_mask as Configurable, enableAttributes),
  ],
  CBLimitingHTC920: [
    updateBooleanAttributes(htc920.htc_0_circuit_breaker_0_limiting_mask as Configurable, enableAttributes),
  ],
  OutputLimitingHTC920: [updateBooleanAttributes(htc920.htc_0_power_limiting_mask as Configurable, enableAttributes)],
  SwitchLimitingHTC920: [
    updateBooleanAttributes(htc920.htc_0_switch_0_limiting_mask as Configurable, enableAttributes),
  ],
  ContactorCountHTC920: [
    updateBooleanAttributes(htc920.htc_0_contactor_cycle_count_limit_exceeded_mask as Configurable, enableAttributes),
    htc920.htc_0_contactor_cycle_count_setpoint as Configurable,
  ],
  FlashAlarmOutputHTC920: [updateBooleanAttributes(htc920.alarm_0_mode as Configurable, yesNoAttributes)],
  HighLoadCurrentHTC920: [
    updateBooleanAttributes(htc920.htc_0_load_current_high_mask as Configurable, enableAttributes),
    htc920.htc_0_load_current_high_alarm_setpoint as Configurable,
    htc920.htc_0_load_current_high_alarm_filter as Configurable,
  ],
  LowLoadCurrentHTC920: [
    updateBooleanAttributes(htc920.htc_0_load_current_low_mask as Configurable, enableAttributes),
    htc920.htc_0_load_current_low_alarm_setpoint as Configurable,
    htc920.htc_0_load_current_low_alarm_filter as Configurable,
  ],
  HighGroundFaultCurrentHTC920: [
    updateBooleanAttributes(htc920.htc_0_ground_fault_current_high_mask as Configurable, enableAttributes),
    htc920.htc_0_ground_fault_current_high_alarm_setpoint as Configurable,
    htc920.htc_0_ground_fault_current_high_alarm_filter as Configurable,
  ],
  GroundFaultTripCurrentHTC920: [
    updateBooleanAttributes(htc920.htc_0_ground_fault_trip_mask as Configurable, enableAttributes),
    htc920.htc_0_ground_fault_current_trip_setpoint as Configurable,
  ],
  HighVoltageHTC920: [
    updateBooleanAttributes(htc920.htc_0_line_voltage_high_mask as Configurable, enableAttributes),
    htc920.htc_0_voltage_high_alarm_setpoint as Configurable,
    htc920.htc_0_voltage_high_alarm_filter as Configurable,
  ],
  LowVoltageHTC920: [
    updateBooleanAttributes(htc920.htc_0_line_voltage_low_mask as Configurable, enableAttributes),
    htc920.htc_0_voltage_low_alarm_setpoint as Configurable,
    htc920.htc_0_voltage_low_alarm_filter as Configurable,
  ],
  HighResistanceHTC920: [
    updateBooleanAttributes(htc920.htc_0_tracing_resistance_0_high_mask as Configurable, enableAttributes),
    htc920.htc_0_tracing_resistance_0_high_deviation as Configurable,
    htc920.htc_0_tracing_resistance_0_high_alarm_filter as Configurable,
  ],
  LowResistanceHTC920: [
    updateBooleanAttributes(htc920.htc_0_tracing_resistance_0_low_mask as Configurable, enableAttributes),
    htc920.htc_0_tracing_resistance_0_low_deviation as Configurable,
    htc920.htc_0_tracing_resistance_0_low_alarm_filter as Configurable,
  ],
  NominalTracingResistanceHTC920: [htc920.htc_0_tracing_resistance_0_nominal as Configurable],
  SwitchRatingHTC920: [htc920.htc_0_ssr_rating as Configurable],
  CBCurrentHTC920: [htc920.htc_0_circuit_breaker_0_current_rating as Configurable],
  MaximumPowerHTC920: [htc920.htc_0_maximum_power_setpoint as Configurable],
  VoltageSourceHTC920: [htc920.htc_0_voltage_source as Configurable],
  VoltageTurnsRatioHTC920: [htc920.htc_0_voltage_turns_ratio as Configurable],
  CurrentTurnsRatioHTC920: [htc920.htc_0_current_turns_ratio as Configurable],
  ThreePhaseCalculationHTC920: [
    updateBooleanAttributes(htc920.htc_0_three_phase_power_calculation_enabled as Configurable, yesNoAttributes),
  ],
  LoadSheddingEnableHTC920: [updateBooleanAttributes(htc920.htc_0_load_shed_enabled as Configurable, enableAttributes)],
  FailSafeModeHTC920: [
    updateBooleanAttributes(htc920.htc_0_load_shed_fail_safe_enabled as Configurable, enableAttributes),
  ],
  LoadSheddingInput1HTC920: [
    updateBooleanAttributes(htc920.htc_0_load_shed_zone_0_enabled as Configurable, enableAttributes),
  ],
  LoadSheddingInput2HTC920: [
    updateBooleanAttributes(htc920.htc_0_load_shed_zone_1_enabled as Configurable, enableAttributes),
  ],
  LoadSheddingInput3HTC920: [
    updateBooleanAttributes(htc920.htc_0_load_shed_zone_2_enabled as Configurable, enableAttributes),
  ],
  LoadSheddingInput4HTC920: [
    updateBooleanAttributes(htc920.htc_0_load_shed_zone_3_enabled as Configurable, enableAttributes),
  ],
  ExternalInputSettingsHTC920: [
    htc920.htc_0_external_input_configuration as Configurable,
    updateBooleanAttributes(htc920.htc_0_override_source as Configurable, externalInputSourceAttributes),
  ],
  AutoCycleHTC920: [
    updateBooleanAttributes(htc920.htc_0_auto_cycle_enabled as Configurable, enableAttributes),
    htc920.htc_0_auto_cycle_interval as Configurable,
    updateBooleanAttributes(htc920.htc_0_auto_cycle_interval_unit as Configurable, periodAttributes),
  ],
};
